import Api from './Api'

export default {

    liste() {
        return Api().get('roles/all')
    },
    async roleUser() {
        const roles = await Api().get('/roles/all')
        console.log(roles)
        for (let lr of roles.data.AllRoles) {
            if (lr.name == "User") {
                return lr
            }
        }
    }
}