<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>
        Liste des
        <span style="color: #ffa409">commissions agent</span>
        <!-- <theme-picker style="float: right"></theme-picker> -->
      </h1>
    </div>

    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input placeholder="Rechercher de commission..." prefix-icon="el-icon-search" v-model="search" clearable>
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom: 15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top: 15px">
          <el-input placeholder="Rechercher de commission..." prefix-icon="el-icon-search" v-model="search" clearable>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready">
        <el-table-column label="Date souscription" prop="dateSouscription" min-width="150" fixed>
          <template slot-scope="scope">
            {{ DateFormater(viewDateSouscription(scope.row.commissionid)) }}
          </template>
        </el-table-column>
        <el-table-column label="Client" prop="user" min-width="200">
          <template slot-scope="scope">
            <a href="#" @click="SetDetailUser(scope.row.contrat.commissionid)">
              {{ viewClient(scope.row.commissionid) }}
            </a>
          </template>
        </el-table-column>
        <el-table-column label="Branche" prop="branche" min-width="100">
          <template slot-scope="scope">
            {{ viewBranche(scope.row.commissionid.branche) }}
            <!-- {{ scope.row.commissionid.branche.name }} -->
          </template>
        </el-table-column>

        <el-table-column label="Contrat" prop="numero_police" min-width="180">
          <template slot-scope="scope">
            {{ viewPolice(scope.row.commissionid) }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="Assureur" prop="assureur" min-width="100">
          <template slot-scope="scope">
            {{ viewAssureur(scope.row.commissionid.contrat) }}
          </template>
        </el-table-column> -->

        <el-table-column label="Prime nette" prop="prime_nette" min-width="150">
          <template slot-scope="scope">
            {{ viewPrime(scope.row.commissionid) }} CFA
          </template>
        </el-table-column>
        <el-table-column label="Commissions" prop="commission" min-width="120">
          <template slot-scope="scope">
            {{ round(scope.row.commission_agent) }}
          </template>
        </el-table-column>
        <el-table-column label="Accessoires Agence" prop="accessoires_agent" min-width="180">
          <template slot-scope="scope">
            {{ round(scope.row.accessoires_agent) }}
          </template>
        </el-table-column>

        <el-table-column label="Date éffet" prop="dateEffet" min-width="120">
          <template slot-scope="scope">
            {{ DateFormater(viewDateEffet(scope.row.commissionid)) }}
          </template>
        </el-table-column>

        <el-table-column label="Date échéance" prop="dateEcheance" min-width="120">
          <template slot-scope="scope">
            {{ DateFormater(viewDateEcheance(scope.row.commissionid)) }}
          </template>
        </el-table-column>

        <el-table-column label="Total Revenu" prop="commission" min-width="120">
          <template slot-scope="scope">
            <b>{{ totalRevenu(scope.row) }}</b>
          </template>
        </el-table-column>

        <!-- <el-table-column fixed="right" label="Actions" prop="action" min-width="150">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailcommission(scope.row)" type="primary" icon="el-icon-view"
              circle></el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout" :total="total">
      </el-pagination>
    </div>


    <el-dialog title="Suppression commission" width="50%" :visible.sync="modalDelete" v-if="modalDelete == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous supprimer ce commission ?</span>
          </div>
          <div >
            <ul style="list-style: none">
              <li>
                <h3>
                  Assureur :
                  <!-- <span class="to-right assuraf-orange">{{
                    detailsCommission.assureur
                  }}</span> -->
                </h3>
              </li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top: 15px">
        <el-button type="danger" round @click="DeletingCommission(detailsCommission._id)">Oui</el-button>
        <el-button type="primary" round @click="modalDelete = false">Non</el-button>
      </div>
    </el-dialog>

    <el-dialog width="50%" title="Client" :visible.sync="showUser" v-if="showUser == true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div >
                <ul style="list-style: none">
                  <li>
                    Nom :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.nom || null
                    }}</span>
                  </li>
                  <li>
                    Prénom :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.prenom || null
                    }}</span>
                  </li>
                  <li>
                    Téléphone :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.telephone_port
                    }}</span>
                  </li>
                  <li>
                    Email :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.email
                    }}</span>
                  </li>
                  <li>
                    Adresse :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.adresse
                    }}</span>
                  </li>
                  <li>
                    Profession :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.profession
                    }}</span>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center !important">
                <img class="justify-center" :src="detailsUser.photo" width="150" alt="photo de profil" />
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%" title="Client" :visible.sync="showUser" v-if="showUser == true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div >
                <ul style="list-style: none">
                  <li>
                    Nom :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.nom || null
                    }}</span>
                  </li>
                  <li>
                    Prénom :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.prenom || null
                    }}</span>
                  </li>
                  <li>
                    Téléphone :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.telephone_port || null
                    }}</span>
                  </li>
                  <li>
                    Email :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.email
                    }}</span>
                  </li>
                  <li>
                    Adresse :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.adresse
                    }}</span>
                  </li>
                  <li>
                    Profession :
                    <span class="to-right assuraf-orange">{{
                      detailsUser.profession
                    }}</span>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top: 15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center !important">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil" />
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%!important" title="Formulaire de modification" :visible.sync="editCommission"
      v-if="editCommission == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="12">
              <div>
                <label>Commission</label>
                <el-input v-model="detailsCommission.commission" placeholder="Commission ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <label>Accessoires courtiers</label>
                <el-input v-model="detailsCommission.accessoires_courtier" placeholder="Accessoires courtier ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="updateCommission()">Modifier</el-button>
          <el-button type="danger" round @click="editCommission = false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog width="70%!important" title="Formulaire d'encaissement " :visible.sync="editEncaissement"
      v-if="editEncaissement == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="12">
              <div>
                <label>Etat Encaissement</label>
                <el-select style="width: 100% !important" v-model="detailsCommission.etat_encaissement"
                  placeholder="Selectionner ---">
                  <el-option value="Non">Non</el-option>
                  <el-option value="Oui">Oui</el-option>
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" class="mt-5">
              <div>
                <label>Moyen de paiement</label>
                <el-select style="width: 100% !important" v-model="detailsCommission.moyen_paiement"
                  placeholder="Selectionner ---" @change="updateLabelDate()">
                  <el-option value="Chéque"></el-option>
                  <el-option value="Virement"></el-option>
                  <el-option value="Autre"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px" :gutter="15" v-if="detailsCommission.moyen_paiement == 'Autre'">
            <el-col :span="12" class="mt-5">
              <div>
                <label>Mode de paiment</label>
                <el-input v-model="AUTRES" placeholder="Mode de paiment ..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5">
              <div>
                <label>Numéro</label>
                <el-input v-model="detailsCommission.num_cheque" placeholder="Numéro du reçu ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 15px" class="mt-5">
              <div>
                <label>Date {{ labelDate }}</label><br />
                <el-date-picker v-model="detailsCommission.date_encaissement" type="date" format="dd/MM/yyyy"
                  :picker-options="dateBeforeToday" placeholder="Date ..." class="input-with-select" />
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px" :gutter="15" v-else>
            <el-col :span="12" class="mt-5" v-if="detailsCommission.moyen_paiement == option.cheque">
              <div>
                <label>Numéro</label>
                <el-input v-model="detailsCommission.num_cheque" placeholder="Numéro  ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5">
              <div>
                <label>Banque</label>
                <el-input v-model="detailsCommission.banque" placeholder="banque " class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5">
              <div>
                <label>Date {{ labelDate }}</label><br />
                <el-date-picker v-model="detailsCommission.date_encaissement" type="date" format="yyyy/MM/dd"
                  :picker-options="dateBeforeToday" placeholder="Date ..." class="input-with-select" />
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="updateEncaissement()">Modifier</el-button>
          <el-button type="danger" round @click="editEncaissement = false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import lib from "../../../libs/index";
import Api from "../../../services/Api";
import store from "../../../store";
export default {
  name: "Commission",
  data() {
    return {
      AUTRES: "",
      option: {
        virement: "Virement",
        cheque: "Chéque",
        autre: "Autre",
      },
      labelDate: "",
      modalgenerationloading: false,
      du: null,
      au: null,
      detailsUser: null,
      showUser: false,
      dismissCountDown: 0,
      dismissSecs: 3,
      listbranche: [],
      listassureur: [],
      createcommission: false,
      editCommission: false,
      editEncaissement: false,
      menu_name: "",
      detailsCommission: {},
      showcommission: false,
      assureurs: [],
      collapse: null,
      totalRows: null,
      filter: null,
      width: 0,
      loading: false,
      ready: false,
      itemsChecked: [],
      listcommission: [],
      height: "auto",
      search: "",
      modalDelete: false,
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false,
      },
      MenuHeader: [
        { key: "theme", label: "Libellé" },
        { key: "collapse", label: "Collapse" },
        { key: "sousmenu", label: "Sous menu" },
        { key: "action", label: "action" },
      ],
      fields: [
        {
          key: "commission",
          label: "Commission",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "numero_police",
          label: "Numero police",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "prime_nette",
          label: "Prime nette",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "assureur",
          label: "Assureur",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "branche",
          label: "Branche",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "user",
          label: "Client",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "dateSouscription",
          label: "Date souscription",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "dateEffet",
          label: "Date effet",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "dateEcheance",
          label: "Date effet",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
    };
  },
  computed: {
    listFiltered() {
      if (this.search.length >= 1) {
        return this.listcommission.filter((obj) => {
          let ctrl = false;
          for (let k in obj) {
            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
            if (
              obj[k] &&
              obj[k]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              ctrl = true;
              /*on le push dans le tableau */
              //this.listcommission.push(obj)
            } else {
              /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
              if (Array.isArray(obj[k])) {
                obj[k].filter((nobj) => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (
                      nobj[l] &&
                      nobj[l]
                        .toString()
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) !== -1
                    ) {
                      ctrl = true;
                      /* on le push dans le tableau */
                      //this.listcommission.push(obj)
                    }
                  }
                });
                /* Si la valeur courante n'est ni String, ni Tableau mais Object */
              } else if (
                !Array.isArray(obj[k]) &&
                typeof obj[k] == "object" &&
                obj[k] != null
              ) {
                /*== On crée un nouveau tablequ vide ==*/
                let my_new_array = [];

                /*== On push la valeur courante dans le nouveau tableau ==*/
                my_new_array.push(obj[k]);

                /*On refiltre le nouveau tableau atrribut par attribut*/
                my_new_array.filter((nobj) => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (
                      nobj[l] &&
                      nobj[l]
                        .toString()
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) !== -1
                    ) {
                      ctrl = true;

                      /* on le push dans le tableau */
                      //this.listcommission.push(obj)
                    }
                  }
                });
              }
            }
          }
          return ctrl;
        });
      } else {
        return this.listcommission.filter((obj) => {
          let ctrl = false;
          for (let k in obj) {
            if (
              obj[k] &&
              obj[k]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true;
          }
          return ctrl;
        });
      }
    },
    listSortered() {
      let prop = this.sortingProp;
      let order = this.sortingOrder;
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = "";
          let val2 = "";

          val1 = item1[prop];
          val2 = item2[prop];
          if (order === "descending") {
            return val2 < val1 ? -1 : 1;
          }
          return val1 < val2 ? -1 : 1;
        })
      );
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage;
      let to = from + this.itemPerPage * 1;
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to);
    },
    total() {
      return this.listFiltered.length;
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.pagination.page = val;
      },
    },
    itemPerPage() {
      return this.pagination.size;
    },
    selectedItems() {
      return this.itemsChecked.length || 0;
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    itemPerPage(val) {
      this.ready = false;
      this.currentPage = 1;

      setTimeout(() => {
        this.ready = true;
      }, 500);
    },
    search(val) {
      this.currentPage = 1;
    },
  },
  methods: {
    updateLabelDate() {
      if (this.detailsCommission.moyen_paiement == "Virement") {
        this.labelDate = "du virement"
      }
      if (this.detailsCommission.moyen_paiement == "Chéque") {
        this.labelDate = "du chéque"
      }
      if (this.detailsCommission.moyen_paiement == "Autre") {
        this.labelDate = "d'encaissement"

      }

    },
    toggleEncaissement(item) {
      this.detailsCommission = item;
      this.editEncaissement = true;
    },
    updateEncaissement() {
      if (
        this.detailsCommission.etat_encaissement &&
        this.detailsCommission.moyen_paiement &&
        this.detailsCommission.date_encaissement
      ) {
        if (this.detailsCommission.moyen_paiement == 'Autre')
          this.detailsCommission.moyen_paiement = this.AUTRES
        Api()
          .post(lib.sdk.commission.updateEncaissement, this.detailsCommission)
          .then((result) => {
            console.log("=================================================");
            console.log("==> ", result.data);
            console.log("=================================================");
            this.$message.success(
              "Modification effectuée avec succés !",
              this.AlertMessage
            );
            this.editEncaissement = false;
            this.getCommissionList();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error(
          "verifié les informations saisie!",
          this.AlertMessage
        );
      }
    },
    isEmailConfig() {
      return lib.sdk.emailConfig.includes(store.state.user.email)
        ? true
        : false;
    },

    generateCommission() {
      Api()
        .post(lib.sdk.commission.generate, {
          email: this.$store.state.user.email,
        })
        .then((result) => {
          console.log("=================================================");
          console.log("==> ", result.data);
          console.log("=================================================");
          this.$message.success(
            "Modification effectuée avec succés !",
            this.AlertMessage
          );
          this.getCommissionList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    exportToExcel() {
      if (this.listFiltered) {
        let table = [];
        this.listFiltered.forEach((element) => {
          table.push(element._id);
        });

        Api()
          .post(lib.sdk.commission.exportToExcel, { export: table })
          .then((resultat) => {
            this.modalgenerationloading = true;
            let url = resultat.data.lien;
            let WinOpener = null;
            setTimeout(
              () => (
                (this.modalgenerationloading = false),
                (WinOpener = window.open()),
                (WinOpener.location = url)
              ),
              7000
            );
          })
          .catch((err) => console.log(err));
      }
    },
    filtreDate() {
      if (this.du && this.au) {
        Api()
          .post(lib.sdk.commission.filtrePeriode, {
            du: this.du,
            au: this.au,
          })
          .then((result) => {
            console.log(result);
            this.listcommission = result.data;
            this.totalRows = result.data.length;
          })
          .catch((err) => console.log(err));
      } else {
        this.$message.error(
          "Veuillez renseigner la période !",
          this.AlertMessage
        );
        this.getCommissionList();
      }
    },
    round(val) {
      return Math.round(val).toLocaleString() + " CFA";
    },
    totalRevenu(item) {

      if (item) {
        let total =
          Math.round(item.commission_agent) + Math.round(item.accessoires_agent);
        return Math.round(total).toLocaleString() + " CFA";

      }

    },
    SetDetailUser(details) {
      this.detailsUser = details.user;
      console.log("details", this.detailsUser);
      this.showUser = true;
    },
    viewAssureur(data) {
      if (data.assureur) return data.assureur.societe;
    },
    viewPolice(data) {
      if (data.contrat) return data.contrat.numero_police;
    },
    viewBranche(branche) {
      if (branche) return branche.name;
    },
    viewClient(contrat) {
      if (contrat.contrat) return contrat.contrat.user.nom + " - " + contrat.contrat.user.prenom;
    },
    viewPolice(contrat) {
      if (contrat) return contrat.contrat.numero_police;
    },
    viewPrime(contrat) {
      if (contrat) return contrat.contrat.prime_nette.toLocaleString() + " CFA";
    },
    viewDateEcheance(contrat) {
      if (contrat) return contrat.contrat.dateEcheance;
    },

    viewDateEffet(contrat) {
      if (contrat) return contrat.contrat.dateEffet;
    },
    viewDateSouscription(contrat) {
      if (contrat) return contrat.contrat.dateSouscription;
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000,
      });
    },
    Annuler() { },
    updateCommission() {
      let item = this.detailsCommission;

      //this.$message.error("Api encours !", this.AlertMessage);

      if (isNaN(item.commission)) {
        this.$message.error(
          "Commission doit etre superieur ou egale a zéro !",
          this.AlertMessage
        );
        return;
      }
      if (isNaN(item.accessoires_courtier)) {
        this.$message.error(
          "Accessoires courtier doit etre superieur ou egale a zéro !",
          this.AlertMessage
        );
        return;
      }
      Api()
        .patch(lib.sdk.commission.update, item)
        .then((resultat) => {
          console.log("=================================================");
          console.log("==> ", resultat.data);
          console.log("=================================================");
          this.$message.success(
            "Modification effectuée avec succés !",
            this.AlertMessage
          );
          this.getCommissionList();
          this.editCommission = false;
        })
        .catch((erreur) => {
          this.$message.error(
            "Vérifiez si les champs sont bien remplie !",
            this.AlertMessage
          );
          console.log(erreur);
        });
    },
    createCommission() {
      Api()
        .post(lib.sdk.commission.add)
        .then((resultat) => {
          this.$message.success(
            "Nouvelle commission de commission ajouté !",
            this.AlertMessage
          );
          this.getCommissionList();
          this.createcommission = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },

    getBranche() {
      Api()
        .get("/branche")
        .then((resultat) => {
          this.listbranche = resultat.data;
        });
    },
    getAssureur() {
      Api()
        .get("/partenaire/assureur")
        .then((resultat) => {
          this.listassureur = resultat.data.partenaires;
        });
    },
    DeletingCommission(id) {
      Api()
        .delete(lib.sdk.commission.delete + id)
        .then((resultat) => {
          if (resultat.status == 200) {
            this.modalDelete = false;
            this.getCommissionList();
          }
        })
        .catch((err) => { });
    },
    commissionDeleteConfirmation(item) {
      this.modalDelete = true;
      this.detailsCommission = item;
    },
    setCollapse() {
      this.collapse = "id_" + Date.now() + "_collapse";
    },
    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format("DD-MM-YYYY");
        // return dayjs(TheDate).format("DD-MM-YYYY H:m:s");
      }
    },
    detailcommission(item) {
      this.detailsCommission = item;
      this.showcommission = true;
    },
    handleSelectionChange(val) {
      this.itemsChecked = val;
    },

    toggleDetails(item) {
      item.commission = Math.round(item.commission);
      item.accessoires_courtier = Math.round(item.accessoires_courtier);
      this.detailsCommission = item;
      this.editCommission = true;
    },
    getCommissionList() {
      if (this.$store.state.user.role == "Agent") {
        Api()
          .post(lib.sdk.commissionAgent.byAgence,{agence: this.$store.state.user.societe})
          .then((result) => {
            this.listcommission = result.data;
            console.log(this.listcommission)
            this.totalRows = result.data.length;
            // this.assureur = this.listcommission.filter(x => x)
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.$store.state.user.role == "SuperAdmin" || this.$store.state.user == "Admin") {
        Api()
          .get(lib.sdk.commissionAgent.all)
          .then((result) => {
            this.listcommission = result.data;
            console.log(this.listcommission)
            this.totalRows = result.data.length;
            // this.assureur = this.listcommission.filter(x => x)
          })
          .catch((err) => {
            console.log(err);
          });
      }

    },

    isTablette() {
      if (screen.width > 425 && screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
    isDesktop: function () {
      if (screen.width > 768) {
        return true;
      } else {
        return false;
      }
    },
    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper");
      if (tableWrapper) this.width = tableWrapper.clientWidth;

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44;
      }

      if (this.width < 480) {
        this.pagination.small = true;
        this.pagination.layout = "prev, pager, next";
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false;
        this.pagination.layout = "prev, pager, next, ->, sizes";
      } else {
        this.pagination.small = false;
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes";
      }

      this.ready = true;
    },
    handleResize: _.throttle(function (e) {
      this.ready = false;
      this.width = 0;
      setTimeout(this.calcDims, 1000);
    }, 500),
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== "User") {
      this.getCommissionList();
    } else {
      this.$router.go(this.$router.push("/login"));
    }
    if (!window.Number.parseInt) window.Number.parseInt = parseInt;

    this.getBranche();
    this.getAssureur();
    this.calcDims();
  },
};
</script>

<style></style>

