exports.check_benefifiar=(detail_population, checked_beneficiarie)=>{
    console.log(detail_population)
    console.log(checked_beneficiarie[0])
    let checker = false

    if(checked_beneficiarie[0].genre=="Adulte_homme" ){
        if(detail_population.Adulte.Homme >=1){
            checker = true
        }
    }

    if(checked_beneficiarie[0].genre=="Adulte_femme"){
        if(detail_population.Adulte.Femme >=1){
            checker = true
        }
    }

    if(checked_beneficiarie[0].genre=="garcon"){
        if(detail_population.Enfant.Garcon >=1){
            checker = true
        }
    }
    if(checked_beneficiarie[0].genre=="fille"){
        if(detail_population.Enfant.Fille >=1){
            checker = true
        }
    }

    if(checked_beneficiarie[0].Senior=="homme"){
        if(detail_population.Senior.Homme >=1){
            checker = true
        }
    }

    if(checked_beneficiarie[0].genre=="femme"){
        if(detail_population.Senior.Femme >=1){
            checker = true
        }
    }

    return checker
  
}  