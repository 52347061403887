<template>
  <div
    class="page-table column scrollable only-y"
    :class="{ flex: !isMobile, overflow: isMobile }"
  >
    <div class="page-header">
      <h1>Liste des <span style="color: #ffa409">Flottes</span></h1>
    </div>
    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
          placeholder="Rechercher vehucules..."
          prefix-icon="el-icon-search"
          v-model="search"
          clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>
    <div v-if="isMobile" style="margin-bottom: 15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top: 15px">
          <el-input
            placeholder="Rechercher de commission..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />
    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div> 
    <div
      class="table-box card-base card-shadow--medium box grow"
      id="table-wrapper"
      v-loading="!ready"
      v-if="!isMobile"
    >
      <el-table
        :data="listInPage"
        style="width: 100%"
        :height="height"
        v-if="ready"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="Client" prop="user" min-width="150" fixed>
          <template slot-scope="scope">
            {{ viewClient(scope.row.vehicules[0].user) }}
          </template>
        </el-table-column>

        <el-table-column label="Email" prop="user" min-width="200" >
          <template slot-scope="scope">
            {{ scope.row.vehicules[0].user.email }}
          </template>
        </el-table-column>
        <el-table-column
          label="Reference flotte"
          prop="_id"
          min-width="150"
          
        >
          <template slot-scope="scope">
            {{ scope.row._id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Date de création"
          prop="created"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ DateFormater(scope.row.vehicules[0].created) }}
          </template>
        </el-table-column>

        <el-table-column
          label="Nombre de vehicules"
          prop="totalVehicule"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.totalVehicule }}
          </template>
        </el-table-column>
        <el-table-column label="Status" prop="status" min-width="100">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.vehicules[0].status">
              validée
            </span>
            <span style="color: red" v-else> non validée </span>
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="Actions"
          prop="action"
          min-width="100"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="gotoDevis(scope.row._id)"
              icon="el-icon-edit"
              circle
            ></el-button>
            <!-- <el-button
              size="mini"
              type="primary"
               v-loading.fullscreen.lock="modalgenerationloading"
              @click="valideFlottes(scope.row.user)"
              icon="el-icon-check"
              circle
            ></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="ready"
        :small="pagination.small"
        :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes"
        :page-size.sync="pagination.size"
        :layout="pagination.layout"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="Validation flotte"
      width="50%"
      :visible.sync="modalValid"
      v-if="modalValid == true"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous valider ce flotte ?</span>
          </div>
          <div >
            <ul style="list-style: none">
              <li>
                <h3>
                  Client :
                  <span class="to-right assuraf-orange"
                    >{{ detailsusers.prenom }} {{ detailsusers.nom }}</span
                  >
                </h3>
              </li>
              <li>
                <h3>
                  Telephone :
                  <span class="to-right assuraf-orange">{{
                    detailsusers.telephone_port
                  }}</span>
                </h3>
              </li>
              <li>
                <h3>
                  Email :
                  <span class="to-right assuraf-orange">{{
                    detailsusers.email
                  }}</span>
                </h3>
              </li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top: 15px">
        <el-button
          type="primary"
          round
          v-loading.fullscreen.lock="modalgenerationloading"
          @click="generateDevis(detailsusers)"
          >Oui</el-button
        >
        <el-button type="secondary" round @click="modalValid = false"
          >Non</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from "../../../services/Api";
import dayjs from "dayjs";

export default {
  data: () => ({
    modalgenerationloading: false,
    detailsvehicules: [],
    detailsusers: {},
    showvehicules: false,
    tableData: [],
    ready: false,
    width: 0,
    height: "auto",
    PuissanceFinscaleItems: [
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      "24+",
    ],

    search: "",
    loading: false,
    itemsChecked: [],
    pagination: {
      page: 1,
      size: 100,
      sizes: [10, 15, 20, 30, 50, 100],
      layout: "total, ->, prev, pager, next, jumper, sizes",
      small: false,
    },
    modalValid: false,
  }),
  computed: {
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.pagination.page = val;
      },
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage;
      let to = from + this.itemPerPage * 1;
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to);
    },
    total() {
      return this.listFiltered.length;
    },
    listFiltered() {
      if (this.search.length >= 1) {
        return this.tableData.filter((obj) => {
          let ctrl = false;
          for (let k in obj) {
            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
            if (
              obj[k] &&
              obj[k]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              ctrl = true;
            } else {
              /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
              if (Array.isArray(obj[k])) {
                obj[k].filter((nobj) => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (
                      nobj[l] &&
                      nobj[l]
                        .toString()
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) !== -1
                    ) {
                      ctrl = true;
                    } else {
                      for (const key in nobj[l]) {
                        if (Object.hasOwnProperty.call(nobj[l], key)) {
                          const element = nobj[l][key];
                          if (
                            element &&
                            element
                              .toString()
                              .toLowerCase()
                              .indexOf(this.search.toLowerCase()) !== -1
                          ) {
                            ctrl = true;
                          }
                        }
                      }
                    }
                  }
                });
                /* Si la valeur courante n'est ni String, ni Tableau mais Object */
              } else if (
                !Array.isArray(obj[k]) &&
                typeof obj[k] == "object" &&
                obj[k] != null
              ) {
                /*== On crée un nouveau tablequ vide ==*/
                let my_new_array = [];

                /*== On push la valeur courante dans le nouveau tableau ==*/
                my_new_array.push(obj[k]);

                /*On refiltre le nouveau tableau atrribut par attribut*/
                my_new_array.filter((nobj) => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (
                      nobj[l] &&
                      nobj[l]
                        .toString()
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) !== -1
                    ) {
                      ctrl = true;
                    }
                  }
                });
              }
            }
          }
          return ctrl;
        });
      } else {
        return this.tableData.filter((obj) => {
          let ctrl = false;
          for (let k in obj) {
            if (
              obj[k] &&
              obj[k]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true;
          }
          return ctrl;
        });
      }
    },
    itemPerPage() {
      return this.pagination.size;
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    itemPerPage(val) {
      this.ready = false;
      this.currentPage = 1;

      setTimeout(() => {
        this.ready = true;
      }, 500);
    },
    search(val) {
      this.currentPage = 1;
    },
  },
  methods: {
    exportToExcel(){
          //API de genereation donne en excel
        },
    generateDevis(item) {
      console.log("Item ===> " , item)
      this.modalgenerationloading = true;
      setTimeout(() => {
        this.modalgenerationloading = false;
        this.$router.push("/flotte/generation/" + item._id);
        this.modalValid = false;
      }, 1000);
    },

    gotoDevis(id) {
      this.$router.push("/flotte/uploadexcel/" + id);
    },

    valideFlottes(item) {
      this.detailsusers = item[0];
      this.modalValid = true;
    },

    viewGaranties(garanties) {
      if (garanties.length !== 0) {
        return `<ul> ${this.li(garanties)}</ul> `;
      }
      return "";
    },

    li(list) {
      let view = "";
      list.forEach((element) => {
        view = view + ` <li> ${element}</li>`;
      });
      return view;
    },

    viewTonnage(tonnage) {
      if (typeof tonnage == "string") return this.formatText(tonnage);
      return tonnage;
    },

    formatText(text) {
      if (typeof text == "string") return text.trim().replaceAll('"', "");
    },

    handleSelectionChange(val) {
      this.itemsChecked = val;
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper");
      if (tableWrapper) this.width = tableWrapper.clientWidth;

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44;
      }

      if (this.width < 480) {
        this.pagination.small = true;
        this.pagination.layout = "prev, pager, next";
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false;
        this.pagination.layout = "prev, pager, next, ->, sizes";
      } else {
        this.pagination.small = false;
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes";
      }

      this.ready = true;
    },

    handleResize: _.throttle(function (e) {
      this.ready = false;
      this.width = 0;
      setTimeout(this.calcDims, 1000);
    }, 500),
    
    getFlotteList() {
      Api()
        .get("/tempflotte/all")
        .then((result) => {
          this.tableData = result.data;
        })
        .catch((err) => {});
    },
    // getFlotteListA() {
    //   Api()
    //     .get("/tempflotte/list")
    //     .then((result) => {
    //       this.tableData = result.data;
    //     })
    //     .catch((err) => {});
    // },
    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format("DD-MM-YYYY HH:mm:ss");
      }
    },
    viewClient(user) {
      if (user) return user.prenom + " - " + user.nom;
    },
  },
  created() {},
  mounted() {
    this.getFlotteList();
    this.calcDims();
  },
};
</script>

<style>
</style>