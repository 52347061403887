export default class Beneficiaire {
    constructor() {
        this.Nom =  null
        this.Prenom = null
        this.dateNaissance = null
        this.LieuNaissance = null
        this.Filiation= ""
        this.Cni= ""
        this.Telephone= ""
        this.Genre = null
    }
}