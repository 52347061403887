<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Renouvellement des <span style="color:#ffa409">contrats</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="handleResize" />

    <el-row :gutter="15" v-if="!isMobile">
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <label>Du</label>
          <el-date-picker style="width: 100%!important;" v-model="du" type="date" placeholder="Choississez une date"
            :picker-options="dateBeforeToday">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <label>Au</label>
          <el-date-picker style="width: 100%!important;" v-model="au" type="date" placeholder="Choississez une date"
            :picker-options="dateBeforeToday">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <label>Branche</label>
          <el-select style="width: 100%!important;" v-model="branche" placeholder="Selectionner">
            <el-option v-for="(b, i) in branchlist" :key="i" :label="b.branche" :value="b._id">
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="6" v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Admin'">
        <div class="grid-content bg-purple">
          <label>Assureurs</label>
          <el-select style="width: 100%!important;" v-model="assureur" placeholder="Selectionner">
            <el-option v-for="(a, i) in assureurlist" :key="i" :label="a.societe" :value="a.id">
            </el-option>
          </el-select>
        </div>
      </el-col>

      <el-col :span="6" v-else>
        <div class="grid-content bg-purple">
          <label>Assureurs</label>
          <el-select style="width: 100%!important;" v-model="assureur" placeholder="Selectionner">
            <el-option v-for="(a, i) in $store.state.user.societe.assureurs_partenaires" :key="i" :label="a.societe"
              :value="a.id">
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="15" v-if="isMobile">
      <el-col :span="24" style="margin-top:15px">
        <div class="grid-content bg-purple">
          <label>Du</label>
          <el-date-picker style="width: 100%!important;" v-model="du" type="date" placeholder="Choississez une date"
            :picker-options="dateBeforeToday">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="24" style="margin-top:15px">
        <div class="grid-content bg-purple">
          <label>Au</label>
          <el-date-picker style="width: 100%!important;" v-model="au" type="date" placeholder="Choississez une date"
            :picker-options="dateBeforeToday">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="24" style="margin-top:15px">
        <div class="grid-content bg-purple">
          <label>Branche</label>
          <el-select style="width: 100%!important;" v-model="branche" placeholder="Selectionner">
            <el-option v-for="(b, i) in branchlist" :key="i" :label="b.branche" :value="b._id">
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="24" style="margin-top:15px"
        v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Admin'">
        <div class="grid-content bg-purple">
          <label>Assureurs</label>
          <el-select style="width: 100%!important;" v-model="assureur" placeholder="Selectionner">
            <el-option v-for="(a, i) in assureurlist" :key="i" :label="a.societe" :value="a.id">
            </el-option>
          </el-select>
        </div>
      </el-col>

      <el-col :span="24" style="margin-top:15px" v-else>
        <div class="grid-content bg-purple">
          <label>Assureurs</label>
          <el-select style="width: 100%!important;" v-model="assureur" placeholder="Selectionner">
            <el-option v-for="(a, i) in $store.state.user.societe.assureurs_partenaires" :key="i" :label="a.societe"
              :value="a.id">
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>

    <resize-observer @notify="handleResize" />

    <div class="mb-5" style="margin-top:10px">
      <el-button type="primary" round @click="FiltreDate()">Rechercher</el-button>
    </div>

    <div class="mb-5" v-if="this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin'">
      <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter
        en Excel</el-button>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile"
      style="margin-top:15px">
      <el-table :row-class-name="tableRowClassName" :data="listInPage" style="width: 100%" :height="height" v-if="ready"
        @selection-change="handleSelectionChange">
        <el-table-column label="Date Souscription" fixed min-width="150" prop="dateSouscription">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Client" min-width="150" prop="client">
          <template slot-scope="scope">
            <span class="date">
              <a href="#" @click="SetDetailUser(scope.row.user)">
                {{ scope.row.user.prenom }} - {{ scope.row.user.nom }}
              </a>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
        <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid">
          <template slot-scope="scope">
            <span v-if="scope.row.partenaireid.societe.length <= 10" class="date">{{ scope.row.partenaireid.societe
            }}</span>
            <span v-else class="date">{{ scope.row.partenaireid.societe.substring(0, 10) + "..." }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Créateur" min-width="150" prop="createur"
          v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Agent' || $store.state.user.role == 'Admin'">
          <template slot-scope="scope">
            <span v-if="scope.row.createur" class="date">{{ scope.row.createur.prenom }} - {{ scope.row.createur.nom
            }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="70">
          <template slot-scope="scope">
            <el-button size="mini" @click="Get_set_Comment(scope.row)" type="primary" icon="el-icon-view" circle
              title="Voir détails"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
        :total="total"></el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile"
      style="margin-top:15px">
      <el-table :row-class-name="tableRowClassName" :data="listInPage" style="width: 100%" v-if="ready"
        @selection-change="handleSelectionChange">
        <el-table-column label="Date Souscription" min-width="150" prop="dateSouscription">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Client" min-width="150" prop="client">
          <template slot-scope="scope">
            <span class="date">
              <a href="#" @click="SetDetailUser(scope.row.user)">
                {{ scope.row.user.prenom }} - {{ scope.row.user.nom }}
              </a>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
        <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid">
          <template slot-scope="scope">
            <span v-if="scope.row.partenaireid.societe.length <= 10" class="date">{{ scope.row.partenaireid.societe
            }}</span>
            <span v-else class="date">{{ scope.row.partenaireid.societe.substring(0, 10) + "..." }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Créateur" min-width="150" prop="createur"
          v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Agent' || $store.state.user.role == 'Admin'">
          <template slot-scope="scope">
            <span v-if="scope.row.createur" class="date">{{ scope.row.createur.prenom }} - {{ scope.row.createur.nom
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Actions" prop="action" min-width="60">
          <template slot-scope="scope">
            <el-button size="mini" @click="Get_set_Comment(scope.row)" type="primary" icon="el-icon-view" circle
              title="Voir détails"></el-button>

          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
        :total="total"></el-pagination>
    </div>

    <el-dialog width="70%!important" title="Détails contrat" :visible.sync="showcontrat"
      v-if="showcontrat == true && !isMobile">
      <div class="mb-5">
        <el-button type="primary" round @click="relance_sms(detailsContrat)">Relancer par SMS</el-button>
        <el-button type="primary" round @click="JsonFormaterView()">Détails en JSON</el-button>
        <el-button type="primary" round @click="getCommentDetail()">Lire commentaires</el-button>
        <el-button type="primary" round @click="SetCommentDetail()">Ajouter un commentaire</el-button>
        <!-- <el-button v-if="detailsContrat.brancheid.branche == 'Automobile'" type="primary" round
          @click="SetDetailRenouvellement()">Renouveler</el-button> -->
          <el-button v-if="detailsContrat.brancheid.branche == 'Automobile'" type="primary" round
          @click="Edit_devis(detailsContrat)">Renouveler</el-button>
          
        <el-button v-if="detailsContrat.brancheid.branche == 'Assur-Hospi'" type="primary" round
          @click="detailRenouvellementAssurHospi()">Renouveler</el-button>
      </div>
      <el-row :gutter="15">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Date de Souscription : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateSouscription)}}</span></li>
                  <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{detailsContrat.numero_police}}</span></li>
                  <li v-if="detailsContrat.brancheid.branche=='Voyage' && detailsContrat.increment_voyage">
                    Numéro Increment Voyage : <span class="to-right assuraf-orange">{{detailsContrat.increment_voyage}}</span></li>
                  <li>Numéro Police Assureur : <span class="to-right assuraf-orange">{{detailsContrat.police_assureur}}</span></li>
                  <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEffet)}}</span></li>
                  <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEcheance)}}</span></li>
                  <li>Prime Nette : <span class="to-right assuraf-orange">{{detailsContrat.prime_nette.toLocaleString()}}</span></li>
                  <li>Prime TTC : <span class="to-right assuraf-orange">{{detailsContrat.prime_ttc.toLocaleString()}}</span></li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{detailsContrat.taxe.toLocaleString()}}</span></li>
                  <li v-if="detailsContrat.brancheid.branche=='Automobile' && CheckIfTypeDefined(detailsContrat.devisid) !=null">
                    FGA : <span class="to-right assuraf-orange" v-if="detailsContrat.devisid!=null">{{detailsContrat.devisid.fga.toLocaleString()}}</span></li>
                  <li>Accessoires : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                  <li v-if="detailsContrat.partner_creator!=null">Partenaires : <span class="to-right assuraf-orange">{{detailsContrat.partner_creator}}</span></li>
                  <li
                   
                    v-if="detailsContrat.brancheid.branche=='Habitation' || detailsContrat.brancheid.branche=='Automobile'">
                      Relance par SMS : 
                      <span class="to-right assuraf-orange"  style="border-radius: 10px;border:1px solid black; padding:5px">
                          {{ relance_status(detailsContrat.relance_step) }}
                      </span>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsContrat.user.prenom.toUpperCase() }}</span>
                  </li>
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsContrat.user.nom.toUpperCase() }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsContrat.user.telephone_port }}</span>
                  </li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsContrat.user.email }}</span></li>
                  <li>Date inscription : <span class="to-right assuraf-orange">{{
                    DateFormater(detailsContrat.user.created) }}</span></li>
                  <li>
                    <img :src="detailsContrat.user.photo" alt="" width="200">
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="15" v-if="JsonView" style="margin-top: 15px">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails en JSON </span>
              </div>
              <div>
                <pre style="background:black;color:white">
                  {{ detailsContrat }}
                </pre>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <div v-if="detailsContrat.brancheid.branche == 'Automobile'" style="margin-top:15px">
         <el-table
          :data="detailsContrat.details[0].vehicules"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Marque" min-width="150" prop="marque" :fixed="!isMobile"></el-table-column>
        <el-table-column label="Modèle" min-width="200" prop="model"></el-table-column>
        <el-table-column label="Force" min-width="200" prop="force"></el-table-column>
        <el-table-column label="Nbre de place" min-width="200" prop="nbplace"></el-table-column>
        <el-table-column label="Valeur à neuf" min-width="200" prop="valeur">
          <template slot-scope="scope">
            {{scope.row.valeur.toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column label="Valeur venale" min-width="200" prop="valeur_venale">
          <template slot-scope="scope">
            {{scope.row.valeur_venale.toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column label="Carburation" min-width="200" prop="carburation"></el-table-column>
      </el-table>
      </div>

      <div v-if="detailsContrat.user_on_gray_card" style="margin-top:15px">
        <el-row :gutter="15">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Nom sur la carte grise : <span class="to-right assuraf-orange">{{
                      detailsContrat.user_on_gray_card.nom.toUpperCase() }}</span></li>
                    <li>Prénom sur la carte grise: <span class="to-right assuraf-orange">{{
                      detailsContrat.user_on_gray_card.prenom.toUpperCase() }}</span>
                    </li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog width="90%!important" title="Détails contrat" :visible.sync="showcontrat"
      v-if="showcontrat == true && isMobile">
      <div class="mb-5">
        <el-button type="primary" round @click="relance_sms(detailsContrat)">Relancer par SMS</el-button>
      </div>
      <div class="mb-5">
        <el-button type="primary" round @click="JsonFormaterView()">Détails en JSON</el-button>
      </div>
      <div class="mb-5 mt-5">
        <el-button type="primary" round @click="getCommentDetail()">Lire commentaires</el-button>
      </div>
      <div class="mb-5 mt-5">
        <el-button type="primary" round @click="SetCommentDetail()">Ajouter un commentaire</el-button>
      </div>
      <div class="mb-5 mt-5">
        <el-button v-if="detailsContrat.brancheid.branche == 'Automobile'" type="primary" round
          @click="SetDetailRenouvellement()">Renouveler</el-button>
      </div>

      <el-row :gutter="15">
        <el-col :span="24" v-if="JsonView">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails en JSON </span>
              </div>
              <div>
                <pre style="background:black;color:white">
                  {{ detailsContrat }}
                </pre>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Date de Souscription : <span class="to-right assuraf-orange">{{
                    DateFormater(detailsContrat.dateSouscription) }}</span></li>
                  <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{ detailsContrat.numero_police
                  }}</span></li>
                  <li v-if="detailsContrat.brancheid.branche == 'Voyage' && detailsContrat.increment_voyage">
                    Numéro Increment Voyage : <span class="to-right assuraf-orange">{{ detailsContrat.increment_voyage
                    }}</span></li>
                  <li>Numéro Police Assureur : <span class="to-right assuraf-orange">{{ detailsContrat.police_assureur
                  }}</span></li>
                  <li>Date Effet : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEffet)
                  }}</span>
                  </li>
                  <li>Date Echéance : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEcheance)
                  }}</span></li>
                  <li>Prime Nette : <span class="to-right assuraf-orange">{{ detailsContrat.prime_nette.toLocaleString()
                  }}</span></li>
                  <li>Prime TTC : <span class="to-right assuraf-orange">{{ detailsContrat.prime_ttc.toLocaleString()
                  }}</span></li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{ detailsContrat.taxe.toLocaleString() }}</span></li>
                  <li
                    v-if="detailsContrat.brancheid.branche == 'Automobile' && CheckIfTypeDefined(detailsContrat.devisid) != null">
                    FGA : <span class="to-right assuraf-orange" v-if="detailsContrat.devisid != null">{{
                      detailsContrat.devisid.fga.toLocaleString() }}</span></li>
                  <li>Accessoires : <span class="to-right assuraf-orange">{{ detailsContrat.accessoires.toLocaleString()
                  }}</span></li>
                  <li v-if="detailsContrat.partner_creator != null">Partenaires : <span class="to-right assuraf-orange">{{
                    detailsContrat.partner_creator }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsContrat.user.prenom.toUpperCase() }}</span>
                  </li>
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsContrat.user.nom.toUpperCase() }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsContrat.user.telephone_port }}</span>
                  </li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsContrat.user.email }}</span></li>
                  <li>Date inscription : <span class="to-right assuraf-orange">{{
                    detailsContrat.accessoires.toLocaleString() }}</span></li>
                  <li>
                    <img :src="detailsContrat.user.photo" alt="" width="200">
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>


      <div v-if="detailsContrat.brancheid.branche == 'Automobile'">
        <el-table :data="detailsContrat.details[0].vehicules" style="width: 100%" v-if="ready"
          @selection-change="handleSelectionChange">
          <el-table-column label="Marque" min-width="150" prop="marque"></el-table-column>
          <el-table-column label="Modèle" min-width="200" prop="model"></el-table-column>
          <el-table-column label="Force" min-width="200" prop="force"></el-table-column>
          <el-table-column label="Nbre de place" min-width="200" prop="nbplace"></el-table-column>
          <el-table-column label="Valeur à neuf" min-width="200" prop="valeur">
            <template slot-scope="scope">
              {{ scope.row.valeur.toLocaleString() }}
            </template>
          </el-table-column>
          <el-table-column label="Valeur venale" min-width="200" prop="valeur_venale">
            <template slot-scope="scope">
              {{ scope.row.valeur_venale.toLocaleString() }}
            </template>
          </el-table-column>
          <el-table-column label="Carburation" min-width="200" prop="carburation"></el-table-column>
        </el-table>
      </div>

      <div v-if="detailsContrat.user_on_gray_card" style="margin-top:15px">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Nom sur la carte grise : <span class="to-right assuraf-orange">{{
                      detailsContrat.user_on_gray_card.nom.toUpperCase() }}</span></li>
                    <li>Prénom sur la carte grise: <span class="to-right assuraf-orange">{{
                      detailsContrat.user_on_gray_card.prenom.toUpperCase() }}</span>
                    </li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog :title="'Détails des commentaires ' + commentcount" width="50%" :visible.sync="show_comment_contrat"
      v-if="show_comment_contrat == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Commentaires</span>
          </div>
          <div v-for="(cl, i) in commentlist" :key="i">
            <ul style="list-style:none">
              <li>{{ cl.userid.prenom }}-{{ cl.userid.nom }} a écrit <span class="to-right assuraf-orange">{{
                cl.comment_text }}</span></li>
              <li>Le<span class="to-right assuraf-orange">{{ DateFormater(cl.createdat) }}</span></li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="SetCommentDetail()">Ajouter</el-button>
        <el-button type="danger" round @click="show_comment_contrat = false">Fermer</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Ajouter un commentaire" width="50%" :visible.sync="set_comment_contrat"
      v-if="set_comment_contrat == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Ajouter</span>
          </div>
          <div>
            <el-input aria-placeholder="Votre commentaire..." type="textarea" v-model="comment.comment_text"></el-input>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="sendComment()">Ajouter</el-button>
        <el-button type="danger" round @click="set_comment_contrat = false">Fermer</el-button>
      </div>
    </el-dialog>

    <el-dialog width="70%" title="Détails client" :visible.sync="showUser" v-if="showUser == true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails client</span>
              </div>
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%" title="Détails client" :visible.sync="showUser" v-if="showUser == true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails client</span>
              </div>
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%" title="Renouvellement" :visible.sync="popuprenouvellement"
      v-if="popuprenouvellement == true && !isMobile">
      <div v-if="detailsContrat.brancheid.branche == 'Automobile'">
        <el-row :gutter="15">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Durée</label>
              <el-input @change="dateDiff" type="number" placeholder="Durée en mois" v-model="duree">
                <template slot="append">mois</template>
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date d'effet</label>
              <el-date-picker style="width: 100%!important;" v-model="dateEffet" @change="dateDiff" type="date"
                placeholder="Choississez une date" :picker-options="dateAfterToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date d'échéance</label>
              <el-input readOnly placeholder="Date d'échéance" v-model="dateEcheance">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top:15px">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Date de Souscription : <span class="to-right assuraf-orange">{{
                      DateFormater(detailsContrat.dateSouscription) }}</span></li>
                    <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{ detailsContrat.numero_police
                    }}</span></li>
                    <li v-if="detailsContrat.brancheid.branche == 'Voyage' && detailsContrat.increment_voyage">
                      Numéro Increment Voyage : <span class="to-right assuraf-orange">{{ detailsContrat.increment_voyage
                      }}</span></li>
                    <li>Numéro Police Assureur : <span class="to-right assuraf-orange">{{ detailsContrat.police_assureur
                    }}</span></li>
                    <li>Date Effet : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEffet)
                    }}</span></li>
                    <li>Date Echéance : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEcheance)
                    }}</span></li>
                    <li>Prime Nette : <span class="to-right assuraf-orange">{{ detailsContrat.prime_nette.toLocaleString()
                    }}</span></li>
                    <li>Prime TTC : <span class="to-right assuraf-orange">{{ detailsContrat.prime_ttc.toLocaleString()
                    }}</span></li>
                    <li>Taxe : <span class="to-right assuraf-orange">{{ detailsContrat.taxe.toLocaleString() }}</span>
                    </li>
                    <li
                      v-if="detailsContrat.brancheid.branche == 'Automobile' && CheckIfTypeDefined(detailsContrat.devisid) != null">
                      FGA : <span class="to-right assuraf-orange" v-if="detailsContrat.devisid != null">{{
                        detailsContrat.devisid.fga.toLocaleString() }}</span></li>
                    <li>Accessoires : <span class="to-right assuraf-orange">{{ detailsContrat.accessoires.toLocaleString()
                    }}</span></li>
                    <li v-if="detailsContrat.partner_creator != null">Partenaires : <span
                        class="to-right assuraf-orange">{{ detailsContrat.partner_creator }}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Prénom : <span class="to-right assuraf-orange">{{ detailsContrat.user.prenom.toUpperCase()
                    }}</span>
                    </li>
                    <li>Nom : <span class="to-right assuraf-orange">{{ detailsContrat.user.nom.toUpperCase() }}</span>
                    </li>
                    <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsContrat.user.telephone_port }}</span>
                    </li>
                    <li>Email : <span class="to-right assuraf-orange">{{ detailsContrat.user.email }}</span></li>
                    <li>Date inscription : <span class="to-right assuraf-orange">{{
                      detailsContrat.accessoires.toLocaleString() }}</span></li>
                    <li>
                      <img :src="detailsContrat.user.photo" alt="" width="200">
                    </li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top:15px">
        <el-button type="primary" style="float:left" @click="SendData" round>Valider</el-button> <br />
      </div>
      <div style="margin-top:10px">
        <h3 style="text-align:center!important;color:red">* La date d'effet doit être supérieure à : <span
            style="color:#ffa409">{{ DateFormater(detailsContrat.dateEcheance) }}</span></h3>
      </div>


      <div v-if="detailsContrat.brancheid.branche == 'Automobile'">
        <el-table :data="detailsContrat.details[0].vehicules" style="width: 100%" :height="height" v-if="ready"
          @selection-change="handleSelectionChange">
          <el-table-column label="Marque" min-width="150" prop="marque" :fixed="!isMobile"></el-table-column>
          <el-table-column label="Modèle" min-width="200" prop="model"></el-table-column>
          <el-table-column label="Puissance fiscale" min-width="180" prop="force"></el-table-column>
          <el-table-column label="Nombre de place" min-width="180" prop="nbplace"></el-table-column>
          <el-table-column label="Valeur à neuf" min-width="200" prop="valeur"></el-table-column>
          <el-table-column label="Valeur vénale" min-width="200" prop="valeur_venale"></el-table-column>
          <el-table-column label="Immatriculation" min-width="200" prop="immatriculation"></el-table-column>
          <el-table-column label="Carburation" min-width="200" prop="carburation"></el-table-column>
        </el-table>
        <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
          :total="totalContrat"></el-pagination>

        <el-row :gutter="15" v-if="detailsContrat.user_on_gray_card">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Nom sur la carte grise : <span class="to-right assuraf-orange">{{
                      detailsContrat.user_on_gray_card.nom.toUpperCase() }}</span></li>
                    <li>Prenom sur la carte grise : <span class="to-right assuraf-orange">{{
                      detailsContrat.user_on_gray_card.prenom.toUpperCase() }}</span>
                    </li>

                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" v-if="JsonView" style="margin-top: 15px">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>Détails en JSON </span>
                </div>
                <div>
                  <pre style="background:black;color:white">
                      {{ detailsContrat }}
                    </pre>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog width="90%" title="Renouvellement" :visible.sync="popuprenouvellement"
      v-if="popuprenouvellement == true && isMobile">
      <div v-if="detailsContrat.brancheid.branche == 'Automobile'">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Durée</label>
              <el-input @change="dateDiff" type="number" placeholder="Durée en mois" v-model="duree">
                <template slot="append">mois</template>
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Date d'effet</label>
              <el-date-picker style="width: 100%!important;" v-model="dateEffet" @change="dateDiff" type="date"
                placeholder="Choississez une date" :picker-options="dateAfterToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Date d'échéance</label>
              <el-input readOnly placeholder="Date d'échéance" v-model="dateEcheance">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top:15px">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Date de Souscription : <span class="to-right assuraf-orange">{{
                      DateFormater(detailsContrat.dateSouscription) }}</span></li>
                    <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{ detailsContrat.numero_police
                    }}</span></li>
                    <li v-if="detailsContrat.brancheid.branche == 'Voyage' && detailsContrat.increment_voyage">
                      Numéro Increment Voyage : <span class="to-right assuraf-orange">{{ detailsContrat.increment_voyage
                      }}</span></li>
                    <li>Numéro Police Assureur : <span class="to-right assuraf-orange">{{ detailsContrat.police_assureur
                    }}</span></li>
                    <li>Date Effet : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEffet)
                    }}</span></li>
                    <li>Date Echéance : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEcheance)
                    }}</span></li>
                    <li>Prime Nette : <span class="to-right assuraf-orange">{{ detailsContrat.prime_nette.toLocaleString()
                    }}</span></li>
                    <li>Prime TTC : <span class="to-right assuraf-orange">{{ detailsContrat.prime_ttc.toLocaleString()
                    }}</span></li>
                    <li>Taxe : <span class="to-right assuraf-orange">{{ detailsContrat.taxe.toLocaleString() }}</span>
                    </li>
                    <li
                      v-if="detailsContrat.brancheid.branche == 'Automobile' && CheckIfTypeDefined(detailsContrat.devisid) != null">
                      FGA : <span class="to-right assuraf-orange" v-if="detailsContrat.devisid != null">{{
                        detailsContrat.devisid.fga.toLocaleString() }}</span></li>
                    <li>Accessoires : <span class="to-right assuraf-orange">{{ detailsContrat.accessoires.toLocaleString()
                    }}</span></li>
                    <li v-if="detailsContrat.partner_creator != null">Partenaires : <span
                        class="to-right assuraf-orange">{{ detailsContrat.partner_creator }}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Prénom : <span class="to-right assuraf-orange">{{ detailsContrat.user.prenom.toUpperCase()
                    }}</span>
                    </li>
                    <li>Nom : <span class="to-right assuraf-orange">{{ detailsContrat.user.nom.toUpperCase() }}</span>
                    </li>
                    <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsContrat.user.telephone_port }}</span>
                    </li>
                    <li>Email : <span class="to-right assuraf-orange">{{ detailsContrat.user.email }}</span></li>
                    <li>Date inscription : <span class="to-right assuraf-orange">{{
                      detailsContrat.accessoires.toLocaleString() }}</span></li>
                    <li>
                      <img :src="detailsContrat.user.photo" alt="" width="200">
                    </li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-row :gutter="15" style="margin-top:15px">
        <el-col :span="24">
          <div>
            <el-button type="primary" style="float:left" @click="SendData" round>Valider</el-button> <br />
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div style="margin-top:10px">
            <h3 style="text-align:center!important;color:red">* La date d'effet doit être supérieure à : <span
                style="color:#ffa409">{{ DateFormater(detailsContrat.dateEcheance) }}</span></h3>
          </div>
        </el-col>
      </el-row>

      <div v-if="detailsContrat.brancheid.branche == 'Automobile'">
        <el-table :data="detailsContrat.details[0].vehicules" style="width: 100%" :height="height" v-if="ready"
          @selection-change="handleSelectionChange">
          <el-table-column label="Marque" min-width="150" fixed prop="marque"></el-table-column>
          <el-table-column label="Modèle" min-width="200" prop="model"></el-table-column>
          <el-table-column label="Puissance fiscale" min-width="180" prop="force"></el-table-column>
          <el-table-column label="Nombre de place" min-width="180" prop="nbplace"></el-table-column>
          <el-table-column label="Valeur à neuf" min-width="200" prop="valeur"></el-table-column>
          <el-table-column label="Valeur vénale" min-width="200" prop="valeur_venale"></el-table-column>
          <el-table-column label="Immatriculation" min-width="200" prop="immatriculation"></el-table-column>
          <el-table-column label="Carburation" min-width="200" prop="carburation"></el-table-column>
        </el-table>
        <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
          :total="totalContrat"></el-pagination>

        <el-row :gutter="15" v-if="detailsContrat.user_on_gray_card">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Nom sur la carte grise : <span class="to-right assuraf-orange">{{
                      detailsContrat.user_on_gray_card.nom.toUpperCase() }}</span></li>
                    <li>Prenom sur la carte grise : <span class="to-right assuraf-orange">{{
                      detailsContrat.user_on_gray_card.prenom.toUpperCase() }}</span>
                    </li>

                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" v-if="JsonView" style="margin-top: 15px">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>Détails en JSON </span>
                </div>
                <div>
                  <pre style="background:black;color:white">
                      {{ detailsContrat }}
                    </pre>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog width="70%" title="Renouvellement" :visible.sync="popuprenouvellementmicro"
      v-if="popuprenouvellementmicro == true && !isMobile">
      <div v-if="detailsContrat.brancheid.branche == 'Assur-Hospi'">

        <el-row :gutter="15">
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Formule </label>
              <el-select style="width:100%" @change="setFormuleCapitaux" v-model="Assurhospi.capital"
                placeholder="Selectionner une formule">
                <el-option value="Selectionner une formule" selected :disabled="true"></el-option>
                <el-option v-for="(items, idx) in listecapitaux" :key="idx" :label="items.capital" :value="items.capital">
                  {{ items.capital.toLocaleString() + " CFA" }}
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Durée</label>
              <el-select style="width: 100%!important;" @change="dateDiff" v-model="Assurhospi.duree"
                placeholder="Selectionner">
                <el-option value="Nombre de mois" selected :disabled="true"></el-option>
                <el-option v-for="items in month" :key="items" :label="items" :value="items">
                  {{ items }}
                </el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="12" class="mt-10">
            <div class="grid-content bg-purple">
              <label>Date d'effet</label>
              <el-date-picker style="width: 100%!important;" v-model="Assurhospi.dateEffet" @change="dateDiff" type="date"
                placeholder="Choississez une date" :picker-options="dateAfterToday">
              </el-date-picker>
            </div>
          </el-col>

          <el-col :span="12" class="mt-10">
            <div class="grid-content bg-purple">
              <label>Date d'échéance</label>
              <el-input readOnly placeholder="Date d'échéance" v-model="Assurhospi.dateEcheance">
              </el-input>
            </div>
          </el-col>
        </el-row>
        <el-row class="mt-15" :gutter="15">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              Capital décès :
              <span class="assuraf-orange">{{ Assurhospi.capital.toLocaleString() || null }} CFA</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple text-center">
              Capital hospitalisation :
              <span class="assuraf-orange">{{ Assurhospi.hospitalisation.toLocaleString() || null }} CFA</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple to-right">
              Prime : <span class="assuraf-orange">{{ Assurhospi.prime.toLocaleString() || null }} CFA</span>
            </div>
          </el-col>

        </el-row>
        <div style="margin-top:15px">
          <el-button type="primary" style="float:left" v-loading.fullscreen.lock="modalgenerationloading"
            @click="SendDataAssurHospi" round>Valider</el-button> <br />
        </div>
        <div style="margin-top:10px">
          <h3 style="text-align:center!important;color:red">* La date d'effet doit être supérieure à : <span
              style="color:#ffa409">{{ DateFormater(detailsContrat.dateEcheance) }}</span></h3>
        </div>


        <el-row :gutter="15" style="margin-top:15px">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Date de Souscription : <span class="to-right assuraf-orange">{{
                      DateFormater(detailsContrat.dateSouscription) }}</span></li>
                    <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{ detailsContrat.numero_police
                    }}</span></li>
                    <li>Numéro Police Assureur : <span class="to-right assuraf-orange">{{ detailsContrat.police_assureur
                    }}</span></li>
                    <li>Date Effet : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEffet)
                    }}</span></li>
                    <li>Date Echéance : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEcheance)
                    }}</span></li>
                    <li>Prime Nette : <span class="to-right assuraf-orange">{{ detailsContrat.prime_nette.toLocaleString()
                    }}</span></li>
                    <li>Prime TTC : <span class="to-right assuraf-orange">{{ detailsContrat.prime_ttc.toLocaleString()
                    }}</span></li>
                    <li>Capital décès : <span class="to-right assuraf-orange">{{
                      detailsContrat.details[0].capital.toLocaleString()
                    }}</span></li>
                    <li>Capital hospitalisation : <span class="to-right assuraf-orange">{{
                      detailsContrat.details[0].hospitalisation.toLocaleString()
                    }}</span></li>
                    <li>Durée : <span class="to-right assuraf-orange">{{ detailsContrat.details[0].duree.toLocaleString()
                    }}</span></li>
                    <li v-if="detailsContrat.partner_creator != null">Partenaires : <span
                        class="to-right assuraf-orange">{{ detailsContrat.partner_creator }}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Prénom : <span class="to-right assuraf-orange">{{ detailsContrat.user.prenom.toUpperCase()
                    }}</span>
                    </li>
                    <li>Nom : <span class="to-right assuraf-orange">{{ detailsContrat.user.nom.toUpperCase() }}</span>
                    </li>
                    <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsContrat.user.telephone_port }}</span>
                    </li>
                    <li>Email : <span class="to-right assuraf-orange">{{ detailsContrat.user.email }}</span></li>
                    <li>Date inscription : <span class="to-right assuraf-orange">{{
                      DateFormater(detailsContrat.user.created) }}</span></li>
                    <li>
                      <img :src="detailsContrat.user.photo" alt="" width="200">
                    </li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"
import AssurHospi from "../../../models/micro/Assurhospi"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      dateAfterToday: {
        disabledDate(time) {
          return time.getTime() < (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
        }
      },

      listecapitaux: [
        {
          capital: 100000,
          hospitalisation: 150000,
          prime: 340,
        },
        {
          capital: 500000,
          hospitalisation: 750000,
          prime: 1700,
        },
        {
          capital: 1000000,
          hospitalisation: 1500000,
          prime: 3400,
        }
      ],
      formule: null,
      Assurhospi: new AssurHospi(),
      month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      showUser: false,
      dateEffet: null,
      dateEcheance: null,
      popuprenouvellement: false,
      popuprenouvellementmicro: false,
      show_comment_contrat: false,
      set_comment_contrat: false,

      comment: {
        userid: null,
        comment_text: null,
        contratid: null,
        createdat: null,
      },
      branche: null,
      assureur: null,
      assureurlist: null,
      branchlist: null,
      commentlist: null,
      commentcount: null,
      JsonView: false,
      Delete_reason: null,
      checked: null,
      user_name: true,
      client_name: false,
      isToday: false,
      isNotToday: false,
      Sunu: false,
      du: null,
      au: null,
      Askia: false,
      Axa: false,
      Amsa: false,
      devisclient: false,
      modalgeneration: false,
      modalNumPolice: false,
      modalDelete: false,
      modal_Delete_ok: false,
      modalNumPoliceAttest: false,
      NumPolice: null,
      NomPrenom: null,
      PrenomSurCarte: null,
      NomSurCarte: null,
      categorie: null,
      NumPoliceAttest: null,
      NumTalon: null,
      showUser: false,
      username: null,
      paramclient: null,
      data: {},
      html: "",
      detailsContrat: [],
      detailsUser: null,
      perPage: 10,
      totalRows: null,
      listuser: null,
      listcontrat: [],
      userid: null,
      userRole: null,
      showcontrat: false,
      showAttestation: false,
      filter: null,
      notification_text: null,
      sonam: "SONAM GENERALE ASSURANCES",
      useremail: null,
      totalItems: null,
      isBusy: false,
      optionsdateModif: {
        useCurrent: false,
        format: 'YYYY/MM/DD',
      },
      dateEffet: null,
      dateEcheance: null,
      duree: null,
      CalculDuree: null,
      JsonView: false,


      specialUpdatedevis: false,

      modalgenerationloading: false,
      optionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      ldevis: [],
      du: null,
      au: null,
      listdevis: [],
      modalgeneration: false,
      detailsDevis: [],
      showdevis: false,
      pageOptions: [5, 10, 15],



      modalInfo: {
        title: '',
        content: ''
      },

      userflette: [],
      fletteRows: null,

      editUser: false,
      Selectedbranche: null,
      selectedAssureur: [],
      configUserDevisAssureur: null,
      assureurs: [],
      branchelist: null,
      modalSociete: false,

      selectedUser: null,
      listeAssureurs: null,
      usermenu: [],
      collapse: null,
      menu_name: null,
      showConfigMenu: false,
      submenu: null,
      userSubmenu: [],
      UserFinalMenu: [],
      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listdevis,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      return this.listcontrat.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },

    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = ""
          let val2 = ""

          val1 = item1[prop]
          val2 = item2[prop]
          if (order === "descending") {
            return val2 < val1 ? -1 : 1
          }
          return val1 < val2 ? -1 : 1
        })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs["my-table"].$el;
        if (newVal === true) {
          tableScrollBody.classList.add("overflow-hidden");
        } else {
          tableScrollBody.classList.remove("overflow-hidden");
        }
      }
    },

    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {

    relance_status(val)
    {
      if(val=="S1")
      {
        return "Prémière relance éffectuée"
      }else if(val=="S2"){
        return "Deuxième relance éffectuée"
      }else{
        return "Aucune relance éffectuée"
      }
    },
    relance_sms(contrat)
    {
      Api().post('/contrat/relance_sms', { contratId: contrat.id })
        .then(result => {
          if (result.data.status == "OK") {
            this.$message.success(result.data.msg, this.AlertMessage)
          }
          if (result.data.status == "NOK") {
            this.$message.error(result.data.msg, this.AlertMessage)
          }
        }).catch(err => {
          this.$message.error("La relance a échoué !", this.AlertMessage)
        })
    },

    check_AvRec(tab) {
      let checker = 0;
      for (let v of tab) {
        if (v.garantie == "AVANCE SUR RECOURS") {
          checker = v.capital;
        }
      }
      if (!checker || checker <= 0) {
        return 5000000
      } else {
        return checker
      }
    },
    SendDataAssurHospi() {
      if (this.Assurhospi.dateEffet && this.Assurhospi.dateEcheance && this.Assurhospi.duree && this.Assurhospi.capital) {
        this.modalgenerationloading = true
        const Sunu = {
          Contractant: this.Assurhospi.Contractant,
          Assure: this.Assurhospi.Assure,
          Beneficiaire: this.Assurhospi.Beneficiaire,
          duree: this.Assurhospi.duree,
          branche: this.Assurhospi.branche,
          assureurs: this.Assurhospi.assureur,
          userid: this.detailsContrat.user.id, //store.state.user._id,
          clientid: this.detailsContrat.user.id, //store.state.user._id,
          createur: store.state.user._id,
          garanties: this.Assurhospi.garanties,
          dateEffet: dayjs(this.Assurhospi.dateEffet).format("YYYY-MM-DD"),
          dateEcheance: this.Assurhospi.dateEcheance,
          dateSouscription: new Date(),
          prime_mensuelle: this.Assurhospi.prime,
          capital: Number(this.Assurhospi.capital),
          hospitalisation: Number(this.Assurhospi.hospitalisation),
          FuturPaiement: null,
          plateforme: 'Dash',
        };
      
        if (this.$store.state.user.role == "Agent") {
          Sunu.courtierid = this.$store.state.user.societe;
        }
        Api()
          .post("/micro/devis/sunuvie", Sunu)
          .then((result) => {
            Api()
              .get("devis/" + result.data.devis._id)
              .then((response) => {
                this.$store.dispatch("setDevisPayement", response.data);
                setTimeout(() => (
                  this.modalgenerationloading = false,
                  this.$router.go(this.$router.push({
                    name: 'paiement'
                  }))), 4000
                );
              });
          })
          .catch((err) => {
            this.modalgenerationloading = false;
            console.log("error");
          });

        setTimeout(() => {
          this.$message.error('Erreur: Veuillez réessayer plus tard !', this.AlertMessage)
          this.modalgenerationloading = false
        }, 20000)
      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }
    },
    SendData() {
      if (this.dateEffet && this.dateEcheance && this.duree) {
        if (this.next_contract_date_calculation(this.detailsContrat.dateEcheance, this.dateEffet) < 0) {
          const car = this.detailsContrat.details[0].vehicules;
          const vehicule = [];
          for (let c of car) {
            vehicule.push({
              age: c.age,
              annee_mois: c.annee_mois,
              carburation: c.carburation,
              carosserie: c.carosserie,
              categorie: c.categorie,
              dateCircule: c.dateCircule,
              flotte_mono: c.flotte_mono,
              force: c.force,
              garanties: c.garanties,
              immatriculation: c.immatriculation,
              marque: c.marque,
              model: c.model,
              nbplace: c.nbplace,
              optionPersoTrans: c.optionPersoTrans,
              optionUtilitaire: c.optionUtilitaire,
              option_Individuel_Accident: c.option_Individuel_Accident,
              tonnage: c.tonnage,
              valeur: c.valeur,
              valeur_venale: c.valeur_venale,
              volumetrie2_roues: c.volumetrie2_roues,
              capitalAvRec: this.check_AvRec(c.Detail_Prime)
            });
          }

          const OneautoToSave = {
            userid: this.detailsContrat.user._id,
            proprietaire: {
              prenom: this.detailsContrat.user.prenom,
              nom: this.detailsContrat.user.nom,
            },

            dateEffet: dayjs(this.dateEffet).format("YYYY-MM-DD"),
            dateEcheance: dayjs(this.dateEcheance).format("YYYY-MM-DD"),
            createur: this.$store.state.user._id,
            branche: "Automobile",
            duree: this.duree,
            dateSouscription: dayjs(new Date()).format("YYYY-MM-DD"),
            vehicule: vehicule,
            assureurs: [this.detailsContrat.partenaireid.societe],
          };

          if (this.$store.state.user.role == "Courtier" || this.$store.state.user.role == "Agent") {
            OneautoToSave.courtierid = this.$store.state.user.societe;
          }
          Api().post("devis/add", OneautoToSave)
           .then((result) => {
              if (result.data.message) {
                this.$store.dispatch("setDevis", result.data);
                this.$router.go(this.$router.push({ name: "liste_devis_paiement" }));
              }
            });
        } else {
          this.$message.error("Veuillez respecter la date d'effet mentionnée en rouge !", this.AlertMessage)
        }
      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }
    },

    next_contract_date_calculation(echance, effet) {
      let dateecheance = dayjs(echance).format("YYY-MM-DD");
      let dateeffet = dayjs(effet).format("YYY-MM-DD");
      let diff_aboth = dayjs(dateecheance).diff(dateeffet, "days");
      return diff_aboth;
    },

    //calclu date echéance
    dateDiff: function () {
      if (this.detailsContrat.brancheid.branche == "Assur-Hospi") {
        this.dateEffet = this.Assurhospi.dateEffet
        this.duree = this.Assurhospi.duree
        this.Assurhospi.prime = this.formule.prime * this.Assurhospi.duree
      }
      if (this.dateEffet && this.duree) {
        let inter = dayjs(this.dateEffet)
          .add(this.duree, "month")
          .format("YYYY-MM-DD");
        this.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
      } else {
        if (this.detailsContrat.brancheid.branche != "Assur-Hospi") {
          this.duree = 1;
          this.dateEffet = dayjs(new Date()).format("YYYY-MM-DD");
          let inter = dayjs(this.dateEffet)
            .add(this.duree, "month")
            .format("YYYY-MM-DD");
          this.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");

        }
      }
      if (this.detailsContrat.brancheid.branche == "Assur-Hospi" && this.dateEffet) {
        this.Assurhospi.dateEcheance = this.dateEcheance
      }


    },

    SetDetailRenouvellement() {
      this.popuprenouvellement = true;
    },

    Edit_devis: function (item) 
    {
      switch (item.brancheid.branche) 
      {
        case 'Automobile':
          Api().get('devis/' + item.devisid._id)
              .then(response => {
                this.$store.dispatch('setDevisReopen', response.data.devis)
                this.$router.go(this.$router.push({ name: 'avenant_update_devis_auto'}))
              })
          break
      }
    },


    detailRenouvellementAssurHospi() {
      this.Assurhospi.Contractant = this.detailsContrat.details[0].contractor,
        this.Assurhospi.Assure = this.detailsContrat.details[0].customer,
        this.Assurhospi.Beneficiaire = this.detailsContrat.details[0].beneficiary,
        this.Assurhospi.capital = this.detailsContrat.details[0].capital,
        this.Assurhospi.hospitalisation = this.detailsContrat.details[0].hospitalisation,
        this.Assurhospi.duree = this.detailsContrat.details[0].duree,
        this.Assurhospi.prime = this.detailsContrat.details[0].prime_mensuelle,
        this.Assurhospi.garanties = this.detailsContrat.devisid.garanties,

        this.formule = this.listecapitaux.find(cap => cap.capital == this.Assurhospi.capital)
      this.popuprenouvellementmicro = true;
    },
    tableRowClassName({ row, rowIndex }) {
      const today = dayjs(new Date()).format("YYYY-MM-DD");
      const expiredate = row.dateEcheance;
      const expirationCountDay = dayjs(expiredate).diff(today, "days");

      if (expirationCountDay >= 10 && expirationCountDay <= 19) {
        return 'success-row';
      } else if (expirationCountDay >= 20 && expirationCountDay <= 30) {
        return 'purple-row';
      } else {
        return 'warning-row'
      }
      return '';
    },

    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      } else {
        const today = dayjs(new Date()).format("YYYY-MM-DD");
        const expiredate = item.dateEcheance;
        const expirationCountDay = dayjs(expiredate).diff(today, "days");

        if (expirationCountDay >= 10 && expirationCountDay <= 19) {
          return "warning-row";
        } else if (expirationCountDay >= 20 && expirationCountDay <= 30) {
          return "success-row";
        } else {
          return "warning-row";
        }
      }
    },

    JsonFormaterView() {
      if (this.JsonView == true) {
        this.JsonView = false;
      } else if (this.JsonView == false) {
        this.JsonView = true;
      }
    },

    getListPartner() {
      Api()
        .get("/partenaire")
        .then((resultat) => {
          this.assureurlist = resultat.data.partenaires;
        });
    },

    getBranche() {
      Api()
        .get("/branche")
        .then((resultat) => {
          this.branchlist = resultat.data;
        });
    },

    checkedControl() {
      if (this.checked) {
        this.client_name = false;
        this.user_name = true;
        this.NomPrenom = null;
        this.PrenomSurCarte = null;
        this.NomSurCarte = null;
      } else if ((this.checked = true)) {
        this.client_name = true;
        this.user_name = false;
      }
    },

    CheckIfTypeDefined(item) {
      return typeof item;
    },

    /*==== EXPORT Liste Contrat en XLS ====*/
    exportToExcel() {
      if (
        this.du != "" &&
        this.du != null &&
        this.au != "" &&
        this.au &&
        this.branche != "" &&
        this.branche != null &&
        this.assureur != "" &&
        this.assureur != null
      ) {
        const data = {
          from_date: this.du,
          to_date: this.au,
          branche: this.branche,
          assureur: this.assureur,
        };

        Api()
          .post("/commentcontract/user_filter/toxls", data)
          .then((resultat) => {
            this.modalgenerationloading = true;
            let url = resultat.data.lien;
            setTimeout(() => (
              this.modalgenerationloading = false,
              window.open(url)), 7000
            );
          });
      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }
    },

    sendComment() {
      this.comment.userid = this.$store.state.user._id;
      this.comment.contratid = this.detailsContrat.id;
      this.comment.createdat = dayjs(new Date());
      if (
        this.comment.userid != "" &&
        this.comment.userid != null &&
        this.comment.contratid != "" &&
        this.comment.contratid != null &&
        this.comment.comment_text != null &&
        this.comment.comment_text != ""
      ) {
        Api()
          .post("/commentcontract/create", this.comment)
          .then((result) => {
            if (result.data.status == 1) {
              this.comment.userid = null;
              this.comment.contratid = null;
              this.comment.comment_text = null;
              this.comment.createdat = null;
              this.$message.success(result.data.msg, this.AlertMessage)
            } else {
              this.$message.error(result.data.msg, this.AlertMessage)
            }
          })
          .catch((err) => {
            this.$message.error('Error 400 !', this.AlertMessage)
          });
      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }
    },

    SetCommentDetail() {
      this.set_comment_contrat = true;
    },

    getCommentDetail() {
      Api()
        .post("/commentcontract/listing/bycontratid", {
          id: this.detailsContrat.id,
        })
        .then((res) => {
          this.commentlist = res.data.comments;
          this.commentcount = res.data.count;
          this.show_comment_contrat = true;
        })
        .catch((err) => {
          this.$message.error('Error 404 !', this.AlertMessage)
        });
    },

    /*Get comment for details or add comment on contract*/
    Get_set_Comment(item) {
      this.detailsContrat = item;
      this.showcontrat = true;
    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format("DD-MM-YYYY");
      }
    },

    getContrat() {
      if (this.$store.state.user.role == "Courtier" || this.$store.state.user.role == "Agent") {
        Api()
          .get(
            "/commentcontract/filtre/expired/onemonth/courtier/" +
            this.$store.state.user.societe
          )
          .then((resultat) => {
            this.listcontrat = resultat.data.contrats;
            this.totalRows = resultat.data.contrats.length;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.$store.state.user.role == "SuperAdmin" || this.$store.state.user.role == 'Admin') {
        Api()
          .get("/commentcontract/filtre/expired/onemonth")
          .then((resultat) => {
            this.listcontrat = resultat.data.contrats;
            this.totalRows = resultat.data.contrats.length;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    SetDetailUser(details) {
      this.detailsUser = details;
      this.showUser = true;
    },

    FiltreDate() {
      if (
        this.du != "" &&
        this.au != "" &&
        this.branche != "" &&
        this.assureur != ""
      ) {
        const data = {
          from_date: this.du,
          to_date: this.au,
          branche: this.branche,
          assureur: this.assureur,
        };

        Api()
          .post("/commentcontract/user_filter", data)
          .then((resultat) => {
            this.listcontrat = resultat.data.contrats;
          });
      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }
    },
    VoidFiltreDate() {
      this.du = null;
      this.au = null;
      this.branche = null;
      this.assureur = null;
      this.getContrat();
    },



    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000
      });
    },

    toggleDetails(item) {
      this.detailsUser = item
      this.editUser = true
    },

    DeleteBrancheCourtier(item) {
      Api().post("/branchecourtier/delete", { id: item._id })
        .then((result) => {
          Api().post("/branchecourtier/get/all/", { userid: item.userid })
            .then((response) => {
              this.configUserDevisAssureur = response.data.partenaires
            })
            .catch((err) => { });
        }).catch((err) => {

        });
    },

    AddbrancheConfig() {
      Api().post("/branchecourtier/create", { configuration: this.configUserDevisAssureur })
        .then((result) => {
          this.configUserDevisAssureur = []
        }).catch((err) => {
          console.log(err)
        });
    },

    pushConfig() {
      if (this.Selectedbranche && this.selectedAssureur) {
        this.configUserDevisAssureur.push({
          branche: this.Selectedbranche,
          assureurs: this.selectedAssureur,
          userid: this.selectedUser.id
        })
        this.Selectedbranche = null
        this.selectedAssureur = []
      }
    },

    loadConfigBranchePopUp(item) {
      this.modalSociete = true
      this.selectedUser = item
      Api().post("/branchecourtier/get/all/", { userid: item.id })
        .then((response) => {
          this.configUserDevisAssureur = response.data.partenaires
        })
        .catch((err) => { });
    },

    setCollapse() {
      this.collapse = "id_" + Date.now() + "_collapse"
    },

    MenuDelete(item) {
      Api().delete("/menu/" + item._id)
        .then((result) => {
          this.getUserMenu(this.detailsUser._id)
        }).catch((err) => {

        });
    },


    pushMenu() {
      if (this.menu_name && this.userSubmenu.length >= 1) {
        this.UserFinalMenu.push({
          user: this.detailsUser.id,
          theme: this.menu_name,
          collapse: this.collapse,
          config: this.userSubmenu
        })
        this.menu_name = null
        this.userSubmenu = []
        this.collapse = null
      } else {
        this.$message.error('Remplissez les champs vides !', this.AlertMessage)
      }
    },

    create_user_menu() {
      if (this.UserFinalMenu.length >= 1) {
        Api().post("/menu/add", this.UserFinalMenu)
          .then((result) => {
            this.$message.error('Opération éffectuée !', this.AlertMessage)
            this.UserFinalMenu = []
          }).catch((err) => {
            console.log(err)
          });
      } else {
        this.$message.error('Le menu ne doit pas être vide !', this.AlertMessage)
      }
    },

    merge() {
      Api().post('/user/merge/userdata', { userid: this.detailsUser.id })
        .then((result) => {
          this.showuser = false
          if (result.data.result) {
            alert('Opération réussie')
          }
        }).catch((err) => {
          console.log('=======err=========')
        });
    },

    detailuser(item) {
      this.getUserMenu(item._id)
      this.detailsUser = item
      this.showuser = true
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList: function () {
      switch (this.$store.state.user.role) {
        case 'SuperAdmin':
          Api().get('/user/frontoffice/')
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;
        case 'Admin':
          Api().get('/user/frontoffice/')
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;


        case 'assureur':
          Api().get('/user/myuser/assureur/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
        .then(response => {
          if (response.data) {
            this.showAlert()
          }
          this.showAlert()
        })
        .catch(err => {

        })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },

    DeletingUser(id) {
      Api().delete('user/delete/' + id)
        .then(resultat => {
          if (resultat.status == 200) {
            this.modalDelete = false
            this.getUserList()
          }
        })
        .catch(err => { })
    },

    getUserMenu(id) {
      Api().post('/menu/user/menu', { userid: id })
        .then((result) => {
          this.usermenu = result.data.menus
        }).catch((err) => {
          console.log(err)
        });
    },








    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function (e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function (value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
    //this.getDevis()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getListPartner();
      this.getBranche();
      this.getContrat();
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {

      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }

          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      &>* {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}

.button-filtre {
  padding: 12px !important;
  margin: 0 15px !important;
  background: #C0C7CF !important;
  border: none !important;
  color: #fff !important;
}

.el-table .warning-row {
  background: #FFB8C8;
}

.el-table .success-row {
  background: #FFD8BE;
}

.el-table .purple-row {
  background: #E2CFEC
}
</style>
