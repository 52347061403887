<template>
  <div
    class="page-table column scrollable only-y"
    :class="{ flex: !isMobile, overflow: isMobile }"
  >
    <div class="page-header">
      <h1>Liste des <span style="color: #ffa409">Flottes</span></h1>
    </div>
    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
          placeholder="Rechercher vehucules..."
          prefix-icon="el-icon-search"
          v-model="search"
          clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>
    <div v-if="isMobile" style="margin-bottom: 15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top: 15px">
          <el-input
            placeholder="Rechercher de flotte..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />
    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
    </div>

    <div  class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile" >
      <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready" @selection-change="handleSelectionChange">
        <el-table-column label="Client" prop="user" min-width="150">
          <template slot-scope="scope">
            {{ viewClient(scope.row.vehicules[0].user) }}
          </template>
        </el-table-column>

        <el-table-column label="Email" prop="user" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.vehicules[0].user.email }}
          </template>
        </el-table-column>

        <el-table-column label=" Numéro flotte" prop="_id" min-width="150" fixed>
          <template slot-scope="scope">
            {{ scope.row._id }}
          </template>
        </el-table-column>

        <el-table-column
          label="Date de création"
          prop="created"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ DateFormater(scope.row.vehicules[0].created) }}
          </template>
        </el-table-column>

        <el-table-column
          label="Nombre de vehicules"
          prop="totalVehicule"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.totalVehicule }}
          </template>
        </el-table-column>

        <el-table-column label="Statut" prop="status" min-width="100">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.vehicules[0].status">
              validée
            </span>
            <span style="color: red" v-else> non validée </span>
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="Actions"
          prop="action"
          min-width="100"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="gotoDevis(scope.row._id)"
              icon="el-icon-view"
              circle
              title="Voir détails"
            ></el-button>
            <el-button
              size="mini"
              type="primary"
              v-loading.fullscreen.lock="modalgenerationloading"
              @click="cloneFlottes(scope.row)"
              icon="el-icon-copy-document"
              title="copié"
              circle
            ></el-button>
          </template>
        </el-table-column>
      
      </el-table>
      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page" :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout" :total="total" >
      </el-pagination>
    </div>
    
    <el-dialog
      title="Validation flotte"
      width="50%"
      :visible.sync="modalValid"
      v-if="modalValid == true"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous valider ce flotte ?</span>
          </div>
          <div >
            <ul style="list-style: none">
              <li>
                <h3>
                  Client :
                  <span class="to-right assuraf-orange"
                    >{{ detailsusers.prenom }} {{ detailsusers.nom }}</span
                  >
                </h3>
              </li>
              <li>
                <h3>
                  Telephone :
                  <span class="to-right assuraf-orange">{{
                    detailsusers.telephone_port
                  }}</span>
                </h3>
              </li>
              <li>
                <h3>
                  Email :
                  <span class="to-right assuraf-orange">{{
                    detailsusers.email
                  }}</span>
                </h3>
              </li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top: 15px">
        <el-button
          type="primary"
          round
          v-loading.fullscreen.lock="modalgenerationloading"
          @click="generateDevis(detailsusers)"
          >Oui</el-button
        >
        <el-button type="secondary" round @click="modalValid = false"
          >Non</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="Copie de flotte" width="80%" :visible.sync="modalClone" v-if="modalClone == true">
      <el-row>
        <el-col :span="12">
          <el-input
            placeholder="Rechercher de vehicules..."
            prefix-icon="el-icon-search"
            v-model="searchClone"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="12" class="text-right">
          <el-button type="primary" round @click="creerFlotte()"
            >Suppression</el-button
          >
        </el-col>
      </el-row>
      <div
        style="margin-top: 15px"
        class="table-box card-base card-shadow--medium box grow"
        id="table-wrapper-clone"
        v-loading="!readyClone"
        v-if="!isMobile"
      >
        <el-table
          :data="cloneInPage"
          style="width: 100%"
          :height="height"
          v-if="readyClone"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="Marque"
            min-width="200"
            prop="marque"
          ></el-table-column>
          <el-table-column
            label="Modele"
            min-width="150"
            prop="model"
          ></el-table-column>
          <el-table-column
            label="Immatriculation"
            min-width="130"
            prop="immatriculation"
          ></el-table-column>
          <el-table-column
            label="Date circulation"
            prop="dateCircule"
            min-width="150"
          >
            <template slot-scope="scope">
              {{ DateFormater(scope.row.dateCircule) }}
            </template>
          </el-table-column>

          <el-table-column label="Tonnage" min-width="100" prop="tonnage">
            <template slot-scope="scope">
              {{ viewTonnage(scope.row.tonnage) }}
            </template>
          </el-table-column>
          <el-table-column label="Garantie" prop="garanties" min-width="280">
            <template slot-scope="scope">
              <span
                style="margin-letf: -20px"
                v-html="viewGaranties(scope.row.garanties)"
              ></span>
            </template>
          </el-table-column>

          <el-table-column
            label="Catégorie"
            min-width="100"
            prop="categorie"
          ></el-table-column>

          <el-table-column
            label="Carrosserie"
            min-width="150"
            prop="carosserie"
          ></el-table-column>

          <el-table-column
            label="Force"
            min-width="100"
            prop="force"
          ></el-table-column>

          <el-table-column
            label="Nombre de places"
            min-width="150"
            prop="nbplace"
          ></el-table-column>
          <el-table-column
            label="Valeur"
            min-width="150"
            prop="valeur"
          ></el-table-column>

          <el-table-column
            label="Valeur venale"
            min-width="150"
            prop="valeur_venale"
          ></el-table-column>
          <el-table-column
            label="Option utilitaire"
            min-width="150"
            prop="optionUtilitaire"
          ></el-table-column>
          <el-table-column
            label="Carburation"
            min-width="100"
            prop="carburation"
          ></el-table-column>

          <el-table-column
            label="Option Personne Transportée"
            min-width="220"
            prop="optionPersoTrans"
          ></el-table-column>

          <el-table-column
            label="Option individuel accident"
            min-width="200"
            prop="option_Individuel_Accident"
          ></el-table-column>
          <el-table-column
            label="Volumetrie"
            min-width="150"
            prop="volumetrie2_roues"
          ></el-table-column>

          <el-table-column
            fixed="right"
            label="Actions"
            prop="action"
            min-width="130"
          >
            <template slot-scope="scope">
              <el-checkbox
                @change="toggleCheckedFlottesChange($event, scope.row)"
                label="à supprimer"
                size="small"
                border
              />
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="readyClone"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="totalClone"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from "../../../services/Api";
import dayjs from "dayjs";

export default {
  data: () => ({
    checkedVehicules: [],
    reference: "",
    flottes: [],
    modalClone: false,
    modalValid: false,
    modalgenerationloading: false,
    detailsvehicules: [],
    detailsusers: {},
    showvehicules: false,
    tableData: [],
    ready: false,
    width: 0,
    height: "auto",
    PuissanceFinscaleItems: [
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      "24+",
    ],

    search: "",
    searchClone: "",
    loading: false,
    itemsChecked: [],
    pagination: {
      page: 1,
      size: 100,
      sizes: [10, 15, 20, 30, 50, 100],
      layout: "total, ->, prev, pager, next, jumper, sizes",
      small: false,
    },
  }),
  computed: {
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.pagination.page = val;
      },
    },
    currentPageClone: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.pagination.page = val;
      },
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage;
      let to = from + this.itemPerPage * 1;
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to);
    },
    total() {
      return this.listFiltered.length;
    },

    cloneInPage() {
      let from = (this.currentPageClone - 1) * this.itemPerPageClone;
      let to = from + this.itemPerPageClone * 1;
      //return this.listSortered.slice(from, to)
      return this.cloneFiltered.slice(from, to);
    },
    totalClone() {
      return this.cloneFiltered.length;
    },

    cloneFiltered() {
      return this.flottes.filter((obj) => {
        let ctrl = false;
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.searchClone.toLowerCase()) !== -1
          )
            ctrl = true;
        }
        return ctrl;
      });
    },

    listFiltered() {
      if (this.search.length >= 1) {
        return this.tableData.filter((obj) => {
          let ctrl = false;
          for (let k in obj) {
            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
            if (
              obj[k] &&
              obj[k]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              ctrl = true;
            } else {
              /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
              if (Array.isArray(obj[k])) {
                obj[k].filter((nobj) => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (
                      nobj[l] &&
                      nobj[l]
                        .toString()
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) !== -1
                    ) {
                      ctrl = true;
                    } else {
                      for (const key in nobj[l]) {
                        if (Object.hasOwnProperty.call(nobj[l], key)) {
                          const element = nobj[l][key];
                          if (
                            element &&
                            element
                              .toString()
                              .toLowerCase()
                              .indexOf(this.search.toLowerCase()) !== -1
                          ) {
                            ctrl = true;
                          }
                        }
                      }
                    }
                  }
                });
                /* Si la valeur courante n'est ni String, ni Tableau mais Object */
              } else if (
                !Array.isArray(obj[k]) &&
                typeof obj[k] == "object" &&
                obj[k] != null
              ) {
                /*== On crée un nouveau tablequ vide ==*/
                let my_new_array = [];

                /*== On push la valeur courante dans le nouveau tableau ==*/
                my_new_array.push(obj[k]);

                /*On refiltre le nouveau tableau atrribut par attribut*/
                my_new_array.filter((nobj) => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (
                      nobj[l] &&
                      nobj[l]
                        .toString()
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) !== -1
                    ) {
                      ctrl = true;
                    }
                  }
                });
              }
            }
          }
          return ctrl;
        });
      } else {
        return this.tableData.filter((obj) => {
          let ctrl = false;
          for (let k in obj) {
            if (
              obj[k] &&
              obj[k]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true;
          }
          return ctrl;
        });
      }
    },
    itemPerPage() {
      return this.pagination.size;
    },
    itemPerPageClone() {
      return this.pagination.size;
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    itemPerPage(val) {
      this.ready = false;
      this.currentPage = 1;

      setTimeout(() => {
        this.ready = true;
      }, 500);
    },
    itemPerPageClone(val) {
      this.readyClone = false;
      this.currentPageClone = 1;

      setTimeout(() => {
        this.readyClone = true;
      }, 500);
    },
    searchClone(val) {
      this.currentPageClone = 1;
    },
  },
  methods: {
    exportToExcel(){
          //API de genereation donne en excel
        },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000,
      });
    },

    creerFlotte() {
      if (this.checkedVehicules.length != 0) {
        Api()
          .post("/tempflotte/vehicule/copy", {
            vehicules: this.checkedVehicules,
          })
          .then((result) => {
            if (result.data.vehicules.length != 0) {
              this.$message.success(
                "Nouvelle flotte généré avec success !",
                this.AlertMessage
              );
              this.$router.push(
                "/flotte/uploadexcel/" + result.data.vehicules[0].reference
              );
            } else {
              this.$message.error(
                "Aucune vehicule générée !",
                this.AlertMessage
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error(
          "La liste des véhicules est vide !",
          this.AlertMessage
        );
      }
    },
    toggleCheckedFlottesChange(value, item) {
      if (!value) {
        this.checkedVehicules.push(item._id);
      } else {
        const r = this.checkedVehicules.find(
          (vehicule) => vehicule === item._id
        );
        if (r !== undefined) {
          this.checkedVehicules.splice(this.checkedVehicules.indexOf(r), 1);
        }
      }
    },

    generateDevis(item) 
    {
      console.log("Item ===> " , item)
      this.modalgenerationloading = true;
      setTimeout(() => {
        this.modalgenerationloading = false;
        this.$router.push("/flotte/generation/" + item._id);
        this.modalValid = false;
      }, 1000);
    },

    gotoDevis(id) {
      this.$router.push("/flotte/uploadexcel/" + id);
    },

    cloneFlottes(item) {
      this.checkedVehicules = [];
      this.reference = item._id;
      this.flottes = item.vehicules;
      this.flottes.forEach((element) => {
        this.checkedVehicules.push(element._id);
      });
      this.detailsusers = item.vehicules[0].user;
      this.modalClone = true;
      this.calcDimsClone();
    },

    viewGaranties(garanties) {
      if (garanties.length !== 0) {
        return `<span> ${this.li(garanties)}</span> `;
      }
      return "";
    },

    li(list) {
      let view = "";
      list.forEach((element) => {
        view = view + `<button style="margin-left: 1px">${element}</button>`;
      });
      return view;
    },

    viewTonnage(tonnage) {
      if (typeof tonnage == "string") return this.formatText(tonnage);
      return tonnage;
    },

    formatText(text) {
      if (typeof text == "string") return text.trim().replaceAll('"', "");
    },

    handleSelectionChange(val) {
      this.itemsChecked = val;
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper");
      if (tableWrapper) this.width = tableWrapper.clientWidth;

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44;
      }

      if (this.width < 480) {
        this.pagination.small = true;
        this.pagination.layout = "prev, pager, next";
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false;
        this.pagination.layout = "prev, pager, next, ->, sizes";
      } else {
        this.pagination.small = false;
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes";
      }

      this.ready = true;
    },

    calcDimsClone() {
      const tableWrapper = document.getElementById("table-wrapper-clone");
      if (tableWrapper) this.width = tableWrapper.clientWidth;

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44;
      }

      if (this.width < 480) {
        this.pagination.small = true;
        this.pagination.layout = "prev, pager, next";
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false;
        this.pagination.layout = "prev, pager, next, ->, sizes";
      } else {
        this.pagination.small = false;
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes";
      }

      this.readyClone = true;
    },

    handleResize: _.throttle(function (e) {
      this.ready = false;
      this.width = 0;
      setTimeout(this.calcDims, 1000);
    }, 500),

    getFlotteList() {
      Api()
        .get("/tempflotte/liste/nonvalider")
        .then((result) => {
          this.tableData = result.data;
        })
        .catch((err) => {});
    },
    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format("DD-MM-YYYY");
      }
    },
    viewClient(user) {
      if (user) return user.prenom + " - " + user.nom;
    },
  },
  created() {},
  mounted() {
    this.getFlotteList();
    this.calcDims();
  },
};
</script>

<style>
</style>