import axios from 'axios'
import store from '../store'
import router from '../router'
export default () => {
  const axiosConf = axios.create({
    // baseURL: `http://localhost:5004/`,
    baseURL: `https://assurapi.assuraf.com/apicloud/`,    
    headers: {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  })
  axiosConf.defaults.headers['apikey'] = store.state.apikey
  axiosConf.defaults.headers['paraphrase'] = store.state.paraphrase
  axiosConf.defaults.headers.common['Authorization'] = "Bearer " + store.state.token
  axiosConf.defaults.headers.post['Content-Type'] = 'Application/json'
  axiosConf.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
  return axiosConf
}