<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste des <span style="color:#ffa409">livraisons</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>
   
     <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
            placeholder="Rechercher livraison..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input
            placeholder="Rechercher livraison..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable>
        </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />
    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table
          :row-class-name="tableRowClassName"
          :data="listInPage"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Date création" min-width="150" fixed prop="created">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.created) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Branche" min-width="150" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Client" min-width="180" prop="client">
            <template slot-scope ="scope">
            <span class="date" v-if="scope.row.userid!=null">{{scope.row.userid.prenom}} - {{scope.row.userid.nom}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro contrat" min-width="200" prop="numero_contrat"></el-table-column>
        <el-table-column label="Zone de livraison" min-width="150" prop="delivery_zone"></el-table-column>
        <el-table-column label="Adresse de livraison" min-width="200" prop="delivery_adresse"></el-table-column>
        <el-table-column label="Type de livraison" min-width="150" prop="delivery_type"></el-table-column>
        <el-table-column label="Coût de livraison" min-width="200" prop="delivery_cost">
            <template slot-scope ="scope">
            <span class="date">{{scope.row.delivery_cost}} CFA</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailDeliveryView(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            <el-button size="mini" @click="DeliveryDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

     <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table
          :row-class-name="tableRowClassName"
          :data="listInPage"
          style="width: 100%"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Date création" min-width="150" prop="created">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.created) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Branche" min-width="150" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Client" min-width="180" prop="client">
            <template slot-scope ="scope">
            <span class="date" v-if="scope.row.userid!=null">{{scope.row.userid.prenom}} - {{scope.row.userid.nom}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro contrat" min-width="200" prop="numero_contrat"></el-table-column>
        <el-table-column label="Zone de livraison" min-width="150" prop="delivery_zone"></el-table-column>
        <el-table-column label="Adresse de livraison" min-width="200" prop="delivery_adresse"></el-table-column>
        <el-table-column label="Type de livraison" min-width="150" prop="delivery_type"></el-table-column>
        <el-table-column label="Coût de livraison" min-width="200" prop="delivery_cost">
            <template slot-scope ="scope">
            <span class="date">{{scope.row.delivery_cost}} CFA</span>
          </template>
        </el-table-column>
         <el-table-column label="Actions" prop="action" min-width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailDeliveryView(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            <el-button size="mini" @click="DeliveryDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <el-dialog width="70%" title="Détails livraison" :visible.sync="showDelivery" v-if="showDelivery && !isMobile">
      <el-row :gutter="15">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailDelivery.created)}}</span></li>
                  <li>Zone de livraison : <span class="to-right assuraf-orange">{{detailDelivery.delivery_zone}}</span></li>
                  <li>Adresse de livraison : <span class="to-right assuraf-orange">{{detailDelivery.delivery_adresse}}</span></li>
                  <li>Type de livraison : <span class="to-right assuraf-orange">{{detailDelivery.delivery_type}}</span></li>
                  <li>Coût de livraison : <span class="to-right assuraf-orange">{{detailDelivery.delivery_cost}} CFA</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
               <div  >
              <ul style="list-style:none">
                <li>Nom : <span class="to-right assuraf-orange">{{detailDelivery.userid.nom}}</span></li>
                <li>Prénom: <span class="to-right assuraf-orange">{{detailDelivery.userid.prenom}}</span></li>
                <li>Téléphone : <span class="to-right assuraf-orange">{{detailDelivery.userid.telephone_port}}</span></li>
                <li>Email : <span class="to-right assuraf-orange">{{detailDelivery.userid.email}}</span></li>
                <li>Rôle : <span class="to-right assuraf-orange">{{detailDelivery.userid.role}}</span></li>
              </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

     <el-dialog width="90%" title="Détails livraison" :visible.sync="showDelivery" v-if="showDelivery && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailDelivery.created)}}</span></li>
                  <li>Zone de livraison : <span class="to-right assuraf-orange">{{detailDelivery.delivery_zone}}</span></li>
                  <li>Adresse de livraison : <span class="to-right assuraf-orange">{{detailDelivery.delivery_adresse}}</span></li>
                  <li>Type de livraison : <span class="to-right assuraf-orange">{{detailDelivery.delivery_type}}</span></li>
                  <li>Coût de livraison : <span class="to-right assuraf-orange">{{detailDelivery.delivery_cost}} CFA</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
               <div  >
              <ul style="list-style:none">
                <li>Nom : <span class="to-right assuraf-orange">{{detailDelivery.userid.nom}}</span></li>
                <li>Prénom: <span class="to-right assuraf-orange">{{detailDelivery.userid.prenom}}</span></li>
                <li>Téléphone : <span class="to-right assuraf-orange">{{detailDelivery.userid.telephone_port}}</span></li>
                <li>Email : <span class="to-right assuraf-orange">{{detailDelivery.userid.email}}</span></li>
                <li>Rôle : <span class="to-right assuraf-orange">{{detailDelivery.userid.role}}</span></li>
              </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog title="Suppression livraison" width="50%" :visible.sync="modalDelete" v-if="modalDelete==true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous supprimer cette livraison?</span>
          </div>
          <div  >
            <ul style="list-style:none">
              <li>De : 
                <span class="to-right assuraf-orange" v-if="detailDelivery.userid!=null">
                  {{detailDelivery.userid.prenom.toUpperCase()}} - {{detailDelivery.userid.nom.toUpperCase()}}
                </span></li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="danger" round @click="DeletingDelivery(detailDelivery._id)">Oui</el-button>
        <el-button type="primary" round @click="modalDelete = false">Non</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
        username: null,
        data: {},
        ldevis: [],
        du:null,
        au:null,
        deliveryList: [],
        modalgeneration: false,
        detailDelivery: [],
        showDelivery: false,
        deleteConfirmation: false,
        modalDelete: false,
        filter: null,
        totalRows: null,
        perPage: 5,
        pageOptions: [5, 10, 15],
        fields: [
          {key: 'created', label: 'Date creation'},
          {key: 'brancheid.branche',label: 'Branche'},
          {key: 'client', label: 'Client'},
          {key: 'numero_contrat',   label:  'Numéro contrat'},
          {key: 'delivery_zone',label: 'Zone de livraison'},
          {key: 'delivery_adresse', label: 'Adresse de livraison'},
          {key: 'delivery_type', label: 'Type de Livraison'},
          {key: 'delivery_cost', label: 'Coût de Livraison'},
          {key: 'action', label: 'Action'},
        ],
      modalgenerationloading : false,
      optionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      ldevis: [],
      du: null,
      au: null,
      listdevis: [],
      modalgeneration: false,
      detailsDevis: [],
      showdevis: false,
      pageOptions: [5, 10, 15],

      fieldsFiltres: [{
        key: 'dateSouscription',
        label: 'Date creation'
      },
        {
          key: 'brancheid.branche',
          label: 'Branche'
        },
        {
          key: 'numeroDevis',
          label: 'Num devis'
        },
        {
          key: 'partenaireid.societe',
          label: 'Assureur'
        },
        {
          key: 'courtier.societe',
          label: 'Courtier'
        },
        {
          key: 'dateEffet',
          label: 'Effet'
        },
        {
          key: 'dateEcheance',
          label: 'Echeance'
        },
        {
          key: 'prime_ttc',
          label: ' Prime TTC'
        },
        {
          key: 'creator',
          label: 'Createur'
        },
        {
          key: 'action',
          label: 'Action'
        },

      ],

      ToastTabSucess: {
        theme: "toasted-primary",
        position: "top-center",
        duration: 10000,
        fitToScreen: true,
        type: "success",
        className: "custom-success-class",
      }, // Customized options for success toast
      ToastTabError: {
        theme: "toasted-primary",
        position: "top-center",
        duration: 10000,
        fitToScreen: true,
        type: "error",
      }, // Customized options for error toast
      modalInfo: {
        title: '',
        content: ''
      },
      MenuHeader:[
        {key: "theme",label: "Libéllé"},
        {key: "collapse",label: "Collapse"},
        {key: "sousmenu",label: "Sous menu"},
        {key: "action", label:"action"}
      ],
      userflette:[],
      fletteRows:null,
      carFields:[
        {key:'marque',label:'Marque', sortable: true, sortDirection: 'desc'},
        {key:'model',label:'Model', sortable: true, sortDirection: 'desc'},
        {key:'immatriculation',label:'Immatriculation', sortable: true, sortDirection: 'desc'},
        {key:'carburation',label:'Carburation', sortable: true, sortDirection: 'desc'},
        {key:'added_date',label:"Date d'ajout"},
        {key:'action',label:'Action'},
      ],
      editUser : false,
      Selectedbranche:null,
      selectedAssureur:[],
      configUserDevisAssureur:null,
      assureurs:[],
      branchelist:null,
      modalSociete: false,
      configHead:[
        { key:"branche", label:"branche"},
        { key:"assureurs", label:"assureur"},
        { key:"action", label:"Action"}
      ],
      selectedUser:null,
      listeAssureurs: null,
      usermenu:[],
      collapse:null,
      menu_name:null,
      showConfigMenu:false,
      submenu:null,
      userSubmenu:[],
      UserFinalMenu:[],

      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.deliveryList, //users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
       listFiltered() 
    {
      if(this.search.length >= 3){
          return this.deliveryList.filter(obj => {
            let ctrl = false
            for (let k in obj) 
            {
              /* Si la valeur courante est un String, et correspond à la valeur recherchée */
              if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                  ctrl = true
                  /*on le push dans le tableau */
                  //this.deliveryList.push(obj) 
              }else{
                 /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
                if(Array.isArray(obj[k])){
                  obj[k].filter(nobj=>{
                    for(let l in nobj){
                        /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                        if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                          ctrl = true
                          /* on le push dans le tableau */
                          //this.deliveryList.push(obj)
                        }
                    }
                  })
                   /* Si la valeur courante n'est ni String, ni Tableau mais Object */
                }else if(!Array.isArray(obj[k]) && typeof obj[k]=="object" && obj[k]!=null){
                  /*== On crée un nouveau tablequ vide ==*/
                  let my_new_array = []

                  /*== On push la valeur courante dans le nouveau tableau ==*/ 
                  my_new_array.push(obj[k])
                  
                  /*On refiltre le nouveau tableau atrribut par attribut*/
                  my_new_array.filter(nobj=>{
                    for(let l in nobj){
                        /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                        if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                          ctrl = true
                          
                          /* on le push dans le tableau */
                          //this.deliveryList.push(obj)
                        }
                    }
                  })
                }
              }
            }
            return ctrl
          })
      }else{
         return this.deliveryList.filter(obj => {
          let ctrl = false
          for (let k in obj) 
          {
            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
            ctrl = true
          }
          return ctrl
        })
      }

    },
    // listFiltered() {
    //   return this.deliveryList.filter(obj => {
    //     let ctrl = false
    //     for (let k in obj) {
    //       if (
    //           obj[k] &&
    //           obj[k]
    //               .toString()
    //               .toLowerCase()
    //               .indexOf(this.search.toLowerCase()) !== -1
    //       )
    //         ctrl = true
    //     }
    //     return ctrl
    //   })
    // },
    /*listFiltered() {
      return this.list.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },*/
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFiltered.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.deliveryList.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    exportToExcel(){
          //API de genereation donne en excel
        },
    tableRowClassName({row, rowIndex}) {
        if(!row) return 
        if(row.delivery_status === false) return 'warning-row'
      },
     AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
     },
    DateFormater: function(TheDate) {
        if (TheDate) { return dayjs(TheDate).format('DD-MM-YYYY') }
      },

    detailDeliveryView(item)
      {
        this.detailDelivery = item
        this.showDelivery = true

        if(item.delivery_status==false)
        {
          Api().post('/delivery/update/',item)
          .then(res=>{
              console.log(res)
              this.getDelivery()
          })
          .catch(e=>{
              console.log(e)
          })
        }
      },

      rowClass(item, type) {
        if (!item) return
        if (item.delivery_status === false) return 'table-danger'
      },

      DateFormater: function(TheDate) {
        if (TheDate) { return dayjs(TheDate).format('DD-MM-YYYY') }
      },

    getDelivery(){
      Api().get('delivery/getall')
        .then(resultat => {
          this.deliveryList = resultat.data.delivery
          this.totalRows = resultat.data.count
        })
      },

    DeliveryDeleteConfirmation (item){
        this.modalDelete = true
        this.detailDelivery = item
      },

    Remover(){
      Api().post('/sinistre/remove')
              .then(resultat => {

               })
               .catch(err=>{
               })
      },

        // Suppression de la demande d'assistance apres confirmation
      DeletingDelivery(id){
           Api().delete('/delivery/delete/'+id)
              .then(resultat => {
                  if (resultat.data.resp==1)
                    {
                      this.$message.success('Livraison supprimée avec succés', this.AlertMessage)
                      this.modalDelete = false
                      this.getDelivery()
                    }
               })
        },

    toggleDetails(item){
      this.detailsUser = item
      this.editUser = true
    },


    DeleteBrancheCourtier(item) {
      Api().post("/branchecourtier/delete", {id:item._id})
          .then((result) => {
            Api().post("/branchecourtier/get/all/", {userid:item.userid})
                .then((response) => {
                  this.configUserDevisAssureur = response.data.partenaires
                })
                .catch((err) => {});
          }).catch((err) => {

      });
    },

    AddbrancheConfig() {
      Api().post("/branchecourtier/create", {configuration:this.configUserDevisAssureur})
          .then((result) => {
            this.configUserDevisAssureur = []
          }).catch((err) => {
        console.log(err)
      });
    },

    pushConfig() {
      if(this.Selectedbranche && this.selectedAssureur)
      {
        this.configUserDevisAssureur.push({
          branche:this.Selectedbranche,
          assureurs:this.selectedAssureur,
          userid: this.selectedUser.id
        })
        this.Selectedbranche  = null
        this.selectedAssureur = []
      }
    },

    loadConfigBranchePopUp(item) {
      this.modalSociete = true
      this.selectedUser = item
      Api().post("/branchecourtier/get/all/", {userid:item.id})
          .then((response) => {
            this.configUserDevisAssureur = response.data.partenaires
          })
          .catch((err) => {});
    },

    setCollapse(){
      this.collapse = "id_"+Date.now()+"_collapse"
    },

    MenuDelete(item)
    {
      Api().delete("/menu/"+item._id)
          .then((result) => {
            this.getUserMenu(this.detailsUser._id)
          }).catch((err) => {

      });
    },

    pushMenu()
    {
      if(this.menu_name && this.userSubmenu.length >= 1)
      {
        this.UserFinalMenu.push({
          user : this.detailsUser.id,
          theme:this.menu_name,
          collapse:this.collapse,
          config:this.userSubmenu
        })
        this.menu_name   = null
        this.userSubmenu = []
        this.collapse    = null
      }else{
        this.$toasted.show("Remplissez les champs vides !", this.ToastTabError);
      }
    },

    create_user_menu()
    {
      if(this.UserFinalMenu.length >=1 )
      {
        Api().post("/menu/add", this.UserFinalMenu)
            .then((result) => {
              this.$toasted.show("Opération effectuée !", this.ToastTabSucess);
              console.log(result.data)
              this.UserFinalMenu = []
            }).catch((err) => {
          console.log(err)
        });
      }else{
        this.$toasted.show("Le menu ne doit pas être vide !", this.ToastTabError);
      }
    },

    merge(){
      Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
          .then((result) => {
            this.showuser = false
            if(result.data.result){
              alert('Opération réussie')
            }
          }).catch((err) => {
        console.log('=======err=========')
      });
    },

    detailuser(item) {
      this.getUserMenu(item._id)
      this.detailsUser = item
      this.showuser = true
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList:function(){
      switch (this.$store.state.user.role) {
        case 'Admin':
          Api().get('/user/frontoffice/')
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;


        case 'assureur':
          Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
          .then(response => {
            if (response.data) {
              this.showAlert()
            }
            this.showAlert()
          })
          .catch(err => {

          })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },

    DeletingUser(id) {
      Api().delete('user/delete/' + id)
          .then(resultat => {
            if (resultat.status == 200) {
              this.modalDelete = false
              this.getUserList()
            }
          })
          .catch(err => {})
    },

    getUserMenu(id){
      Api().post('/menu/user/menu', {userid:id})
          .then((result) => {
            this.usermenu = result.data.menus
            console.log(result.data.menus)
          }).catch((err) => {
        console.log(err)
      });
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function(e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function(value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
    this.getDelivery()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getDelivery()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.el-table .warning-row {
    background: #FFB8C8;
  }

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
