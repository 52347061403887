import Api from './Api'

class Avenants {

    async renouvellement(body) {
        return Api().post('/avenant/create/renew', body)
    }
    async annulation(object) {
        return Api().post('/avenant/contrat/annulation', object)
    }
    async immatriculation(object) {
        return Api().post('/avenant/contrat/immatriculation', object)
    }
    async update_client(object) {
        return Api().post('/avenant/contrat/client', object)
    }
}

export default new Avenants();