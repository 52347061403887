<template>
  <div
    class="page-table column scrollable only-y"
    :class="{ flex: !isMobile, overflow: isMobile }"
  >
    <div class="page-header">
      <h1>
        Création de devis <span style="color: #ffa409">Flotte/Auto</span> pour
        ({{ user.prenom || null }} {{ user.nom || null }})
      </h1>
    </div>

    <div style="margin-bottom: 15px">
      <el-row :gutter="15">
        <el-col :span="12" style="margin-top: 15px">
          <el-input
            placeholder="Rechercher vehicules..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>
    </div>
    <div class="toolbar-box flex align-center">
      <div class="box grow">
        <el-button
          v-if="showTable"
          type="primary"
          round
          class="float-right"
          v-loading.fullscreen.lock="modalgenerationloading"
          @click="modalAdd = true"
          >Ajouter un véhicule</el-button
        >
        <el-button
          v-if="showTable"
          type="primary"
          round
          class="float-right"
          v-loading.fullscreen.lock="modalgenerationloading"
          @click="generateDevis()"
          >Génération de devis</el-button
        >
      </div>
    </div>
    <resize-observer @notify="handleResize" />

    <div
      class="table-box card-base card-shadow--medium box grow"
      id="table-wrapper"
      v-loading="!ready"
      v-if="showTable"
    >
      <el-table
        :data="listInPage"
        style="width: 100%"
        :height="height"
        v-if="ready"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          label="Marque"
          min-width="200"
          prop="marque"
        ></el-table-column>
        <el-table-column
          label="Modele"
          min-width="150"
          prop="model"
        ></el-table-column>
        <el-table-column
          label="Immatriculation"
          min-width="130"
          prop="immatriculation"
        ></el-table-column>

        <el-table-column
          label="Nombre de places"
          min-width="150"
          prop="nbplace"
        ></el-table-column>
        <el-table-column
          label="Force"
          min-width="150"
          prop="force"
        ></el-table-column>
        <el-table-column
          label="Carburation"
          min-width="100"
          prop="carburation"
        ></el-table-column>
        <el-table-column
          label="Volumetrie"
          min-width="150"
          prop="volumetrie2_roues"
        ></el-table-column>

        <el-table-column
          label="Date de mise circulation"
          prop="dateCircule"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateCircule) }}
          </template>
        </el-table-column>

        <el-table-column label="Tonnage" min-width="100" prop="tonnage">
          <template slot-scope="scope">
            {{ viewTonnage(scope.row.tonnage) }}
          </template>
        </el-table-column>
        <el-table-column label="Garantie" prop="garanties" min-width="280">
          <template slot-scope="scope">
            <span
              style="margin-letf: -20px"
              v-html="viewGaranties(scope.row.garanties)"
            ></span>
          </template>
        </el-table-column>

        <el-table-column
          label="Catégorie"
          min-width="100"
          prop="categorie"
        ></el-table-column>

        <el-table-column
          label="Carrosserie"
          min-width="150"
          prop="carosserie"
        ></el-table-column>

        <el-table-column
          label="Valeur"
          min-width="150"
          prop="valeur"
        ></el-table-column>

        <el-table-column
          label="Valeur venale"
          min-width="150"
          prop="valeur_venale"
        ></el-table-column>
        <el-table-column
          label="Option utilitaire"
          min-width="150"
          prop="optionUtilitaire"
        ></el-table-column>

        <el-table-column
          label="Option Personne Transportée"
          min-width="220"
          prop="optionPersoTrans"
        ></el-table-column>

        <el-table-column
          label="Option individuel accident"
          min-width="200"
          prop="option_Individuel_Accident"
        ></el-table-column>

        <el-table-column
          fixed="right"
          label="Actions"
          prop="action"
          min-width="140"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="detailVehicules(scope.row)"
              icon="el-icon-view"
              circle
            ></el-button>
            <el-button
              size="mini"
              type="primary"
              @click="toggleVehicules(scope.row)"
              icon="el-icon-edit"
              circle
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              @click="deleteVehicules(scope.row)"
              icon="el-icon-delete"
              circle
              title="Supprimer"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="ready"
        :small="pagination.small"
        :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes"
        :page-size.sync="pagination.size"
        :layout="pagination.layout"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="Suppression vehicule"
      width="50%"
      :visible.sync="modalDelete"
      v-if="modalDelete == true"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous supprimer ce vehicule ?</span>
          </div>
          <div >
            <ul style="list-style: none">
              <li>
                <h3>
                  Marque :
                  <span class="to-right assuraf-orange">{{
                    detailsVehicules.marque
                  }}</span>
                </h3>
              </li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top: 15px">
        <el-button
          type="danger"
          round
          @click="deletingVehicule(detailsVehicules._id)"
          >Oui</el-button
        >
        <el-button type="primary" round @click="modalDelete = false"
          >Non</el-button
        >
      </div>
    </el-dialog>

    <el-dialog width="50%" title="Détails" :visible.sync="showVehicules">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div >
                <ul style="list-style: none">
                  <li>
                    Nom Complet :
                    <span class="to-right assuraf-orange">
                      {{
                        detailsVehicules.userid
                          ? detailsVehicules.userid.prenom
                          : null
                      }}
                      {{
                        detailsVehicules.userid
                          ? detailsVehicules.userid.nom
                          : null
                      }}</span
                    >
                  </li>

                  <li>
                    Telephone :
                    <span class="to-right assuraf-orange">
                      {{
                        detailsVehicules.userid
                          ? detailsVehicules.userid.telephone_port
                          : null
                      }}</span
                    >
                  </li>
                  <li>
                    Email :
                    <span class="to-right assuraf-orange">
                      {{
                        detailsVehicules.userid
                          ? detailsVehicules.userid.email
                          : null
                      }}</span
                    >
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    Tonnage :
                    <span class="to-right assuraf-orange">
                      {{ viewTonnage(detailsVehicules.tonnage) }}</span
                    >
                  </li>
                  <li>
                    Catégorie :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.categorie
                    }}</span>
                  </li>

                  <li>
                    Marque :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.marque
                    }}</span>
                  </li>
                  <li>
                    Model :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.model
                    }}</span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    Volumetrie :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.volumetrie2_roues
                    }}</span>
                  </li>
                  <li>
                    Carosserie :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.carosserie
                    }}</span>
                  </li>
                  <li>
                    Date circulation :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailsVehicules.dateCircule)
                    }}</span>
                  </li>
                  <li>
                    Force :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.force
                    }}</span>
                  </li>
                  <li>
                    <!-- <pre>{{detailsVehicules}}</pre> -->
                    Garanties:
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.garanties
                    }}</span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    Nombre de place :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.nbplace
                    }}</span>
                  </li>
                  <li>
                    Immatriculation :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.immatriculation
                    }}</span>
                  </li>
                  <li>
                    Option utilitaire :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.optionUtilitaire
                    }}</span>
                  </li>
                  <li>
                    Option Personne Transportée :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.optionPersoTrans
                    }}</span>
                  </li>
                  <li>
                    Carburation :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.carburation
                    }}</span>
                  </li>
                  <li>
                    Valeur :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.valeur
                    }}</span>
                  </li>
                  <li>
                    Valeur venale :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.valeur_venale
                    }}</span>
                  </li>
                  <li>
                    Option Individuel Accident :
                    <span class="to-right assuraf-orange">{{
                      detailsVehicules.option_Individuel_Accident
                    }}</span>
                  </li>
                  <li>
                    Date creation :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailsVehicules.created)
                    }}</span>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
      width="65%!important"
      title="Formulaire de modification"
      :visible.sync="editVehicules"
      v-if="editVehicules == true"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row style="margin-top: 5px" :gutter="15">
            <!-- <el-col :span="12">
              <pre>
              {{ detailsVehicules }}
              </pre>
            </el-col> -->

            <el-col :span="12" style="margin-top: 10px">
              <div class="grid-content bg-purple-light">
                <label>Categorie</label>
                <el-select
                  style="width: 100% !important"
                  v-model="detailsVehicules.categorie"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="(items, index) in listeCategorie"
                    :key="index"
                    :label="items"
                    :value="items"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 10px">
              <div>
                <label>Option véhicule utilitaire :</label>
                <el-select
                  style="width: 100% !important"
                  v-model="detailsVehicules.optionUtilitaire"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="(items, index) in listeUtilitaire"
                    :key="index"
                    :label="items"
                    :value="items"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 10px">
              <div class="grid-content bg-purple">
                <label>Marque du véhicule</label>
                <el-select
                  style="width: 100% !important"
                  v-model="detailsVehicules.marque"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="(items, index) in listMarque"
                    :key="index"
                    :label="items.Marque"
                    :value="items.Marque"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 10px">
              <div class="grid-content bg-purple-light">
                <label>Modéle du véhicule</label>
                <el-select
                  style="width: 100% !important"
                  v-model="detailsVehicules.model"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="(items, index) in listeModeles"
                    :key="index"
                    :label="items.Type"
                    :value="items.Type"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 15px">
              <div>
                <label>Option Personne Transportée :</label>
                <el-select
                  style="width: 100% !important"
                  v-model="detailsVehicules.optionPersoTrans"
                  placeholder="Selectionner"
                >
                  <el-option label="" value="">-----------</el-option>
                  <el-option label="option 1" value="option 1"
                    >Option 1</el-option
                  >
                  <el-option label="option 2" value="option 2"
                    >Option 2</el-option
                  >
                  <el-option label="option 3" value="option 3"
                    >Option 3</el-option
                  >
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 15px">
              <label>Force :</label>
              <div>
                <el-select
                  style="width: 100% !important"
                  v-model="detailsVehicules.force"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="items in PuissanceFinscaleItems"
                    :key="items"
                    :label="items"
                    :value="items"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 15px">
              <div class="grid-content bg-purple">
                <label>Date 1ère mise en circulation</label>
                <el-date-picker
                  style="width: 100% !important"
                  v-model="detailsVehicules.dateCircule"
                  type="date"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday"
                >
                </el-date-picker>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 15px">
              <label>Nombre de places</label>
              <div>
                <el-input
                  type="number"
                  placeholder="Nombre de places"
                  v-model="detailsVehicules.nbplace"
                >
                  <template slot="append">place(s)</template>
                </el-input>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 15px">
              <div class="grid-content bg-purple">
                <label>Valeur actuelle</label>
                <el-input
                  placeholder="Valeur actuelle"
                  v-model="detailsVehicules.valeur_venale"
                >
                  <template slot="append">CFA</template>
                </el-input>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 15px">
              <div class="grid-content bg-purple">
                <label>Valeur neuf</label>
                <el-input
                  placeholder="Valeur neuf"
                  v-model="detailsVehicules.valeur"
                >
                  <template slot="append">CFA</template>
                </el-input>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 15px">
              <label>Carrosserie :</label>
              <div>
                <el-input
                  placeholder="carrosserie"
                  v-model="detailsVehicules.carosserie"
                ></el-input>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 15px">
              <label>Immatriculation :</label>
              <div>
                <el-input
                  v-mask="mask"
                  placeholder="Immatriculation"
                  v-model="detailsVehicules.immatriculation"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 15px">
              <label>Carburation :</label>
              <div>
                <el-input
                  placeholder="carburation"
                  v-model="detailsVehicules.carburation"
                ></el-input>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 15px">
              <label>Option Individuel Accident :</label>
              <div>
                <el-select
                  style="width: 100% !important"
                  v-model="detailsVehicules.option_Individuel_Accident"
                  placeholder="Selectionner"
                >
                  <el-option label="" value="">-----------</el-option>
                  <el-option label="option 1" value="option 1"
                    >Option 1</el-option
                  >
                  <el-option label="option 2" value="option 2"
                    >Option 2</el-option
                  >
                  <el-option label="option 3" value="option 3"
                    >Option 3</el-option
                  >
                </el-select>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top: 15px">
              <label>Garanties :</label>
              <div>
                <el-select
                  style="width: 100% !important"
                  v-model="detailsVehicules.garanties"
                  multiple
                  collapse-tags
                  collapse-tags-tooltip
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="items in listeGarantie"
                    :key="items"
                    :label="items"
                    :value="items"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="updateVehicules()"
            >Modifier</el-button
          >
          <el-button type="danger" round @click="editVehicules = false"
            >Annuler</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="Ajout vehicule"
      width="60%"
      :visible.sync="modalAdd"
      v-if="modalAdd == true"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row style="margin-top: 5px" :gutter="15">
            <el-col :span="12" style="margin-top: 10px">
              <div class="grid-content bg-purple">
                <label>Marque du véhicule</label>
                <el-select
                  style="width: 100% !important"
                  v-model="vehicule.marque"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="(items, index) in listMarque"
                    :key="index"
                    :label="items.Marque"
                    :value="items.Marque"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 10px">
              <div class="grid-content bg-purple-light">
                <label>Modéle du véhicule</label>
                <el-select
                  style="width: 100% !important"
                  v-model="vehicule.model"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="(items, index) in listeModeles"
                    :key="index"
                    :label="items.Type"
                    :value="items.Type"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 10px">
              <label>Immatriculation :</label>
              <div>
                <el-input
                  v-mask="mask"
                  placeholder="Immatriculation"
                  v-model="vehicule.immatriculation"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 10px">
              <label>Force :</label>
              <div>
                <el-select
                  style="width: 100% !important"
                  v-model="vehicule.force"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="items in PuissanceFinscaleItems"
                    :key="items"
                    :label="items"
                    :value="items"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 10px">
              <div class="grid-content bg-purple-light">
                <label>Categorie</label>
                <el-select
                  style="width: 100% !important"
                  v-model="vehicule.categorie"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="(items, index) in listeCategorie"
                    :key="index"
                    :label="items"
                    :value="items"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 10px">
              <label>Tonnage :</label>
              <div>
                <el-select
                  style="width: 100% !important"
                  v-model="vehicule.tonnage"
                  placeholder="Selectionner"
                >
                  <el-option label="3,5 Tonnes" value="3,5 Tonnes"
                    >3,5 Tonnes</el-option
                  >
                  <el-option
                    label="Plus de 3,5 Tonnes"
                    value="Plus de 3,5 Tonnes"
                    >Plus de 3,5 Tonnes</el-option
                  >
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 10px">
              <label>Nombre de places</label>
              <div>
                <el-input
                  type="number"
                  placeholder="Nombre de places"
                  v-model="vehicule.nbplace"
                >
                  <template slot="append">place(s)</template>
                </el-input>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 10px">
              <div class="grid-content bg-purple">
                <label>Date 1ère mise en circulation</label>
                <el-date-picker
                  style="width: 100% !important"
                  v-model="vehicule.dateCircule"
                  type="date"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday"
                >
                </el-date-picker>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 10px">
              <div class="grid-content bg-purple">
                <label>Valeur actuelle</label>
                <el-input
                  placeholder="Valeur actuelle"
                  v-model="vehicule.valeur_venale"
                >
                  <template slot="append">CFA</template>
                </el-input>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 10px">
              <div class="grid-content bg-purple">
                <label>Valeur neuf</label>
                <el-input placeholder="Valeur neuf" v-model="vehicule.valeur">
                  <template slot="append">CFA</template>
                </el-input>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 10px">
              <label>Carrosserie :</label>
              <div>
                <el-select
                  style="width: 100% !important"
                  v-model="vehicule.carosserie"
                  placeholder="Selectionner"
                >
                  <el-option
                    label="carosserie tourisme"
                    value="carosserie tourisme"
                    >Carosserie tourisme</el-option
                  >
                  <el-option
                    label="autres carosseries"
                    value="autres carosseries"
                    >autres carosseries</el-option
                  >
                </el-select>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 10px">
              <label>Carburation :</label>
              <div>
                <el-input
                  placeholder="carburation"
                  v-model="vehicule.carburation"
                ></el-input>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 10px">
              <div>
                <label>Option véhicule utilitaire :</label>
                <el-select
                  style="width: 100% !important"
                  v-model="vehicule.optionUtilitaire"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="(items, index) in listeUtilitaire"
                    :key="index"
                    :label="items"
                    :value="items"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 15px">
              <div>
                <label>Option Personne Transportée :</label>
                <el-select
                  style="width: 100% !important"
                  v-model="vehicule.optionPersoTrans"
                  placeholder="Selectionner"
                >
                  <el-option label="option 1" value="option 1"
                    >Option 1</el-option
                  >
                  <el-option label="option 2" value="option 2"
                    >Option 2</el-option
                  >
                  <el-option label="option 3" value="option 3"
                    >Option 3</el-option
                  >
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" style="margin-top: 10px">
              <label>Option Individuel Accident :</label>
              <div>
                <el-select
                  style="width: 100% !important"
                  v-model="vehicule.option_Individuel_Accident"
                  placeholder="Selectionner"
                >
                  <el-option label="option 1" value="option 1"
                    >Option 1</el-option
                  >
                  <el-option label="option 2" value="option 2"
                    >Option 2</el-option
                  >
                  <el-option label="option 3" value="option 3"
                    >Option 3</el-option
                  >
                </el-select>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 10px">
              <label>Capital avance sur recours</label>
              <div>
                <el-select
                  style="width: 100% !important"
                  v-model="vehicule.capitalAvRec"
                  placeholder="Selectionner"
                >
                  <el-option label="500000" value="500000"
                    >500 000 CFA</el-option
                  >
                  <el-option label="1000000" value="1000000"
                    >1 500 000 CFA</el-option
                  >
                  <el-option label="+1000000" value="+1000000"
                    >Plus de 1 million CFA</el-option
                  >
                </el-select>
              </div>
            </el-col>

            <el-col :span="24" style="margin-top: 10px">
              <label>Garanties :</label>
              <div>
                <el-select
                  style="width: 100% !important"
                  v-model="vehicule.garanties"
                  multiple
                  collapse-tags
                  collapse-tags-tooltip
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="items in listeGarantie"
                    :key="items"
                    :label="items"
                    :value="items"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="valideVehicules()"
            >Valider</el-button
          >
          <el-button type="danger" round @click="modalAdd = false"
            >Annuler</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from "../../../services/Api";
import store from "../../../store";
import dayjs from "dayjs";
import MarqueVehicule from "../../../common/marqueauto";
import Marque2roues from "../../../common/2roues";
import configAuto from "../../../common/configAuto";
import _ from "underscore";
import { mask } from "vue-the-mask";
export default {
  name: "DevisFlotte",
  directives: {
    mask,
  },
  data: () => ({
    mask: "XX-XXXX-XX",
    modalgenerationloading: false,
    vehicule: {
      userid: null,
      categorie: "",
      optionUtilitaire: "",
      marque: "",
      model: "",
      optionPersoTrans: "",
      force: "",
      dateCircule: "",
      valeur_venale: "",
      valeur: "",
      carosserie: "",
      immatriculation: "",
      carburation: "",
      garanties: [],
      option_Individuel_Accident: "",
      tonnage: "",
      nbplace: null,
      capitalAvRec: null,
      reference: "",
    },
    listMarque: [],
    listeCategorie: [
      "Catégorie 1",
      "Catégorie 2",
      "Catégorie 3",
      "Catégorie 5",
    ],
    listeUtilitaire: ["Matériel personnel", "Materiel tierce"],
    listeGarantie: [
      "RESPONSABILITE CIVILE",
      "INCENDIE",
      "AVANCE SUR RECOURS",
      "DEFENSE ET RECOURS",
      "VOL",
      "BRIS DE GLACES",
      "PERSONNES TRANSPORTEES",
      "TIERCE COMPLETE",
      "TIERCE COLLISION",
    ],
    dateBeforeToday: {
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
    },
    dateAfterToday: {
      disabledDate(time) {
        return time.getTime() < dayjs(new Date()).add(-1, "days").toDate(); //dayjs.datediff(Date.now(), );
      },
    },
    user: {},
    modalDelete: false,
    modalAdd: false,
    showVehicules: false,
    editVehicules: false,
    detailsVehicules: {},
    tableData: [],
    showTable: false,
    filter: null,
    currentStatus: null,
    ready: false,
    height: "auto",
    width: 0,
    search: "",
    loading: false,
    clientsearch: "",
    PuissanceFinscaleItems: [
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      "24+",
    ],
    pagination: {
      page: 1,
      size: 100,
      sizes: [10, 15, 20, 30, 50, 100],
      layout: "total, ->, prev, pager, next, jumper, sizes",
      small: false,
    },
  }),
  computed: {
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.pagination.page = val;
      },
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage;
      let to = from + this.itemPerPage * 1;
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to);
    },
    total() {
      return this.listFiltered.length;
    },
    listFiltered() {
      return this.tableData.filter((obj) => {
        let ctrl = false;
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true;
        }
        return ctrl;
      });
    },
    itemPerPage() {
      return this.pagination.size;
    },
  },
  watch: {
    itemPerPage(val) {
      this.ready = false;
      this.currentPage = 1;

      setTimeout(() => {
        this.ready = true;
      }, 500);
    },
    search(val) {
      this.currentPage = 1;
    },
    marque(val) {
      this.onChangeSelectedMarque(val);
      this.onChangeSelectedMarque2roues(val);
    },
  },
  methods: {
    generateDevis() {
      this.modalgenerationloading = true;
      setTimeout(() => {
        this.$router.push("/flotte/generation/" + this.user.reference);
      }, 1000);
    },

    updateVehicules() {
      let item = this.detailsVehicules;
      Api()
        .patch("/tempflotte/vehicule/update/" + item._id, item)
        .then((resultat) => {
          console.log("=================================================");
          console.log("==> ", resultat.data);
          console.log("=================================================");
          this.$message.success(
            "Modification effectuée avec succés !",
            this.AlertMessage
          );
          this.getFlotte();
          this.editVehicules = false;
        })
        .catch((erreur) => {
          this.$message.error(
            "Vérifiez si les champs sont bien remplie !",
            this.AlertMessage
          );
          console.log(erreur);
        });
    },

    valideVehicules() {
      if (
        this.vehicule.immatriculation == "" ||
        this.vehicule.garanties.length == 0 ||
        this.vehicule.categorie == ""
      ) {
        this.$message.error(
          "Les champs suivants sont obligatoires : immatriculation, garanties, categorie !",
          this.AlertMessage
        );
        return;
      }
      this.modalgenerationloading = true;
      this.vehicule.userid = this.user._id;
      this.vehicule.reference = this.user.reference;

      Api()
        .post("/tempflotte/vehicule/add", this.vehicule)
        .then((resultat) => {
          this.modalgenerationloading = false;
          this.$message.success(
            "Vehicule ajoutée avec succés !",
            this.AlertMessage
          );
          this.getFlotte();
          this.modalAdd = false;
          this.vehicule = {
            categorie: "",
            optionUtilitaire: "",
            marque: "",
            model: "",
            optionPersoTrans: "",
            force: "",
            dateCircule: "",
            valeur_venale: "",
            valeur: "",
            carosserie: "",
            immatriculation: "",
            carburation: "",
            garanties: [],
            option_Individuel_Accident: "",
            tonnage: "",
            nbplace: null,
            capitalAvRec: null,
            reference: "",
          };
        })
        .catch((erreur) => {
          this.modalgenerationloading = false;

          this.$message.error(
            "Vérifiez si les champs sont bien remplie !",
            this.AlertMessage
          );
          console.log(erreur);
        });
    },

    detailVehicules(item) {
      this.detailsVehicules = item;
      this.showVehicules = true;
    },
    toggleVehicules(item) {
      this.detailsVehicules = item;
      this.editVehicules = true;
    },

    deleteVehicules(item) {
      this.modalDelete = true;
      this.detailsVehicules = item;
    },
    deletingVehicule(id) {
      Api()
        .delete("/tempflotte/vehicule/delete/" + id)
        .then((resultat) => {
          if (resultat.status == 200) {
            this.$message.success("vehicule supprimé !", this.AlertMessage);
            this.modalDelete = false;
            this.getFlotte();
          }
        })
        .catch((err) => {});
    },
    viewGaranties(garanties) {
      if (garanties.length !== 0) {
        return `<ul> ${this.li(garanties)}</ul> `;
      }
      return "";
    },
    li(list) {
      let view = "";
      list.forEach((element) => {
        view = view + ` <li> ${element}</li>`;
      });
      return view;
    },
    viewClient(user) {
      if (user) return user.nom + " - " + user.prenom;
    },
    viewTonnage(tonnage) {
      if (typeof tonnage == "string") return this.formatText(tonnage);
      return tonnage;
    },
    formatText(text) {
      if (typeof text == "string") return text.trim().replaceAll('"', "");
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format("DD-MM-YYYY");
      }
    },

    handleSelectionChange(val) {
      this.itemsChecked = val;
    },
    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper");
      if (tableWrapper) this.width = tableWrapper.clientWidth;

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44;
      }

      if (this.width < 480) {
        this.pagination.small = true;
        this.pagination.layout = "prev, pager, next";
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false;
        this.pagination.layout = "prev, pager, next, ->, sizes";
      } else {
        this.pagination.small = false;
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes";
      }

      this.ready = true;
    },
    handleResize: _.throttle(function (e) {
      this.ready = false;
      this.width = 0;
      setTimeout(this.calcDims, 500);
    }, 500),

    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000,
      });
    },

    save() {},

    getFlotte() {
      if (this.$route.params.id) {
        Api()
          .get("/tempflotte/liste/vehicule/" + this.$route.params.id)
          .then((result) => {
            this.showTable = true;
            this.tableData = result.data.flottes;
            this.user = this.tableData[0].userid;
            this.user.reference = this.tableData[0].reference;
          })
          .catch((err) => {
            this.$message.error(
              "Formatage du fichier non reconnu !",
              this.AlertMessage
            );
          });
      } else {
        this.$router.push({ name: "uploadexcel" });
      }
    },
  },
  created() {},
  mounted() {
    this.listMarque = _.uniq(MarqueVehicule, _.property("Marque"));
    this.listeModeles = _.uniq(MarqueVehicule, _.property("Type"));
    this.listeMarque2roues = _.uniq(Marque2roues, _.property("Marque"));
    this.listeModeles2roues = _.uniq(Marque2roues, _.property("Type"));

    this.calcDims();
    this.getFlotte();
  },
};
</script>
