exports.customPassword=(nom,prenom)=>{
    var length = 13;
    var string = "abcdefghijklmnopqrstuvwxyz"; //to upper 
    var numeric = '0123456789';
    var punctuation = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
    var password = "";
    var character = "";
    while( password.length<length ) {
        let entity1 = Math.ceil(string.length * Math.random()*Math.random())
        let entity2 = Math.ceil(numeric.length * Math.random()*Math.random())
        let entity3 = Math.ceil(punctuation.length * Math.random()*Math.random())
        let hold = string.charAt( entity1 )
        hold = (password.length%2==0)?(hold.toUpperCase()):(hold)
        character += hold
        character += numeric.charAt( entity2 )
        character += punctuation.charAt( entity3 )
        password = character
    }
    password=password.split('').sort(function(){return 0.5-Math.random()}).join('');
    let psss = password.substr(0,12)+build_end() 
    return psss
}

function build_end(){
    var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 3;
    var password = "";
    for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber +1);
       }
    return password;
}