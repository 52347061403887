<template>
	<div class="page-profile-edit">
		<el-form ref="form" label-width="120px" :label-position="labelPosition">
			<el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item>
						<label>Nouveau mot de passe</label>
						<el-input v-model="password" type="password"/>
					</el-form-item>
				</el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item>
						<label>Confirmez le mot de passe</label>
						<el-input v-model="password_confirmation" type="password"/>
					</el-form-item>
				</el-col>
			</el-col>
			<el-col class="col-p">
				<el-form-item>
					<el-button type="primary" @click="UpdateUserPassword()">Modifier</el-button>
					<el-button @click="toHome">Annuler</el-button>
				</el-form-item>
			</el-col>
		</el-form>
	</div>
</template>

<script>
import Api from '../../services/Api'
import store from '../../store'

export default {
	name: 'ProfileEdit',
	data() {
		return {
			dismissSecs: 3,
            deleteConfirmation: false,
            UpdateMyPwd:false,
            modalDelete: false,
            dismissCountDown: 0,
            modalShow: false,
            listuser: null,
            Myprofile: false,
            username: null,
            nom: null,
            seenuser: true,
            currentuser: null,
            LoggedUser: null,
			password:null, 
            password_confirmation:null,



			form: {
			



				// username: 'aShenton',
				// email: 'ashenton@mail.com',
				// firstName: 'Aurora',
				// lastName: 'Shenton',
				// birthday: '1991-02-13T23:00:00.000Z',
				// phone: '',
				// website: '',
				// hobbies: [],
				// skills: ['JavaScript', 'HTML', 'CSS', 'Vue.js'],
				// gender: 'Female',
				// address: '',
				// city: '',
				// country: '',
				// postalCode: '',
				// aboutMe: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus posuere libero, nec convallis arcu ullamcorper a. Vestibulum diam neque, egestas scelerisque arcu a, fermentum ornare mauris. Ut venenatis vulputate maximus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur scelerisque quis turpis ac tempus. Quisque dolor dolor, fermentum nec magna eget, fringilla auctor lacus. Aenean sagittis est ac ligula pharetra, quis imperdiet ante dignissim. Ut vehicula nec nisl a pretium. Quisque faucibus auctor viverra. Sed ultricies convallis magna. In blandit eros id efficitur vulputate. Duis efficitur sollicitudin dui non vehicula. Nullam ut eros fermentum, dapibus metus non, accumsan neque. Mauris sed pellentesque felis. Suspendisse viverra risus sit amet congue consectetur.'
			},
			hobbies: [
				{
					value: 'Model building',
					label: 'Model building'
				}, {
					value: 'Drawing',
					label: 'Drawing'
				}, {
					value: 'Snowboarding',
					label: 'Snowboarding'
				}
			],
			skills: [
				{
					value: 'HTML',
					label: 'HTML'
				}, {
					value: 'CSS',
					label: 'CSS'
				}, {
					value: 'JavaScript',
					label: 'JavaScript'
				}
			],
			labelPosition: 'right'
		}
	},
	methods: {
		  AlertMessage() {
			this.$message({
				showClose: false,
				duration : 5000
			})
		  },

		toHome() {
      		this.$router.go(this.$router.push('/dashboard'))
		},
		  //Update current user password
        UpdateUserPassword(){
            if(this.password && this.password_confirmation)
            {
                if(this.password==this.password_confirmation)
                {
                    const data={
                        id:store.state.user._id,
                        password:this.password,
                        confirmation:this.password_confirmation
                    }
                    Api().post('/user/express/update', data)
                    .then((result) => {
                        if(result.data.nModified==1){
                            this.Logout()
                        }else{
								this.$message.error('Opération échouée !', this.AlertMessage)
                        }
                    }).catch((err) => {
                        
                    });

                }else{
					this.$message.error('Vos deux mots de passe ne sont pas identiques !', this.AlertMessage)
                }
            }else{
				this.$message.error('Remplissez les champs vides !', this.AlertMessage)
            }
        },
        
        Logout() {
            Api().get('user/logout/' + store.state.user._id)
                .then(response => {
                    if (response.data.info == 'ok') {
                        store.state.user = null
                        store.state.devis = null
                        store.state.devispayement = null
                        store.state.Newcontrat = null
                        store.state.DevisReopen = null
                        store.state.Client = null
                        store.state.orderid = null
                        store.state.modePaiement = null
                        store.state.isUserLoggedIn = false
                        store.state.listeBeneficiaire = null
                        this.$router.go(this.$router.push({
                            name: 'login'
                        }))
                      
                    } else {
                        store.state.user = null
                        store.state.devis = null
                        store.state.devispayement = null
                        store.state.Newcontrat = null
                        store.state.DevisReopen = null
                        store.state.Client = null
                        store.state.orderid = null
                        store.state.modePaiement = null
                        store.state.isUserLoggedIn = false
                        store.state.listeBeneficiaire = null
                        this.$router.go(this.$router.push({
                            name: 'login'
                        }))
                    
                    }
                })
        },

        UpdateUser() {

            if (this.username.nom && this.username.prenom && this.username.email) {
                if (this.username.statut_marital && this.username.telephone_port && this.username.telephone_bur && this.username.piece_identite) {
                    if (this.username.numero_piece && this.username.profession) {
                        Api().post('user/' + this.username._id, this.username)
                            .then(response => {
                                if (response.data) {
                                    this.$message.success('Infos modifié avec succés !', this.AlertMessage)
									setTimeout(() => (
										this.toHome(),
										window.open(url)), 2000
									);
                                }
                            })
                    } else {
						this.$message.error('Touts les champs sont obligatoires !', this.AlertMessage)
                    }
                } else {
					this.$message.error('Touts les champs sont obligatoires !', this.AlertMessage)
                }
            } else {
				this.$message.error('Touts les champs sont obligatoires !', this.AlertMessage)
            }

        },
		resizeLabelPosition() {
			if(window.innerWidth <= 768) {
				this.labelPosition = 'top'	
			}
		}
	},
	mounted() {
		this.username = this.$store.state.user

		Api().get('/user/current')
            .then(resultat => {
                this.currentuser = resultat.data;
            })

		this.resizeLabelPosition();
		window.addEventListener('resize', this.resizeLabelPosition);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeLabelPosition);
	}
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.page-profile-edit {
	.label-switch-box {
		display: block;
		clear: both;
		width: 100%;
		text-align: right;
		margin-bottom: 20px;
	}
	.col-p {
		padding: 0 10px;
		box-sizing: border-box;
	}
	.select-wide {
		width: 100%;
	}
}
</style>
