<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Création de devis <span style="color:#ffa409">Nsia Etudes</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="__resizeHanlder"/>

    <div v-if="hidesearchClient && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div style="margin-top: 15px;">
        <h3>Rechercher client</h3>
        <el-row :gutter="15" justify="center">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
              <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top:15px">
          <el-col :span="10">
            <el-input v-if="NumberInput" type="text" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
              <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
            </el-input>
            <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
              <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
        </el-row>
      </div>

       <div style="margin-top: 15px;" v-if="clientOk">
            <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
        </div>

        <div style="margin-top: 15px;" v-if="clientnonOk">
            <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
        </div>
    </div>

    <div v-if="hidesearchClient && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
              <el-row :gutter="15" justify="center">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="15" style="margin-top:15px">
                <el-col :span="24">
                  <el-input v-if="NumberInput" type="number" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                    <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                  <el-col :span="24" style="margin-top:15">
                   <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                    <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                  </el-col>
              </el-row>
            </div>

             <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
            </div>
        </div>

    <div v-if="e1 == 1 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div style="margin-top:15px">
        <h3>Simulation :</h3>
        <el-row :gutter="15" type="flex" class="row-bg" justify="center">
          <el-col :span="24">
            <div>
              <el-form>
                <el-form-item>
                  <el-radio-group v-model="choix_prime" @change="switchCapital()">
                    <el-radio label="bourse_annuelle">Bourse annuelle</el-radio>
                    <el-radio label="prime_mensuelle">Prime mensuelle</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>

      <div v-if="Capital">
        <h3>Bourse annuelle :</h3>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date naissance assuré</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="dateNaiss"
                  type="date"
                  format="yyyy/MM/dd"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Date naissance bénéficiaire</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="BenefiFields.dateNaissance"
                  format="yyyy/MM/dd"
                  type="date"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Bourse souhaitée</label>
              <el-input type="number" min="1" placeholder="Saisir le capital" v-model="choixCapital.Capital" @keyup="CalculCapitalRente">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Durée cotisation (année)</label>
              <el-input type="number" min="1" placeholder="Saisir la durée" v-model="duree" @change="CheckFieldChange()">
              </el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Durée de bourse</label>
              <el-select style="width:100%" v-model="Duree_bourse" placeholder="Selectionner une durée">
                <el-option value="null" selected disabled="">Nombre d'année</el-option>
                <el-option value="3">3 ans</el-option>
                <el-option value="4">4 ans</el-option>
                <el-option value="5">5 ans</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>

        <div class="text-center" v-if="monthly_payment != null">
          <h4>Prime mensuelle : <span style="color:#ffa409">{{ monthly_payment.toLocaleString()}} FCFA</span></h4>
        </div>

        <div class="text-center" v-if="monthly_payment != null">
          <p>Une prime annuelle de <span style="color:#ffa409">{{ choixCapital.Capital.toLocaleString() }} CFA</span> sera versée en cas de sinstre (décès/invalidité) jusqu'a <span style="color:#ffa409">1 ans</span> avant terme du contrat
            <br>En plus d'une bourse annuelle garantie de <span style="color:#ffa409">{{ choixCapital.Capital.toLocaleString()}} CFA</span> pendant <span style="color:#ffa409">{{ Duree_bourse }} ans </span> au terme du contrat.</p>
        </div>
      </div>

      <div v-if="Mensualite">
        <h3>Prime mensuelle :</h3>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple" v-if="categorie !== '2_roues'">
              <label>Date naissance assuré</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="dateNaiss"
                  type="date"
                  format="yyyy/MM/dd"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Date naissance bénéficiaire</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="BenefiFields.dateNaissance"
                  format="yyyy/MM/dd"
                  type="date"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Prime souhaitée</label>
              <el-input type="number" min="1" placeholder="Saisir la mensualité" v-model="choixCapital.Mensuel" @keyup="CheckFieldChange">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="12">
            <div class="grid-content bg-purple" >
              <label>Durée cotisation (année)</label>
              <el-input type="number" min="1" placeholder="Saisir la durée" v-model="duree" @change="CheckFieldChange()">
              </el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Durée de bourse</label>
              <el-select style="width:100%" v-model="Duree_bourse" @change="CheckFieldChange" placeholder="Selectionner une durée">
                <el-option value="null" selected disabled="">Nombre d'année</el-option>
                <el-option value="3">3 ans</el-option>
                <el-option value="4">4 ans</el-option>
                <el-option value="5">5 ans</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>

        <div class="text-center" v-if="Calculated_response != null">
          <h4>Bourse annuelle : <span style="color:#ffa409">{{ Calculated_response.toLocaleString()}} FCFA</span></h4>
        </div>

        <div class="text-center" v-if="Calculated_response != null">
          <p>Une bourse annuelle de <span v-if="Calculated_response != null" style="color:#ffa409">{{ Calculated_response.toLocaleString() }} CFA</span> sera versée en cas de sinstre (décès/invalidité) jusqu'a <span style="color:#ffa409">1 ans</span> avant terme du contrat
            <br>En plus d'une bourse annuelle garantie de <span v-if="Calculated_response != null" style="color:#ffa409">{{ Calculated_response.toLocaleString()}} CFA</span> pendant <span style="color:#ffa409">{{ Duree_bourse }} ans </span> au terme du contrat.</p>
        </div>
      </div>

      <div style="margin-top:15px">
        <el-button v-if="Simule" @click="fieldsControl" type="primary" round>Simuler</el-button>
        <el-button v-if="SkipSimule" @click="DevisNextStep" type="primary" round>Suivant</el-button>
      </div>
    </div>

     <div v-if="e1 == 1 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div style="margin-top:15px">
        <h3>Simulation :</h3>
        <el-row :gutter="15" type="flex" class="row-bg" justify="center">
          <el-col :span="24">
            <div>
              <el-form>
                <el-form-item>
                  <el-radio-group v-model="choix_prime" @change="switchCapital()">
                    <el-radio label="bourse_annuelle">Bourse annuelle</el-radio>
                    <el-radio label="prime_mensuelle">Prime mensuelle</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>

      <div v-if="Capital">
        <h3>Bourse annuelle :</h3>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Date naissance assuré</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="dateNaiss"
                  type="date"
                  format="yyyy/MM/dd"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Date naissance bénéficiaire</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="BenefiFields.dateNaissance"
                  format="yyyy/MM/dd"
                  type="date"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Bourse souhaitée</label>
              <el-input type="number" min="1" placeholder="Saisir le capital" v-model="choixCapital.Capital" @keyup="CalculCapitalRente">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Durée cotisation (année)</label>
              <el-input type="number" min="1" placeholder="Saisir la durée" v-model="duree" @change="CheckFieldChange()">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Durée de bourse</label>
              <el-select style="width:100%" v-model="Duree_bourse" placeholder="Selectionner une durée">
                <el-option value="null" selected disabled="">Nombre d'année</el-option>
                <el-option value="3">3 ans</el-option>
                <el-option value="4">4 ans</el-option>
                <el-option value="5">5 ans</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>

        <div class="text-center" v-if="monthly_payment != null">
          <h4>Prime mensuelle : <span style="color:#ffa409">{{ monthly_payment.toLocaleString()}} FCFA</span></h4>
        </div>

        <div class="text-center" v-if="monthly_payment != null">
          <p>Une prime annuelle de <span style="color:#ffa409">{{ choixCapital.Capital.toLocaleString() }} CFA</span> sera versée en cas de sinstre (décès/invalidité) jusqu'a <span style="color:#ffa409">1 ans</span> avant terme du contrat
            <br>En plus d'une bourse annuelle garantie de <span style="color:#ffa409">{{ choixCapital.Capital.toLocaleString()}} CFA</span> pendant <span style="color:#ffa409">{{ Duree_bourse }} ans </span> au terme du contrat.</p>
        </div>
      </div>

      <div v-if="Mensualite">
        <h3>Prime mensuelle :</h3>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple" v-if="categorie !== '2_roues'">
              <label>Date naissance assuré</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="dateNaiss"
                  type="date"
                  format="yyyy/MM/dd"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Date naissance bénéficiaire</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="BenefiFields.dateNaissance"
                  format="yyyy/MM/dd"
                  type="date"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Prime souhaitée</label>
              <el-input type="number" min="1" placeholder="Saisir la mensualité" v-model="choixCapital.Mensuel" @keyup="CheckFieldChange">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple" >
              <label>Durée cotisation (année)</label>
              <el-input type="number" min="1" placeholder="Saisir la durée" v-model="duree" @change="CheckFieldChange()">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Durée de bourse</label>
              <el-select style="width:100%" v-model="Duree_bourse" @change="CheckFieldChange" placeholder="Selectionner une durée">
                <el-option value="null" selected disabled="">Nombre d'année</el-option>
                <el-option value="3">3 ans</el-option>
                <el-option value="4">4 ans</el-option>
                <el-option value="5">5 ans</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>

        <div class="text-center" v-if="Calculated_response != null">
          <h4>Bourse annuelle : <span style="color:#ffa409">{{ Calculated_response.toLocaleString()}} FCFA</span></h4>
        </div>

        <div class="text-center" v-if="Calculated_response != null">
          <p>Une bourse annuelle de <span v-if="Calculated_response != null" style="color:#ffa409">{{ Calculated_response.toLocaleString() }} CFA</span> sera versée en cas de sinstre (décès/invalidité) jusqu'a <span style="color:#ffa409">1 ans</span> avant terme du contrat
            <br>En plus d'une bourse annuelle garantie de <span v-if="Calculated_response != null" style="color:#ffa409">{{ Calculated_response.toLocaleString()}} CFA</span> pendant <span style="color:#ffa409">{{ Duree_bourse }} ans </span> au terme du contrat.</p>
        </div>
      </div>

      <div style="margin-top:15px">
        <el-button v-if="Simule" @click="fieldsControl" type="primary" round>Simuler</el-button>
        <el-button v-if="SkipSimule" @click="DevisNextStep" type="primary" round>Suivant</el-button>
      </div>
    </div>

    <div v-if="e1 == 2 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>
        <h3>Contractant :</h3>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-form>
                <el-form-item>
                  <label>Genre : </label>
                  <el-radio-group v-model="ContractantFields.Genre">
                    <el-radio label="homme">Monsieur</el-radio>
                    <el-radio label="femme">Madame</el-radio>
                    <el-radio label="entreprise">Entreprise</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="margin-top: -30px;" :span="24">
            <div class="grid-content bg-purple">
              <el-form>
                <el-form-item>
                  <label>Situation : </label>
                  <el-radio-group v-model="ContractantFields.Situation">
                    <el-radio label="marié">Marié(e)</el-radio>
                    <el-radio label="célibataire">Célibataire</el-radio>
                    <el-radio label="veuf">Veuf(ve)</el-radio>
                    <el-radio label="divorce">Divorcé(e)</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col style="margin-top: -30px;" :span="24">
            <div class="grid-content bg-purple">
              <el-form>
                <el-form-item>
                  <label>Tranche Revenu annuel : </label>
                  <el-radio-group v-model="ContractantFields.revenu_annuel">
                    <el-radio label="moins de 10">Moins de <span>10</span> millions</el-radio>
                    <el-radio label="entre 10 et 30">Entre <span>10</span> et <span>30</span> millions</el-radio>
                    <el-radio label="plus de 30">Plus de <span>30</span> millions</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="margin-top: -30px;" :span="24">
            <div class="grid-content bg-purple">
              <el-form>
                <el-form-item>
                  <label>Tranche de patrimoine : </label>
                  <el-radio-group v-model="ContractantFields.patrimoine">
                    <el-radio label="moins de 50">Moins de <span>50</span> millions</el-radio>
                    <el-radio label="entre 50 et 150">Entre <span>50</span> et <span>150</span> millions</el-radio>
                    <el-radio label="plus de 150">Plus de <span>150</span> millions</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="ContractantFields.Nom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input placeholder="Votre prenom" v-model="ContractantFields.Prenom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Date de naissance</label>
              <el-date-picker
                  @change="yearsDiffContractant()"
                  style="width: 100%!important;"
                  v-model="ContractantFields.dateNaissance"
                  type="date"
                  format="yyyy/MM/dd"
                  placeholder="Choississez une date"
                  :picker-options="dateAfterToday">
              </el-date-picker>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Lieu de naissance</label>
              <el-input placeholder="Votre lieu de naissance" v-model="ContractantFields.LieuNaissance">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Profession</label>
              <el-input placeholder="Votre profession" v-model="ContractantFields.Profession">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Adresse</label>
              <el-input type="text" placeholder="Votre adresse" v-model="ContractantFields.Adresse">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone</label>
              <el-input type="number" placeholder="Votre numéro de téléphone" v-model="ContractantFields.Telephone">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Email</label>
              <el-input placeholder="Adresse e-mail" v-model="ContractantFields.Email">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>CNI</label>
              <el-input type="number" min="1" placeholder="Votre numéro de CNI" v-model="ContractantFields.Cni">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nationalité</label>
              <el-input placeholder="Votre nationalité" v-model="ContractantFields.Nationalite">
              </el-input>
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top:15px">
        <el-button style="float:left" @click="e1 =1" round>Retour</el-button>
        <el-button @click="ContractControl()" type="primary" round>Suivant</el-button>
      </div>
    </div>

     <div v-if="e1 == 2 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>
        <h3>Contractant :</h3>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-form>
                <el-form-item>
                  <label>Genre : </label>
                  <el-radio-group v-model="ContractantFields.Genre">
                    <el-radio label="homme">Monsieur</el-radio>
                    <el-radio label="femme">Madame</el-radio>
                    <el-radio label="entreprise">Entreprise</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="margin-top: -30px;" :span="24">
            <div class="grid-content bg-purple">
              <el-form>
                <el-form-item>
                  <label>Situation : </label>
                  <el-radio-group v-model="ContractantFields.Situation">
                    <el-radio label="marié">Marié(e)</el-radio>
                    <el-radio label="célibataire">Célibataire</el-radio>
                    <el-radio label="veuf">Veuf(ve)</el-radio>
                    <el-radio label="divorce">Divorcé(e)</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col style="margin-top: -30px;" :span="24">
            <div class="grid-content bg-purple">
              <el-form>
                <el-form-item>
                  <label>Tranche Revenu annuel : </label>
                  <el-radio-group v-model="ContractantFields.revenu_annuel">
                    <el-radio label="moins de 10">Moins de <span>10</span> millions</el-radio>
                    <el-radio label="entre 10 et 30">Entre <span>10</span> et <span>30</span> millions</el-radio>
                    <el-radio label="plus de 30">Plus de <span>30</span> millions</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="margin-top: -30px;" :span="24">
            <div class="grid-content bg-purple">
              <el-form>
                <el-form-item>
                  <label>Tranche de patrimoine : </label>
                  <el-radio-group v-model="ContractantFields.patrimoine">
                    <el-radio label="moins de 50">Moins de <span>50</span> millions</el-radio>
                    <el-radio label="entre 50 et 150">Entre <span>50</span> et <span>150</span> millions</el-radio>
                    <el-radio label="plus de 150">Plus de <span>150</span> millions</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="ContractantFields.Nom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input placeholder="Votre prenom" v-model="ContractantFields.Prenom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Date de naissance</label>
              <el-date-picker
                  @change="yearsDiffContractant()"
                  style="width: 100%!important;"
                  v-model="ContractantFields.dateNaissance"
                  type="date"
                  format="yyyy/MM/dd"
                  placeholder="Choississez une date"
                  :picker-options="dateAfterToday">
              </el-date-picker>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Lieu de naissance</label>
              <el-input placeholder="Votre lieu de naissance" v-model="ContractantFields.LieuNaissance">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Profession</label>
              <el-input placeholder="Votre profession" v-model="ContractantFields.Profession">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Adresse</label>
              <el-input type="text" placeholder="Votre adresse" v-model="ContractantFields.Adresse">
              </el-input>
            </div>
          </el-col>
            <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Téléphone</label>
              <el-input type="number" placeholder="Votre numéro de téléphone" v-model="ContractantFields.Telephone">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Email</label>
              <el-input placeholder="Adresse e-mail" v-model="ContractantFields.Email">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>CNI</label>
              <el-input type="number" min="1" placeholder="Votre numéro de CNI" v-model="ContractantFields.Cni">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Nationalité</label>
              <el-input placeholder="Votre nationalité" v-model="ContractantFields.Nationalite">
              </el-input>
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top:15px">
        <el-button style="float:left" @click="e1 =1" round>Retour</el-button>
        <el-button @click="ContractControl()" type="primary" round>Suivant</el-button>
      </div>
    </div>

    <div v-if="e1 == 3 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>

        <h3>Assuré :</h3>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="24">
            <div class="justfy-center grid-content bg-purple-dark">
              <p>Le contractant est l'assuré ?</p>
              <el-switch
                  style="display: block"
                  v-model="checkbox"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Oui"
                  inactive-text="Non"
                  @change="toggleCheckbox">
              </el-switch>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input :disabled="this.checkbox == true" placeholder="Votre nom" v-model="AssureFields.Nom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input :disabled="this.checkbox == true" placeholder="Votre prenom" v-model="AssureFields.Prenom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Date de naissance</label>
              <el-date-picker
                  :disabled="disableDateAssure"
                  @change="yearsDiffAssure()"
                  style="width: 100%!important;"
                  v-model="AssureFields.dateNaissance"
                  type="date"
                  format="yyyy/MM/dd"
                  placeholder="Choississez une date"
                  :picker-options="dateAfterToday">
              </el-date-picker>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Lieu de naissance</label>
              <el-input :disabled="this.checkbox == true" placeholder="Votre lieu de naissance" v-model="AssureFields.LieuNaissance">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Profession</label>
              <el-input :disabled="this.checkbox == true" placeholder="Votre profession" v-model="AssureFields.Profession">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Adresse</label>
              <el-input :disabled="this.checkbox == true" type="text" placeholder="Votre adresse" v-model="AssureFields.Adresse">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone</label>
              <el-input :disabled="this.checkbox == true" type="number" placeholder="Votre numéro de téléphone" v-model="AssureFields.Telephone">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Email</label>
              <el-input :disabled="this.checkbox == true" placeholder="Adresse e-mail" v-model="AssureFields.Email">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Lieu d'exercice profession</label>
              <el-input type="text" min="1" placeholder="Votre lieu d'exercice" v-model="AssureFields.LieuExe">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nationalité</label>
              <el-input :disabled="this.checkbox == true" placeholder="Votre nationalité" v-model="AssureFields.Nationalite">
              </el-input>
            </div>
          </el-col>
        </el-row>


        <div style="margin-top:15px">
          <el-button style="float:left" round @click="e1 = 2">Retour</el-button>
          <el-button @click="AssureControl" type="primary" round>Suivant</el-button>
        </div>
      </div>
    </div>

    <div v-if="e1 == 3 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>

        <h3>Assuré :</h3>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="24">
            <div class="justfy-center grid-content bg-purple-dark">
              <p>Le contractant est l'assuré ?</p>
              <el-switch
                  style="display: block"
                  v-model="checkbox"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Oui"
                  inactive-text="Non"
                  @change="toggleCheckbox">
              </el-switch>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input :disabled="this.checkbox == true" placeholder="Votre nom" v-model="AssureFields.Nom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input :disabled="this.checkbox == true" placeholder="Votre prenom" v-model="AssureFields.Prenom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Date de naissance</label>
              <el-date-picker
                  :disabled="disableDateAssure"
                  @change="yearsDiffAssure()"
                  style="width: 100%!important;"
                  v-model="AssureFields.dateNaissance"
                  type="date"
                  format="yyyy/MM/dd"
                  placeholder="Choississez une date"
                  :picker-options="dateAfterToday">
              </el-date-picker>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Lieu de naissance</label>
              <el-input :disabled="this.checkbox == true" placeholder="Votre lieu de naissance" v-model="AssureFields.LieuNaissance">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Profession</label>
              <el-input :disabled="this.checkbox == true" placeholder="Votre profession" v-model="AssureFields.Profession">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Adresse</label>
              <el-input :disabled="this.checkbox == true" type="text" placeholder="Votre adresse" v-model="AssureFields.Adresse">
              </el-input>
            </div>
          </el-col>
            <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Téléphone</label>
              <el-input :disabled="this.checkbox == true" type="number" placeholder="Votre numéro de téléphone" v-model="AssureFields.Telephone">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Email</label>
              <el-input :disabled="this.checkbox == true" placeholder="Adresse e-mail" v-model="AssureFields.Email">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Lieu d'exercice profession</label>
              <el-input type="text" min="1" placeholder="Votre lieu d'exercice" v-model="AssureFields.LieuExe">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Nationalité</label>
              <el-input :disabled="this.checkbox == true" placeholder="Votre nationalité" v-model="AssureFields.Nationalite">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button style="float:left" round @click="e1 = 2">Retour</el-button>
          <el-button @click="AssureControl" type="primary" round>Suivant</el-button>
        </div>
      </div>
    </div>

    <div v-if="e1 == 4 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>

        <h3>Bénéficiaire :</h3>

        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="BenefiFields.Nom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input placeholder="Votre prenom" v-model="BenefiFields.Prenom">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Date de naissance</label>
              <el-date-picker
                  disabled
                  @change="yearsDiffAssure()"
                  style="width: 100%!important;"
                  v-model="BenefiFields.dateNaissance"
                  type="date"
                  format="yyyy/MM/dd"
                  placeholder="Choississez une date"
                  :picker-options="dateAfterToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Lieu de naissance</label>
              <el-input placeholder="Votre lieu de naissance" v-model="BenefiFields.LieuNaissance">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Filiation</label>
              <el-select style="width:100%" v-model="BenefiFields.Filiation" placeholder="Selectionner une filiation">
                <el-option value="null" selected disabled="">Filiation</el-option>
                <el-option value="fils_fille">Fils/Fille</el-option>
                <el-option value="frere_soeur">Frère/Soeur</el-option>
                <el-option value="neveu_niece">Neveu/Niece</el-option>
                <el-option value="cousin_e">Cousin(e)</el-option>
                <el-option value="autres">Autre (adoptive,...</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button style="float:left" round @click="e1 = 3">Retour</el-button>
          <el-button @click="BenefControl" type="primary" round>Suivant</el-button>
        </div>
      </div>
    </div>

     <div v-if="e1 == 4 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>

        <h3>Bénéficiaire :</h3>

        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="BenefiFields.Nom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input placeholder="Votre prenom" v-model="BenefiFields.Prenom">
              </el-input>
            </div>
          </el-col>
            <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Date de naissance</label>
              <el-date-picker
                  disabled
                  @change="yearsDiffAssure()"
                  style="width: 100%!important;"
                  v-model="BenefiFields.dateNaissance"
                  type="date"
                  format="yyyy/MM/dd"
                  placeholder="Choississez une date"
                  :picker-options="dateAfterToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Lieu de naissance</label>
              <el-input placeholder="Votre lieu de naissance" v-model="BenefiFields.LieuNaissance">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Filiation</label>
              <el-select style="width:100%" v-model="BenefiFields.Filiation" placeholder="Selectionner une filiation">
                <el-option value="null" selected disabled="">Filiation</el-option>
                <el-option value="fils_fille">Fils/Fille</el-option>
                <el-option value="frere_soeur">Frère/Soeur</el-option>
                <el-option value="neveu_niece">Neveu/Niece</el-option>
                <el-option value="cousin_e">Cousin(e)</el-option>
                <el-option value="autres">Autre (adoptive,...</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button style="float:left" round @click="e1 = 3">Retour</el-button>
          <el-button @click="BenefControl" type="primary" round>Suivant</el-button>
        </div>
      </div>
    </div>

    <div v-if="e1 == 5 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>
        <el-row :gutter="24">
          <el-col :span="24">
            <div class="item-box card-shadow--medium" style="margin-top:15px">
              <h3 class="text-center">DÉTAILS SUR L'ASSURANCE</h3>
              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Contractant</span>
                      </div>
                      <div  >
                        <ul style="list-style:none">
                          <li>Genre : <span class="to-right assuraf-orange">{{ContractantFields.Genre}}</span></li>
                          <li>Situation : <span class="to-right assuraf-orange">{{ContractantFields.Situation}}</span></li>
                          <li>Nom : <span class="to-right assuraf-orange">{{ContractantFields.Nom}}</span></li>
                          <li>Prénom : <span class="to-right assuraf-orange">{{ContractantFields.Prenom}}</span></li>
                          <li>Date de naissance : <span class="to-right assuraf-orange">{{DateFormater(ContractantFields.dateNaissance)}}</span></li>
                          <li>Lieu de naissance : <span class="to-right assuraf-orange">{{ContractantFields.LieuNaissance}}</span></li>
                          <li>Profession : <span class="to-right assuraf-orange">{{ContractantFields.Profession}}</span></li>

                          <li>Adresse : <span class="to-right assuraf-orange">{{ContractantFields.Adresse}}</span></li>
                          <li>Téléphone : <span class="to-right assuraf-orange">{{ContractantFields.Telephone}}</span></li>
                          <li>Email : <span class="to-right assuraf-orange">{{ContractantFields.Email}}</span></li>
                          <li>CNI : <span class="to-right assuraf-orange">{{ContractantFields.Cni}}</span></li>
                          <li>Nationalite : <span class="to-right assuraf-orange">{{ContractantFields.Nationalite}}</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple-light">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Assuré</span>
                      </div>
                      <div  >
                        <ul style="list-style:none">
                          <li>Nom : <span class="to-right assuraf-orange">{{AssureFields.Nom}}</span></li>
                          <li>Prénom : <span class="to-right assuraf-orange">{{AssureFields.Prenom}}</span></li>
                          <li>Date de naissance : <span class="to-right assuraf-orange">{{DateFormater(AssureFields.dateNaissance)}}</span></li>
                          <li>Lieu de naissance : <span class="to-right assuraf-orange">{{AssureFields.LieuNaissance}}</span></li>
                          <li>Profession : <span class="to-right assuraf-orange">{{AssureFields.Profession}}</span></li>

                          <li>Adresse : <span class="to-right assuraf-orange">{{AssureFields.Adresse}}</span></li>
                          <li>Téléphone : <span class="to-right assuraf-orange">{{AssureFields.Telephone}}</span></li>
                          <li>Email : <span class="to-right assuraf-orange">{{AssureFields.Email}}</span></li>
                          <li>Nationalite : <span class="to-right assuraf-orange">{{AssureFields.Nationalite}}</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple-light" style="margin-bottom: 20px;
    margin-right: 20px;">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Bénéficiaire</span>
                      </div>

                      <div  >
                        <ul style="list-style:none">
                          <li>Nom : <span class="to-right assuraf-orange">{{BenefiFields.Nom}}</span></li>
                          <li>Prénom : <span class="to-right assuraf-orange">{{BenefiFields.Prenom}}</span></li>
                          <li>Date de naissance : <span class="to-right assuraf-orange">{{DateFormater(BenefiFields.dateNaissance)}}</span></li>
                          <li>Lieu de naissance : <span class="to-right assuraf-orange">{{BenefiFields.LieuNaissance}}</span></li>
                          <li>Filiation : <span class="to-right assuraf-orange">{{BenefiFields.Filiation}}</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
              </el-row>
            </div>

            <div class="item-box card-shadow--medium" style="margin-top:15px">
              <h3 class="text-center">DÉTAILS DES COTISATIONS</h3>
              <el-row :gutter="24" style="margin-top:20px">
                <el-col :span="24">
                  <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Garanties</span>
                      </div>
                      <div  >
                        <ul style="list-style:none">
                          <li>Durée des cotisations : <span class="to-right assuraf-orange">{{duree}}</span></li>
                          <li>Durée de service : <span class="to-right assuraf-orange">{{Duree_bourse}} ans</span></li>
                          <li v-if="Mensualite">Bourse annuelle : <span v-if="Calculated_response != null" class="to-right assuraf-orange">{{Calculated_response.toLocaleString()}} CFA</span></li>
                          <li v-show="Mensualite">Prime mensuelle : <span v-if="Calculated_response != null" class="to-right assuraf-orange">{{choixCapital.Mensuel}} CFA</span></li>
                          <li v-show="Capital">Bourse annuelle : <span class="to-right assuraf-orange">{{choixCapital.Capital}} CFA</span></li>
                          <li v-show="Capital">Prime mensuelle : <span class="to-right assuraf-orange">{{monthly_payment}} CFA</span></li>
                          <li>Branche : <span class="to-right assuraf-orange">{{branche}}</span></li>
                          <li>Assureur : <span class="to-right assuraf-orange">{{Assureur}}</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
              </el-row>
            </div>

            <div class="item-box card-shadow--medium" style="margin-top:15px">
              <h3 class="text-center">PÉRIODE DE COUVERTURE</h3>
              <el-row :gutter="24" style="margin-top:20px">
                <el-col :span="12">
                  <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                    <label>Date d'effet</label>
                    <el-date-picker
                        @change="dateDiff"
                        style="width: 100%!important;"
                        v-model="dateEffet"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date"
                        :picker-options="dateAfterToday">
                    </el-date-picker>
                  </div>
                </el-col>

                <el-col :span="12">
                  <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                    <label>Date d"échéance</label>
                    <el-input placeholder="Date d'échéance" v-model="dateEcheance" readonly>
                    </el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button style="float:left" round @click="e1 = 4">Retour</el-button>
          <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="CreateDevis" type="primary" round>Valider</el-button>
        </div>
      </div>
    </div>

    <div v-if="e1 == 5 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>
        <el-row :gutter="24">
          <el-col :span="24">
            <div class="item-box card-shadow--medium" style="margin-top:15px">
              <h3 class="text-center">DÉTAILS SUR L'ASSURANCE</h3>
              <el-row :gutter="20">
                <el-col :span="24">
                  <div class="grid-content bg-purple">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Contractant</span>
                      </div>
                      <div  >
                        <ul style="list-style:none">
                          <li>Genre : <span class="to-right assuraf-orange">{{ContractantFields.Genre}}</span></li>
                          <li>Situation : <span class="to-right assuraf-orange">{{ContractantFields.Situation}}</span></li>
                          <li>Nom : <span class="to-right assuraf-orange">{{ContractantFields.Nom}}</span></li>
                          <li>Prénom : <span class="to-right assuraf-orange">{{ContractantFields.Prenom}}</span></li>
                          <li>Date de naissance : <span class="to-right assuraf-orange">{{ DateFormater(ContractantFields.dateNaissance) }}</span></li>
                          <li>Lieu de naissance : <span class="to-right assuraf-orange">{{ContractantFields.LieuNaissance}}</span></li>
                          <li>Profession : <span class="to-right assuraf-orange">{{ContractantFields.Profession}}</span></li>

                          <li>Adresse : <span class="to-right assuraf-orange">{{ContractantFields.Adresse}}</span></li>
                          <li>Téléphone : <span class="to-right assuraf-orange">{{ContractantFields.Telephone}}</span></li>
                          <li>Email : <span class="to-right assuraf-orange">{{ContractantFields.Email}}</span></li>
                          <li>CNI : <span class="to-right assuraf-orange">{{ContractantFields.Cni}}</span></li>
                          <li>Nationalite : <span class="to-right assuraf-orange">{{ContractantFields.Nationalite}}</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                  <div class="grid-content bg-purple-light">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Assuré</span>
                      </div>
                      <div  >
                        <ul style="list-style:none">
                          <li>Nom : <span class="to-right assuraf-orange">{{AssureFields.Nom}}</span></li>
                          <li>Prénom : <span class="to-right assuraf-orange">{{AssureFields.Prenom}}</span></li>
                          <li>Date de naissance : <span class="to-right assuraf-orange">{{DateFormater(AssureFields.dateNaissance)}}</span></li>
                          <li>Lieu de naissance : <span class="to-right assuraf-orange">{{AssureFields.LieuNaissance}}</span></li>
                          <li>Profession : <span class="to-right assuraf-orange">{{AssureFields.Profession}}</span></li>

                          <li>Adresse : <span class="to-right assuraf-orange">{{AssureFields.Adresse}}</span></li>
                          <li>Téléphone : <span class="to-right assuraf-orange">{{AssureFields.Telephone}}</span></li>
                          <li>Email : <span class="to-right assuraf-orange">{{AssureFields.Email}}</span></li>
                          <li>Nationalite : <span class="to-right assuraf-orange">{{AssureFields.Nationalite}}</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                  <div class="grid-content bg-purple-light">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Bénéficiaire</span>
                      </div>

                      <div  >
                        <ul style="list-style:none">
                          <li>Nom : <span class="to-right assuraf-orange">{{BenefiFields.Nom}}</span></li>
                          <li>Prénom : <span class="to-right assuraf-orange">{{BenefiFields.Prenom}}</span></li>
                          <li>Date de naissance : <span class="to-right assuraf-orange">{{DateFormater(BenefiFields.dateNaissance)}}</span></li>
                          <li>Lieu de naissance : <span class="to-right assuraf-orange">{{BenefiFields.LieuNaissance}}</span></li>
                          <li>Filiation : <span class="to-right assuraf-orange">{{BenefiFields.Filiation}}</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
              </el-row>
            </div>

            <div class="item-box card-shadow--medium" style="margin-top:15px">
              <h3 class="text-center">DÉTAILS DES COTISATIONS</h3>
              <el-row :gutter="24" style="margin-top:20px">
                <el-col :span="24">
                  <div class="grid-content bg-purple">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Garanties</span>
                      </div>
                      <div  >
                        <ul style="list-style:none">
                          <li>Durée des cotisations : <span class="to-right assuraf-orange">{{duree}}</span></li>
                          <li>Durée de service : <span class="to-right assuraf-orange">{{Duree_bourse}} ans</span></li>
                          <li v-if="Mensualite">Bourse annuelle : <span v-if="Calculated_response != null" class="to-right assuraf-orange">{{Calculated_response.toLocaleString()}} CFA</span></li>
                          <li v-show="Mensualite">Prime mensuelle : <span v-if="Calculated_response != null" class="to-right assuraf-orange">{{choixCapital.Mensuel}} CFA</span></li>
                          <li v-show="Capital">Bourse annuelle : <span class="to-right assuraf-orange">{{choixCapital.Capital}} CFA</span></li>
                          <li v-show="Capital">Prime mensuelle : <span class="to-right assuraf-orange">{{monthly_payment}} CFA</span></li>
                          <li>Branche : <span class="to-right assuraf-orange">{{branche}}</span></li>
                          <li>Assureur : <span class="to-right assuraf-orange">{{Assureur}}</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
              </el-row>
            </div>

            <div class="item-box card-shadow--medium" style="margin-top:15px">
              <h3 class="text-center">PÉRIODE DE COUVERTURE</h3>
              <el-row :gutter="24" style="margin-top:20px">
                <el-col :span="24">
                  <div class="grid-content bg-purple">
                    <label>Date d'effet</label>
                    <el-date-picker
                        @change="dateDiff"
                        style="width: 100%!important;"
                        v-model="dateEffet"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date"
                        :picker-options="dateAfterToday">
                    </el-date-picker>
                  </div>
                </el-col>

                <el-col :span="24" style="margin-top:15px">
                  <div class="grid-content bg-purple">
                    <label>Date d"échéance</label>
                    <el-input placeholder="Date d'échéance" v-model="dateEcheance" readonly>
                    </el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button style="float:left" round @click="e1 = 4">Retour</el-button>
          <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="CreateDevis" type="primary" round>Valider</el-button>
        </div>
      </div>
    </div>

    <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile">
      <el-form ref="form" :model="valid" label-width="120px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="nom"></el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top:15px">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Numéro de téléphone</label>
              <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Adresse e-mail</label>
              <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top:15px">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Sexe</label>
              <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                <el-option label="Homme" value="homme"></el-option>
                <el-option label="Femme" value="femme"></el-option>
                <el-option label="entreprise" value="entreprise"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="createuser()">Créer</el-button>
      </div>
    </el-dialog>

     <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="isMobile">
      <el-form ref="form" :model="valid" label-width="120px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="nom"></el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Numéro de téléphone</label>
              <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Adresse e-mail</label>
              <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Sexe</label>
              <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                <el-option label="Homme" value="homme"></el-option>
                <el-option label="Femme" value="femme"></el-option>
                <el-option label="entreprise" value="entreprise"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="createuser()">Créer</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Modalité des prochains paiements" :visible.sync="PaymentFuturMode">
      <el-form ref="form" :model="valid" label-width="120px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple-light">
              <label>Choix paiement</label>
              <el-select style="width:100%" v-model="PaymentFutur" placeholder="Selectionner une modalité">
                <el-option value="null" selected disabled="">choix paiement</el-option>
                <el-option value="cash">Cash</el-option>
                <el-option value="cheque">Cheque</el-option>
                <el-option value="prelevement">Prelevement bancaire</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="ValidPaymentFutur">Valider</el-button>
      </div>
    </el-dialog>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import pays from '../../../common/countries'
import ville from '../../../common/ville.json'
//fin importation assuraf
import customPassword from "../../../services/password_generator"

const validatePhone = phone => {
   if(!phone.length){
    return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
  }else{
    return { valid: true, error: null };
  }
};

const validateEmail = email => {
  if (!email.length) {
    return {valid: false, error: "L'adresse e-mail est obligatoire!"};
  } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Veuillez entrer un email valide!" };
  } else {
    return { valid: true, error: null };
  }
};

export default {
  name: "devis_nsia_etudes",

  data() {
    return {
      modalgenerationloading : false,
      isMobile : false,
      choix_prime : 'bourse_annuelle',
      optionsdateEffet: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
        minDate: new Date(),
      },
      buttons: false,
      buttons_2: false,
      buttons_3: false,
      buttons_4: false,
      buttons_5: false,
      first_page: false,
      second_page: false,
      thirst_page: false,
      fourt_page: false,
      five_page: false,
      questionTest: "",
      QuestionnaireTab: [],
      QuestionMedical: null,
      PaymentFutur: null,
      PaymentFuturMode: false,
      Duree_bourse: null,
      oldDate: null,
      bottomPushNotif: false,
      disableDateAssure: true,
      Modalite: null,
      Assureur: "",
      Simule: true,
      SkipSimule: false,
      money: {
        thousands: " ",
        precision: false,
        masked: false,
      },
      choixOffre: null,
      choixOffreFinale: null,
      dateNaiss: null,
      duree: null,
      Capital: true,
      Mensualite: false,
      Capi_souhaite: null,
      rente_an: null,
      pourcent: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
      choixCapital: {
        Capital: null,
        Mensuel: null,
      },
      elevation: 2,
      activeStep: 0,
      checkbox: false,
      errors: {},
      aStorMap: false,
      disable: false,
      valide: null,
      QuestionSwitch: {
        Q1: null,
        Q2: null,
        Q3: null,
        Q4: null,
        Q5: null,
        Q6: null,
        Q7: null,
      },
      QuestionNext: {
        Q: true,
        Q1: false,
        Q2: false,
        Q3: false,
        Q4: false,
        Q5: false,
        Q6: false,
        Q7: false,
        Q8: false,
        Q9: false,
        Q10: false,
        Q11: false,
        Q12: false,
        Q13: false,
        Q14: false,

        Q15: false,
        Q16: false,
        Q17: false,
        Q18: false,
        Q19: false,
        Q20: false,
        Q21: false,

        Q22: false,
        Q23: false,
        Q24: false,
        Q25: false,
        Q26: false,
        Q27: false,
        Q28: false,

        Q29: false,
        Q30: false,
        Q31: false,
        Q32: false,
        Q33: false,
        Q34: false,
        Q35: false,
      },

      // numTelephone : '' ,
      amenities: [1, 4],
      genre: null,

      fraisLiv: 0,
      fraisPaiement: 0,
      Testons: false,
      itemsGenre: ["Homme", "Femme"],
      adresselivraison: "",
      autoHeight: true,
      x: null,
      dialogAlert: false,
      Devis_pour_souscription: [],
      mode_livraison: null,
      listVille: ville,

      menuDelivrance: false,
      Calculated_response: null,
      monthly_payment: null,
      mode_payement: null,
      notifDeviParMail: null,
      countries: null,
      ville: null,
      quartier: "",
      listegaranties: [],
      marque: null,
      tableau_info_passager: [],
      nbPassager: null,
      frais_livraison: null,
      branche: null,
      continue_btn: false,
      dialogInfoPassager: false,
      dialog: false,
      city: null,
      datePickerDateNais: false,
      IsPaid: false,
      livePaper: false,
      checked: false,

      animation: "animate-in",
      NomAssure: null,
      isContractant: true,
      isAssure: false,
      isBenef: false,
      Questionnaire: false,
      QuestionnaireSuite: false,
      isPayment: false,
      menu: false,
      Capi: 0,
      Mensu: 0,
      // Contractant fields
      ContractantFields: {
        Cni: null,
        Genre: null,
        Situation: null,
        Nom: null,
        Prenom: null,
        dateNaissance: null,
        LieuNaissance: null,
        Profession: null,
        Adresse: null,
        Telephone: null,
        Email: null,
        Nationalite: null,
        patrimoine: null,
        revenu_annuel: null,
      },
      // Assure fields
      AssureFields: {
        Nom: null,
        Prenom: null,
        dateNaissance: null,
        LieuNaissance: null,
        Profession: null,
        Adresse: null,
        Telephone: null,
        Email: null,
        LieuExe: null,
        Nationalite: null,
      },
      // Beneficiaire fields
      BenefiFields: {
        Nom: null,
        Prenom: null,
        dateNaissance: null,
        LieuNaissance: null,
        Filiation: "",
      },
      // Questionnaire fields
      QuestionsFieldsSuite: {
        taille: null,
        poids: null,
        ArretTravailW: null,
        ArretTravailM: null,
        InterompTravailDate: null,
        InterompTravailMotif: null,
        InterompTravailDuree: null,
        SejourDate: null,
        SejourMotif: null,
        SejourDuree: null,
        interventionDate: null,
        interventionMotif: null,
        infirm: null,
        infirmDate: null,
        infirmTaux: null,
        infirmServie: null,
        infectionNbre: null,
        infectionDate: null,
        infectRespiratoire: null,
      },

      formSteps: [
        {
          title: "CONTRACTANT",
          fields: [{ valid: true }],
        },
        {
          title: "ASSURE",
          fields: [{ valid: true }],
        },
        {
          title: "BENEFICIAIRE",
          fields: [{ valid: true }],
        },
        {
          title: "QUESTIONNAIRE MEDICAL",
          fields: [{ valid: true }],
        },
        {
          title: "QUESTIONNAIRE MEDICAL SUITE",
        },
      ],
      CreatedDevis: null,

      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      dateAfterToday: {
        disabledDate(time) {
                    return time.getTime() <  (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
        }
      },
      dateAfterDateDepart : {
        disabledDate(time) {
          return time.getTime() > this.dateDepart;
        }
      },
      remboursable:null,
      optionsdateDepart: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
        minDate: new Date(),
      },

      optionsdateArrivee: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
        minDate: new Date(),
      },
      PassengerBirthOptionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      optionsdateUser: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
        defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
        maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
      },
      countriesSn: 'Sénégal',

      hideForm: false,
      popupListePassager: false,
      steps: [{
        label: 'Etape 1',
        completed: false,
      },
        {
          label: 'Etape 2',
          completed: false,
        },
        {
          label: 'Etape 3',
          completed: false,
        },
      ],
      clientnonOk: false,
      clientOk: false,
      clientsearch: null,
      adulte_enfant: null,
      seen: true,
      selected: [],
      nexted: false,
      selectpays: '',
      ToastTabSucess: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'success',
        className: 'custom-success-class'
      }, // Customized options for success toast
      ToastTabError: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'error'
      }, // Customized options for error toast
      listpays: pays,
      dateDepart: null,
      dateEffet: null,
      dateEcheance: null,
      pays_domicile: null,
      dateArrivee: null,
      nbpassager: null,
      garantie: [],
      age: null,
      assureurs: [],
      listpaysprime: [],
      usersearch: [],
      telephoneclient: '',
      destination: null,
      listeAssureurs: [],
      listvoyage: [],
      listPassager: [],
      datenaissancePassager: null,
      enteteTabPassager: [{
        key: 'adulte_enfant',
        label: 'Adulte / Enfant'
      },
        {
          key: 'age_passager',
          label: 'Age passager'
        },
      ],

      items: [{
        text: 'homme'
      },
        {
          text: 'femme'
        },
      ],

      NumberInput : false,
      EmailInput : false,

      radio: '1',



      optionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
        minDate: new Date(),
      },
      optionsdateMEC: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
        maxDate: new Date(),
      },

      e1: 0,

      emailclient: null,
      emailC: this.emailclient,
      telephoneC: this.telephoneclient,
      showId: false,
      showconfirmclient: false,
      recupIdClient: '',
      shownameclient: true,
      hidesearchClient: true,
      nom: null,
      prenom: null,
      email: null,
      datenaissance: null,
      profession: null,
      AddUser: false,
      adresse: null,
      piece_identite: null,
      numero_piece: null,
      hideDive_vehicule: null,

      password1: null,

      garanties: [],


      voidField: false,

      Deb: null,
      selectedcarbu: '',

      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: [],
      userRole: null
    }
  },
  mounted() 
  {
    window.addEventListener("resize", this.__resizeHanlder)
    this.getUserRoles()
  },

  methods: 
  {

    getUserRoles(){ 
            Api().get('/roles/all')
            .then(response => {
                this.listRoles = response.data.AllRoles
                for(let lr of response.data.AllRoles){
                    if(lr.name=="User"){
                        this.userRole = lr._id
                    }
                }
            }).catch(err => {console.log("failed getAllUserRoles");})
    },

     init() {
        if (window.innerWidth <= 768) this.isMobile = true
      },
    showidclient() {
      this.showId = false;
      this.showconfirmclient = true;
      this.shownameclient = false;
    },

    hidesearch() {
      this.hidesearchClient = false;
      this.e1 = 1;
    },

    rechercherbyphone() {
      if (this.usersearch.telephone_port === this.telephoneclient) {
        this.clientOk = true;
        this.clientnonOk = false;
      }
      if (this.usersearch.telephone_port !== this.telephoneclient) {
        this.clientnonOk = true;
        this.clientOk = false;
        this.telephoneC = this.telephoneclient;
      }
    },
    
    generatePassword: function () {
      this.password1 = customPassword.customPassword(this.nom, this.prenom)
    },

    // fonction pour formattage de Date (:-)
    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format("DD-MM-YYYY");
      }
    },

    nextPage() {
      this.second_page = true;
      this.first_page = false;
      this.QuestionNext.Q8 = true;
    },

    nextPageToThirst() {
      this.second_page = false;
      this.first_page = false;
      this.thirst_page = true;
      this.QuestionNext.Q15 = true;
    },

    nextPageToFourt() {
      this.second_page = false;
      this.first_page = false;
      this.thirst_page = false;
      this.fourt_page = true;
      this.QuestionNext.Q22 = true;
    },

    nextPageToFive() {
      this.second_page = false;
      this.first_page = false;
      this.thirst_page = false;
      this.fourt_page = false;
      this.five_page = true;
      this.QuestionNext.Q29 = true;
    },

    ValidPaymentFutur() {
      if (this.PaymentFutur) {
        this.PaymentFuturMode = false;
      } else {
        this.$toasted.show("Choix obligatoire !", this.ToastTabError);
      }
    },

    DynamicSimule() {
      if (this.Capital) {
        if (
            this.dateNaiss &&
            this.choixCapital.Capital &&
            this.duree &&
            this.Duree_bourse
        ) {
          this.fieldsControl();
        } else {
          this.bottomPushNotif = false;
        }
      } else if (this.Mensualite) {
        if (
            this.dateNaiss &&
            this.choixCapital.Mensuel &&
            this.duree &&
            this.Duree_bourse
        ) {
          this.fieldsControl();
        } else {
          this.bottomPushNotif = false;
        }
      }
      //this.Capi.replace
    },
    CalculCapitalRente() {
      let capi = 0.1;
      if (
          Number(this.choixCapital.Capital.replace(/[^0-9.-]+/g, "")) >= 500000
      ) {
        this.Capi =
            capi * Number(this.choixCapital.Capital.replace(/[^0-9.-]+/g, ""));
      } else {
        this.Capi = 0;
      }
      this.CheckFieldChange();
    },

    CalculMensuRente() {
      let capi = 0.1;
      this.Mensu = parseInt(capi * this.Calculated_response);
    },

    /*========== Fonction pour faire choix entre capital ou mensualites ========*/
    Choix() {
      if ((this.choixOffre = this.Capital)) {
        this.choixOffreFinale = "Capital";
        this.choixCapital.Mensuel = null;
      } else if (this.choixOffre = this.Mensualite) {
        this.choixCapital.Capital = null;
        this.choixOffreFinale = "Mensualite";
      }
    },

    /*===Send of data to API allowing create proposition for the customer===*/
    CreateDevis: function () {
      if (this.dateEffet) {
        if (this.PaymentFutur == null) {
          this.PaymentFuturMode = true;
        } else {
          this.PaymentFuturMode = false;
          if (this.Capital)
          {
            const Sunu = {
              Contractant: this.ContractantFields,
              Assure: this.AssureFields,
              Beneficiaire: this.BenefiFields,
              Questionnaire: this.QuestionnaireTab, 
              Modalite: this.Modalite,
              duree: this.duree,
              branche: "Vie",
              assureurs: "NSIA-VIE",
              userid: this.usersearch._id,
              clientid: this.usersearch._id,
              createur: store.state.user._id,
              dateEffet: dayjs(this.dateEffet).format("YYYY-MM-DD"),
              dateEcheance: this.dateEcheance,
              dateSouscription: new Date(),
              prime_mensuelle: this.monthly_payment,
              capital: Number(
                  this.choixCapital.Capital.replace(/[^0-9.-]+/g, "")
              ),
              duree_bourse: this.Duree_bourse,
              Rente: Number(this.Capi),
              FuturPaiement: this.PaymentFutur,
              plateforme : 'Dash',
            }

            if (this.$store.state.user.role == 'Agent')
            {
              Sunu.courtierid = this.$store.state.user.societe
            }

            Api().post("/vie/create/devis", Sunu)
                .then((result) => {
                  Api()
                      .get("devis/" + result.data.devis._id)
                      .then((response) => {
                        this.modalgenerationloading = true
                        this.$store.dispatch("setDevisPayement", response.data);
                         setTimeout(() => (
                                    this.modalgenerationloading = false,
                                    this.$router.go(this.$router.push({
                                    name: 'paiement'
                                }))), 4000
                                );
                      });
                })
                .catch((err) => {
                  console.log("error");
                });
          } else if (this.Mensualite) {
            const Sunu = {
              Contractant: this.ContractantFields,
              Assure: this.AssureFields,
              Beneficiaire: this.BenefiFields,
              Questionnaire: this.QuestionnaireTab, // this.QuestionsFieldsSuite,
              Modalite: this.Modalite,
              duree: this.duree,
              branche: "Vie",
              assureurs: "NSIA-VIE",
              userid: store.state.user._id,
              clientid: store.state.user._id,
              createur: store.state.user._id,
              dateEffet: dayjs(this.dateEffet).format("YYYY-MM-DD"),
              dateEcheance: this.dateEcheance,
              dateSouscription: new Date(),
              prime_mensuelle: Number(
                  this.choixCapital.Mensuel.replace(/[^0-9.-]+/g, "")
              ),
              capital: this.Calculated_response,
              duree_bourse: this.Duree_bourse,
              Rente: Number(this.Mensu),
              FuturPaiement: this.PaymentFutur,
              plateforme : 'Dash',
            }

            if (this.$store.state.user.role == 'Agent')
            {
              Sunu.courtier = this.$store.state.user.societe
            }

            Api().post("/vie/create/devis", Sunu)
                .then((result) => {
                  Api()
                      .get("devis/" + result.data.devis._id)
                      .then((response) => {
                        this.modalgenerationloading = true
                        this.$store.dispatch("setDevisPayement", response.data);
                         setTimeout(() => (
                                    this.modalgenerationloading = false,
                                    this.$router.go(this.$router.push({
                                    name: 'paiement'
                                }))), 4000
                                );
                      });
                })
                .catch((err) => {
                  console.log("error");
                });
          }
        }
      } else {
        this.$toasted.show("Date d'effet obligatoire!", this.ToastTabError);
      }
    },

    /*=================Verification de la session de l'utilisateur et envoie des donnees==============*/
    SendData: function () {
      this.bottomPushNotif = true;
      const SimulateSunuData = {
        age: this.dateNaiss,
        duree: this.duree,
        Duree_bourse: this.Duree_bourse,
        capital: Number(this.choixCapital.Mensuel.replace(/[^0-9.-]+/g, "")),
      };

      Api()
          .post("/vie/nsia/vie/etudes/mensualite", SimulateSunuData)
          .then((res) => {
            this.Calculated_response = res.data.capital;
            this.CalculMensuRente();
          })
          .catch((err) => {
            console.log(err);
          });
    },

    SendDataDeduction: function () {
      this.bottomPushNotif = true;
      const SimulateSunuData = {
        age: this.dateNaiss,
        Duree_bourse: this.Duree_bourse,
        duree: this.duree,
        capital: Number(this.choixCapital.Capital.replace(/[^0-9.-]+/g, "")),
      };
      Api()
          .post("/vie/nsia/vie/etudes/capital", SimulateSunuData)
          .then((res) => {
            console.log("To PAY <===>" + res.data.monthly_payment);
            this.monthly_payment = res.data.monthly_payment;
            this.CalculCapitalRente();
          })
          .catch((err) => {
            console.log(err);
          });
    },

    //Control des champs pour validation
    fieldsControl() {
      const date1 = dayjs(this.dateNaiss);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      let year = Math.floor(daydiff / 360);

      this.Choix();
      let toDay = year;
      if (this.dateNaiss) {
        if (toDay > 65) {
          this.$message.error("L'année de naissance doit être inferieur ou égale à 65 ans !", this.AlertMessage)
        } else {
          if (this.choixCapital.Capital || this.choixCapital.Mensuel) {
            if (this.choixOffreFinale == "Capital") {
              if (
                  Number(this.choixCapital.Capital.replace(/[^0-9.-]+/g, "")) <
                  500000
              ) {
                this.$message.error("Le capital souhaite doit être au minimum 500 000 FCFA !", this.AlertMessage)
              } else {
                if (this.duree) {
                  if (this.duree >= 10) {
                    if (this.duree > 20) {
                      this.$message.error("Durée doit être inferieur à 20 ans !", this.AlertMessage)
                    } else {
                      if (this.Duree_bourse) {
                        let dureeTotal = Number(this.duree);
                        if (dureeTotal + toDay <= 65) {
                          this.SendDataDeduction();
                          this.SkipSimule = true;
                          this.Simule = false;
                          this.Modalite = "CAPITAL SOUHAITÉ";
                          this.branche = "vie";
                          this.Assureur = "NSIA-VIE";
                        } else {

                          this.$message.error("Revoir la durée de cotisation. L’âge du contractant ne doit pas dépasser 65ans au terme du contrat !", this.AlertMessage)
                        }
                      } else {
                        this.$message.error("Durée bourse obligatoire !", this.AlertMessage)
                      }
                    }
                  } else {
                    this.$message.error("Durée doit être supérieur ou égale à 10 ans !", this.AlertMessage)
                  }
                } else {
                  this.$message.error("Durée obligtoire !", this.AlertMessage)
                }
              }
            } else if (this.choixOffreFinale == "Mensualite") {
              if (
                  Number(this.choixCapital.Mensuel.replace(/[^0-9.-]+/g, "")) <
                  6000
              ) {
                this.$message.error("La mensualite souhaite doit être supérieur à 6 000 FCFA !", this.AlertMessage)
              } else {
                if (this.duree) {
                  if (this.duree >= 10) {
                    if (this.duree > 20) {
                      this.$message.error("Durée doit être inferieur à 20 ans !", this.AlertMessage)
                    } else {
                      if (this.Duree_bourse) {
                        let dureeTotal = Number(this.duree);
                        if (dureeTotal + toDay <= 65) {
                          this.SendData();
                          this.Modalite = "MENSUALITÉS SOUHAITÉES";
                          this.SkipSimule = true;
                          this.Simule = false;
                          this.branche = "vie";
                          this.Assureur = "SUNU Assurance vie";
                        } else {
                          this.$message.error("Revoir la durée de cotisation. L’âge du contractant ne doit pas dépasser 65ans au terme du contrat !", this.AlertMessage)
                        }
                      } else {
                        this.$message.error("Durée bourse obligtoire !", this.AlertMessage)
                      }
                    }
                  } else {
                    this.$message.error("Durée doit être supérieur ou égale à 10 ans !", this.AlertMessage)
                  }
                } else {
                  this.$message.error("Durée obligtoire !", this.AlertMessage)
                }
              }
            }
          } else {
            this.$message.error("Capital obligtoire !", this.AlertMessage)
          }
        }
      } else {
        this.$message.error("Année de naissance obligtoire !", this.AlertMessage)
      }
    },

    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },

    CheckFieldChange() {
      /* if(this.dateNaiss = !this.dateNaiss){*/
      this.Simule = true;
      this.SkipSimule = false;
      /*}*/
      this.DynamicSimule();
    },
    DevisNextStep() {
      const date1 = dayjs(this.BenefiFields.dateNaissance);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      let year = Math.floor(daydiff / 360);
      this.oldDate = this.dateNaiss;

      /*let toDay = year
                let dureeTotal = Number(this.duree)
*/
      if (this.BenefiFields.dateNaissance) {
        if (this.choixOffreFinale == "Capital") {
          if (this.monthly_payment < 10000) {
            this.$toasted.show(
                "La prime mensuelle doit étre suppérieure a 10 000 CFA !",
                this.ToastTabError
            );
          } else {
            if (store.state.isUserLoggedIn) {
              this.ContractantFields.Nom = this.usersearch.nom;
              this.ContractantFields.Prenom = this.usersearch.prenom;
              this.ContractantFields.Telephone = this.usersearch.telephone_port;
              this.ContractantFields.Email = this.usersearch.email;
              this.ContractantFields.dateNaissance = this.usersearch.datenaissance;
              this.ContractantFields.Genre = this.$store.state.user.sexe;
              this.ContractantFields.Situation = this.$store.state.user.statut_marital;
              this.ContractantFields.Profession = this.$store.state.user.profession;
              this.ContractantFields.Adresse = this.$store.state.user.adresse;
              this.ContractantFields.Cni = this.$store.state.user.numero_piece;
              this.e1 = 2;
              //this.ContractantFields.dateNaissance = this.dateNaiss;
              this.AssureFields.dateNaissance = this.dateNaiss;
            }
          }
        } else if (this.choixOffreFinale == "Mensualite") {
          if (this.Calculated_response < 500000) {
            this.$toasted.show(
                "La bourse annuelle doit étre suppérieure ou égale à 500 000 CFA !",
                this.ToastTabError
            );
          } else {
            if (store.state.isUserLoggedIn) {
              this.ContractantFields.Nom = this.$store.state.user.nom;
              this.ContractantFields.Prenom = this.$store.state.user.prenom;
              this.ContractantFields.Telephone =
                  this.$store.state.user.telephone_port;
              this.ContractantFields.Email = this.$store.state.user.email;
              this.ContractantFields.dateNaissance =
                  this.$store.state.user.datenaissance;
              this.ContractantFields.Genre = this.$store.state.user.sexe;
              this.ContractantFields.Situation =
                  this.$store.state.user.statut_marital;
              this.ContractantFields.Profession =
                  this.$store.state.user.profession;
              this.ContractantFields.Adresse = this.$store.state.user.adresse;
              this.ContractantFields.Cni = this.$store.state.user.numero_piece;
              this.e1 = 2;
              this.ContractantFields.dateNaissance = this.dateNaiss;
              this.AssureFields.dateNaissance = this.dateNaiss;
            }
          }
        }
      } else {
        this.$toasted.show(
            "Date bénéficiaire obligatoire !",
            this.ToastTabError
        );
      }
    },

    switchCapital() {
      if (this.choix_prime == 'bourse_annuelle') {
        //this.Choix()
        this.choixOffreFinale = "Capital";
        this.Capital = true;
        this.Mensualite = false;
        this.Mensu = 0;
        this.choixCapital.Mensuel = null;
        this.CheckFieldChange()
      }
      if (this.choix_prime == 'prime_mensuelle') {
        //this.Choix()
        this.choixOffreFinale = "Mensualite";
        this.Mensualite = true
        this.Capital = false;
        this.Capi = 0;
        this.choixCapital.Capital = null;
        this.CheckFieldChange()
      }
    },
    /*nextStep() {
                this.animation = 'animate-out';
                setTimeout(() => {
                    this.animation = 'animate-in';
                    this.activeStep += 1;
                }, 600);
            },*/
    toggleCheckbox() {
      if (this.checkbox == true) {
        this.AssureFields.Nom = this.ContractantFields.Nom;
        this.AssureFields.Prenom = this.ContractantFields.Prenom;
        this.AssureFields.dateNaissance = this.DateFormater(this.ContractantFields.dateNaissance);
        this.dateNaiss = this.ContractantFields.dateNaissance;

        //this.dateNaiss = this.ContractantFields.dateNaissance
        this.AssureFields.LieuNaissance = this.ContractantFields.LieuNaissance;
        this.AssureFields.Profession = this.ContractantFields.Profession;
        this.AssureFields.Adresse = this.ContractantFields.Adresse;
        this.AssureFields.Telephone = this.ContractantFields.Telephone;
        this.AssureFields.Email = this.ContractantFields.Email;
        this.AssureFields.Nationalite = this.ContractantFields.Nationalite;
        this.disableDateAssure = true;
        this.AssureControl();
        this.DynamicSimule();
      } else if (this.checkbox == false) {
        //this.disableDateAssure = false
        //this.dateNaiss = null
        this.AssureFields.dateNaissance = this.oldDate;
        this.dateNaiss = this.oldDate;
        this.DynamicSimule();
        //this.AssureFields.dateNaissance = this.dateNaiss
        this.ClearForm();
      }
    },
    ClearForm: function () {
      this.AssureFields.Nom = null;
      this.AssureFields.Prenom = null;
      //this.AssureFields.dateNaissance = null
      this.AssureFields.LieuNaissance = null;
      this.AssureFields.Profession = null;
      this.AssureFields.Adresse = null;
      this.AssureFields.Telephone = null;
      this.AssureFields.Email = null;
      this.AssureFields.LieuExe = null;
      this.AssureFields.Nationalite = null;
    },
    yearsDiff() {
      const date1 = dayjs(this.dateNaiss).year();
      const date2 = new Date().getFullYear();
      let daydiff = date2 - date1;
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
            "Erreur, date antérieure à la date actuelle! ",
            this.ToastTabError
        );
        this.dateNaiss = null;
      } else if (this.age) {
        if (this.age < 18 || this.age <= 0) {
          this.$toasted.show(
              "Erreur, l'age doit pas être Supérieur à 18 ans! ",
              this.ToastTabError
          );
          this.dateNaiss = null;
        } else if (this.age > 55) {
          this.$toasted.show(
              "L'année de naissance doit être inferieur ou égale à 55 ans!",
              this.ToastTabError
          );
          this.dateNaiss = null;
        }
      } else if (this.age <= 0) {
        if (this.age < 18 || this.age <= 0) {
          this.$toasted.show(
              "Erreur, l'age doit pas etre Supérieur à 18 ans! ",
              this.ToastTabError
          );
          this.dateNaiss = null;
        }
      }
      this.CheckFieldChange();
    },

    yearsDiffContractant() {
      const date1 = dayjs(this.ContractantFields.dateNaissance);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
            "Erreur, date antérieure à la date actuelle! ",
            this.ToastTabError
        );
        this.ContractantFields.dateNaissance = null;
      } else if (this.age) {
        let year = Math.floor(this.age / 360);
        this.age = year;
        if (this.age < 18 || this.age <= 0) {
          this.$toasted.show(
              "Erreur, l'age doit pas etre supperieur à 18 ans! ",
              this.ToastTabError
          );
          this.ContractantFields.dateNaissance = null;
        }
      } else if (this.age <= 0) {
        let year = Math.floor(this.age / 360);
        this.age = year;
        if (this.age < 18 || this.age <= 0) {
          this.$toasted.show(
              "Erreur, l'age doit pas etre supperieur à 18 ans! ",
              this.ToastTabError
          );
          this.ContractantFields.dateNaissance = null;
        }
      }
    },

    yearsDiffAssure() {
      const date1 = dayjs(this.AssureFields.dateNaissance);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
            "Erreur, date antérieure à la date actuelle! ",
            this.ToastTabError
        );
        this.AssureFields.dateNaissance = null;
      } else if (this.age) {
        let year = Math.floor(this.age / 360);
        this.age = year;
        if (this.age < 18) {
          this.$toasted.show(
              "Erreur, l'age doit pas etre Supérieur à 18 ans! ",
              this.ToastTabError
          );
          this.AssureFields.dateNaissance = null;
        }
      } else if (this.age <= 0) {
        let year = Math.floor(this.age / 360);
        this.age = year;
        if (this.age < 18) {
          this.$toasted.show(
              "Erreur, l'age doit pas etre Supérieur à 18 ans! ",
              this.ToastTabError
          );
          this.AssureFields.dateNaissance = null;
        }
      }
    },

    yearsDiffBenef() {
      const date1 = dayjs(this.BenefiFields.dateNaissance);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
            "Erreur, date antérieure à la date actuelle! ",
            this.ToastTabError
        );
        this.BenefiFields.dateNaissance = null;
      } else if (this.age >= 360) {
        let year = Math.floor(this.age / 360);
        this.age = year;
        if (this.age > 11) {
          this.$toasted.show(
              "Erreur, l'age doit pas etre Supérieur à 11 ans! ",
              this.ToastTabError
          );
          this.BenefiFields.dateNaissance = null;
        }
      }
    },

    yearsDiffQuestionSuite() {
      const date1 = dayjs(this.QuestionsFieldsSuite.ArretTravailW);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
            "Erreur, date antérieure à la date actuelle! ",
            this.ToastTabError
        );
        this.QuestionsFieldsSuite.ArretTravailW = null;
      }
    },

    yearsDiffQuestionSuite2() {
      const date1 = dayjs(this.QuestionsFieldsSuite.InterompTravailDate);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
            "Erreur, date antérieure à la date actuelle! ",
            this.ToastTabError
        );
        this.QuestionsFieldsSuite.InterompTravailDate = null;
      }
    },

    yearsDiffQuestionSuite3() {
      const date1 = dayjs(this.QuestionsFieldsSuite.SejourDate);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
            "Erreur, date antérieure à la date actuelle! ",
            this.ToastTabError
        );
        this.QuestionsFieldsSuite.SejourDate = null;
      }
    },

    yearsDiffQuestionSuite4() {
      const date1 = dayjs(this.QuestionsFieldsSuite.interventionDate);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
            "Erreur, date antérieure à la date actuelle! ",
            this.ToastTabError
        );
        this.QuestionsFieldsSuite.interventionDate = null;
      }
    },

    yearsDiffQuestionSuite5() {
      const date1 = dayjs(this.QuestionsFieldsSuite.infirmDate);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
            "Erreur, date antérieure à la date actuelle! ",
            this.ToastTabError
        );
        this.QuestionsFieldsSuite.infirmDate = null;
      }
    },

    yearsDiffQuestionSuite6() {
      const date1 = dayjs(this.QuestionsFieldsSuite.infectionDate);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
            "Erreur, date antérieure à la date actuelle! ",
            this.ToastTabError
        );
        this.QuestionsFieldsSuite.infectionDate = null;
      }
    },

    yearsDiffQuestionSuite7() {
      const date1 = dayjs(this.QuestionsFieldsSuite.infectRespiratoire);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
            "Erreur, date antérieure à la date actuelle! ",
            this.ToastTabError
        );
        this.QuestionsFieldsSuite.infectRespiratoire = null;
      }
    },

    BackToStep1() {
      this.animation = "animate-out-back";
      setTimeout(() => {
        this.animation = "animate-in-back";
        this.e1 = 2;
        this.activeStep -= 1;
        (this.isContractant = true), (this.isAssure = false);
      }, 500);
    },

    BackToStep2() {
      this.animation = "animate-out-back";
      setTimeout(() => {
        this.animation = "animate-in-back";
        this.activeStep -= 1;
        this.e1 = 3;
        (this.isContractant = false), (this.isAssure = true);
        this.isBenef = false;
      }, 500);
    },

    BackToStep3() {
      this.animation = "animate-out-back";
      setTimeout(() => {
        this.animation = "animate-in-back";
        this.activeStep -= 1;
        this.e1 = 4;
        (this.isContractant = false), (this.isAssure = false);
        this.isBenef = true;
        this.Questionnaire = false;
      }, 600);
    },
    BackToStep4() {
      this.animation = "animate-out-back";
      setTimeout(() => {
        this.animation = "animate-in-back";
        this.activeStep -= 1;
        this.e1 = 5;
        (this.isContractant = false), (this.isAssure = false);
        this.isBenef = false;
        this.Questionnaire = true;
        this.QuestionnaireSuite = false;
      }, 600);
    },

    /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
    onlyNumber($event) {
      let NumOnly = $event.NumOnly ? $event.NumOnly : $event.which;
      if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
        $event.preventDefault();
      }
    },

    dateDiff: function () {
      if (this.dateEffet === "" && this.duree === "") this.dateEcheance = "";
      let inter = dayjs(this.dateEffet)
          .add(this.duree, "year")
          .format("YYYY-MM-DD");
      this.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
    },

    nextStep() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep += 1;
        (this.isContractant = false), (this.isAssure = true);
      }, 600);
    },

    nextStep2() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep += 1;
        (this.isContractant = false),
            (this.isAssure = false),
            (this.isBenef = true);
      }, 600);
    },

    nextStep3() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep += 1;
        (this.isContractant = false),
            (this.isAssure = false),
            (this.isBenef = false),
            (this.QuestionnaireSuite = true);
      }, 600);
    },

    nextStep4() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep += 1;
        (this.isContractant = false),
            (this.isAssure = false),
            (this.isBenef = false),
            (this.QuestionnaireSuite = false);
        this.isPayment = true;
      }, 600);
    },

    ContractControl() {
      this.errors = {};
      if (this.ContractantFields.Genre && this.ContractantFields.Situation) {
        if (this.ContractantFields.patrimoine && this.ContractantFields.revenu_annuel) {
          if (this.ContractantFields.Nom && this.ContractantFields.Prenom) {
            if (this.ContractantFields.dateNaissance && this.ContractantFields.LieuNaissance) {
              if (this.ContractantFields.Profession && this.ContractantFields.Adresse) {
                if (this.ContractantFields.Telephone && this.ContractantFields.Email) {
                  if (this.ContractantFields.Cni) {
                      if (this.ContractantFields.Nationalite) {
                        this.e1 = 3;
                        this.nextStep();
                      } else {
                        this.$message.error("Nationalité obligatoire !", this.AlertMessage)
                      }
                    } else {
                      this.$message.error("Cni obligatoire !", this.AlertMessage)
                    }
                } else {
                  this.$message.error("Téléphone et Email obligatoires !", this.AlertMessage)
                }
              } else {
                this.$message.error("Profession et Adresse obligatoires !", this.AlertMessage)
              }
            } else {
              this.$message.error("Date et Lieu de naissance obligatoires !", this.AlertMessage)
            }
          } else {
            this.$message.error("Nom et Prénom obligatoires !", this.AlertMessage)
          }
        } else {
          this.$message.error("Revenu annuel et Patrimoine obligatoires !", this.AlertMessage)
        }
      } else {
        this.$message.error("Genre et Situation obligatoires !", this.AlertMessage)
      }
    },

    AssureControl() {
      this.errors = {};
      if (this.AssureFields.Nom && this.AssureFields.Prenom) {
        if (this.AssureFields.dateNaissance && this.AssureFields.LieuNaissance) {
          if (this.AssureFields.Profession && this.AssureFields.Adresse) {
            if (this.AssureFields.Telephone && this.AssureFields.Email) {
               if (this.AssureFields.LieuExe) {
                  if (this.AssureFields.Nationalite) {
                    this.e1 = 4;
                    this.nextStep2();
                  } else {
                    this.$message.error("Nationalité obligatoire !", this.AlertMessage)
                  }
                } else {
                  this.$message.error("Lieu d'exercice obligatoire !", this.AlertMessage)
                }
            } else {
              this.$message.error("Téléphone et Email obligatoires !", this.AlertMessage)
              this.animation = "animate-wrong";
              setTimeout(() => {
                this.animation = "";
              }, 400);
            }
          } else {
            this.$message.error("Profession et Adresse obligatoires !", this.AlertMessage)
          }
        } else {
          this.$message.error("Date et Lieu de naissance obligatoires !", this.AlertMessage)
        }
      } else {
        this.$message.error("Nom et Prénom obligatoires !", this.AlertMessage)
      }
    },

    BenefControl() {
      if (this.BenefiFields.Nom && this.BenefiFields.Prenom) {
        if (
            this.BenefiFields.dateNaissance &&
            this.BenefiFields.LieuNaissance
        ) {
          if (this.BenefiFields.Filiation) {
            this.e1 = 5;
            this.nextStep4();
            this.bottomPushNotif = false;
          } else {
            this.$message.error("Filiation obligatoires !", this.AlertMessage)
          }
        } else {
          this.$message.error("Date et Lieu de naissance obligatoires !", this.AlertMessage)
        }
      } else {
        this.$message.error("Nom et Prénom obligatoires !", this.AlertMessage)
      }
    },

    /*On check le numero de la question dans le tableau avant de pusher*/
    CheckValue(numero) { 
      let checker = 0;
      for (let v of this.QuestionnaireTab) {
        if (v.num === numero) {
          checker = 1;
        }
      }
      return checker;
    },

    BackToBenefForm() {
      this.animation = "animate-out-back";
      setTimeout(() => {
        this.animation = "animate-in-back";
        this.activeStep -= 1;
        this.e1 = 4;
        (this.isContractant = false),
            (this.isAssure = false),
            (this.isBenef = true);
      }, 500);
    },

    QuestionControlSuiteBack() {
      this.QuestionNext.Q = true;
      this.first_page = false;
      this.second_page = false;
      this.thirst_page = false;
      this.fourt_page = false;
      this.five_page = false;
    },

    QuestionControlSuiteBack1() {
      this.first_page = false;
      this.second_page = true;
      this.thirst_page = false;
      this.fourt_page = false;
      this.five_page = false;
    },

    QuestionControlSuiteBack2() {
      this.first_page = false;
      this.second_page = false;
      this.thirst_page = true;
      this.fourt_page = false;
      this.five_page = false;
    },

    QuestionControlSuiteBack3() {
      this.first_page = false;
      this.second_page = false;
      this.thirst_page = false;
      this.fourt_page = true;
      this.five_page = false;
    },

    QuestionControlSuiteBack4() {
      this.QuestionNext.Q4 = true;
      this.QuestionNext.Q5 = false;
    },

    QuestionControlSuiteBack5() {
      this.QuestionNext.Q5 = true;
      this.QuestionNext.Q6 = false;
    },

    QuestionControlSuiteBack6() {
      this.QuestionNext.Q6 = true;
      this.QuestionNext.Q7 = false;
    },

    validField() {
      this.e1 = 6;
      this.nextStep4();
      this.bottomPushNotif = false;
    },

    getListePays: function () {
      Api().get('/pays')
          .then(response => {
            this.listpays = response.data
          })
          .catch(err => {
            err
          })
    },


    remplissage_passager() {
      this.popupListePassager = true
    },


    /*Passenger Age calculation based on passenger birthday*/
    PassengerAgeCalculation() {
      let TodayDate = new Date()
      this.age = dayjs(TodayDate).diff(this.datenaissancePassager, 'year')

      let PassengerObject = {
        birthday: this.PassengerBirthday,
        age: this.age
      }
      localStorage.setItem('passenger-birthady-age', JSON.stringify(PassengerObject))
      return PassengerObject.age
    },

    DurationCalculation() {
      this.dateEffet = this.dateDepart
      this.dateEcheance = this.dateArrivee
      let date_tmp =  dayjs(this.dateArrivee).diff(this.dateDepart, 'day')
      this.duree = date_tmp + 1  //dayjs(this.dateArrivee).diff(this.dateDepart, 'day')
    },

    /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
    onlyNumber($event) {
      let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
      if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
        $event.preventDefault();
      }
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },
    errorNotif(){
      this.$notify({
        title: 'Error',
        message: 'Numéro obligatoire !',
        position: 'top-right',
        type: 'error'
      });
    },
    errorNotifCreateUser(){
      this.$notify({
        title: 'Error',
        message: 'Tout les Champs sont obligatoires !',
        position: 'top-right',
        type: 'error'
      });
    },
    successNotifCreateUser(){
      this.$notify({
        title: 'Success',
        message: 'Utilisateur crée avec succés !',
        position: 'top-right',
        type: 'succes'
      });
    },
    check_client_search(){
      if(this.clientsearch == 'téléphone'){
        this.NumberInput = true
        this.EmailInput = false
        this.emailclient = null
        this.clientOk = false
        this.clientnonOk = false
      } else if(this.clientsearch == 'email'){
        this.NumberInput = false
        this.EmailInput = true
        this.telephoneclient = null
        this.clientOk = false
        this.clientnonOk = false
      }
    },
    isMobile: function () {
      if (screen.width <= 760) {
        return true
      }else {
        return false
      }
    },


   rechercherClient () {
      if(this.telephoneclient){
        const validPhone = validatePhone(this.telephoneclient);
        this.errors.phone = validPhone.error;

        if(validPhone.valid == true){
          Api().get('/user/phone/' + this.telephoneclient)
              .then(resultat => {
                if (resultat.data.telephone_port === this.telephoneclient) {
                  this.clientOk = true
                  this.clientnonOk = false
                  this.usersearch = resultat.data;
                  this.$store.dispatch('setClient', resultat.data)
                }

              })
              .catch(e => {
                this.clientnonOk = true
                this.clientOk = false
                this.telephoneC = this.telephoneclient;
              })
        } else {
          this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
        }
      }else {
        this.$message.error('Numéro de téléphone obligatoire !', this.AlertMessage)
      }


    },

    rechercheClientByMail(){
      if(this.emailclient){

        const validEmail = validateEmail(this.emailclient);
        this.errors.email = validEmail.error;

        if(validEmail.valid == true){
          Api().get('/user/email/' +this.emailclient)
              .then(resultat =>{
                if (resultat.data.email === this.emailclient) {
                  this.clientOk = true
                  this.clientnonOk = false
                  this.usersearch = resultat.data;
                  this.$store.dispatch('setClient', resultat.data)
                }
              }).catch(e => {
            this.clientnonOk = true
            this.clientOk = false
            this.emailC = this.emailclient;
          })
        } else {
          this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
        }
      }else {
        this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)

      }
    },

    yearsDiff() {
      const date1 = dayjs(this.dateCircule);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, 'days');
      this.age = daydiff

      if (this.age <= 29) {
        this.an = this.annee_mois = 'jour(s)'
      } else if (this.age >= 30, this.age <= 360) {
        let year = Math.floor(this.age / 30)
        this.age = year;
        this.an = this.annee_mois = 'mois'
      } else if (this.age >= 360) {
        let year = Math.floor(this.age / 360)
        this.age = year;
        this.an = this.annee_mois = 'an(s)'
      }

    },

    showidclient() {
      this.showId = false
      this.showconfirmclient = true
      this.shownameclient = false

    },

    hidesearch() {
      this.hidesearchClient = false
      this.e1 = 1
    },

    rechercherbyphone() {
      if (this.usersearch.telephone_port === this.telephoneclient) {
        this.clientOk = true
        this.clientnonOk = false

      }
      if (this.usersearch.telephone_port !== this.telephoneclient) {
        this.clientnonOk = true
        this.clientOk = false
        this.telephoneC = this.telephoneclient;

      }
    },

    onReset(evt) {
      evt.preventDefault();
      this.user.email = '';
      this.user.name = '';
      this.user.food = null;
      this.user.checked = [];
      this.show = false;
      this.$nextTick(() => {
        this.show = true
      });
    },


    //liste de touts les partenaires
    getListePartenaire: function ()
    {
      if (this.$store.state.user.role == 'Agent')
      {
        Api().post('/branchecourtier/get/partenaire/',{userid:this.$store.state.user._id, branche:"Automobile"})
            .then(response => {
              this.listeAssureurs = response.data.partenaires[0].assureurs
            }).catch(err => {})
      } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin')
      {
        Api().get('/partenaire/assureur')
            .then(response => {
              this.listeAssureurs = response.data.partenaires
            })
      } else if (this.$store.state.user.role == 'assureur')
      {
        Api().get('/partenaire/' + this.$store.state.user.societe)
            .then(response => {
              this.listeAssureurs = response.data.partenaire
            })
      }

    },

    HubspotSend(param)
    {
      let data = {
        "email": param.email,
        "firstname": param.prenom,
        "lastname": param.nom,
        "phone": param.telephone_port,
        "inscrit": "Dash"
      };
      Api().post('/testimonial/hubspot', data)
          .then(resp => {
            (resp.data)
          })
          .catch(e => {
            (e)
          })
    },

    MailChimpInscriptionCompte(param)
    {
      let data = {
        "email": param.email,
        "FNAME": param.prenom,
        "LNAME": param.nom,
        "PHONE": param.telephone_port,
      };
      Api().post('/testimonial/mailchimp', data)
          .then(resp => {
            (resp.data)
          })
          .catch(e => {
            (e)
          })
    },
    // Creation d'un user avant de faire son devis
    createuser: function () {

      this.generatePassword()
      if (this.nom && this.prenom && this.emailclient && this.genre && this.telephoneclient)
      {
        const user = {
          nom: this.nom,
          prenom: this.prenom,
          datenaissance: this.datenaissance,
          profession: this.profession,
          adresse: this.adresse,
          sexe: this.genre,
          telephone_port: this.telephoneclient,
          email: this.emailclient,
          password: this.password1,
          confirmPass: this.password1,
          role: this.userRole,
          createdby:store.state.user._id,
          photo: this.file,
          piece_identite: this.piece_identite,
          numero_piece: this.numero_piece,
           plateforme : 'Dash',
          created: dayjs(new Date()).format('YYYY-MM-DD')
        }
        Api().post('user/register', user)
            .then(resultat => {
               this.HubspotSend(user)
               this.MailChimpInscriptionCompte(user)
              if (resultat.status == 200) 
              {
                if(resultat.data.numero){
                  this.$message.error(resultat.data.msg, this.AlertMessage)
                }else{
                  this.$store.dispatch('setClient', resultat.data.data)
                  this.usersearch = resultat.data.data
                  this.AddUser = false
                  this.clientnonOk = false
                  this.hidesearchClient = false
                  this.e1 = 1
                }
              } else {
                this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
              }

            })
            .catch(err => {
              this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
            })

      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }

    },

    /*================================================================================================================================*/
    /*================================================================================================================================*/
    /*==========CETTE PARTIE EST SPECIALEMENT POUR UNE SERIE DE FONCTION PERMETTANT DE VALIDER LES MODELS POUR CHAQUE STEPPER=========*/
    //Validation des elements du Stepper 1
    Stepper1Validator() {
      if (this.destination) {
        this.e1 = 2
      } else {
        this.$message.error('Pays de destination obligatoire !', this.AlertMessage)
      }
    },

    //Validation des ellements du Stepper 2
    Stepper2Validator() {
      if (this.dateDepart) {
        if(this.dateArrivee){
          if(this.nbpassager){
            if(this.remboursable){
              this.e1 = 3
              this.dateEffet = this.dateDepart
            }else {
              this.$message.error('Choix remboursement obligatoire !', this.AlertMessage)
            }
          }else {
            this.$message.error('Nombre de passager obligatoire !', this.AlertMessage)
          }
        }else {
          this.$message.error("Date d'arrivée obligatoire !", this.AlertMessage)
        }
      } else {
        this.$message.error('Date de départ obligatoire !', this.AlertMessage)
      }
    },

    //Validation des elements du Stepper 3
    Stepper3Validator() {
      if (this.categorie !== '2_roues') {
        if (this.marque) {
          if (this.model) {
            this.e1 = 4
          } else {
            this.$message.error('Veuillez choisir le modèle du véhicule !', this.AlertMessage)
          }
        } else {
          this.$message.error('Veuillez choisir la marque du véhicule !', this.AlertMessage)
        }
      } else {
        if (this.marque) {
          if (this.model) {
            this.e1 = 5
          } else {
            this.$message.error('Veuillez choisir le modèle du véhicule !', this.AlertMessage)
          }
        } else {
          this.$message.error('Veuillez choisir la marque du véhicule !', this.AlertMessage)
        }
      }

    },

    testVar:function(val){
      if (val!=null)
      {
        return Number(val.replace(/[^0-9.-]+/g, ""));
      } else {
        return "0";
      }
    },



    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700)
  },
  created() {
        this.init()
    },
  watch : {
    usersearch(val)
    {
      if(val!=null){
        this.dateNaiss = val.datenaissance
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
