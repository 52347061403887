export default class Contractant {
    constructor() {
        this.Nom =  null
        this.Prenom = null
        this.dateNaissance = null
        this.LieuNaissance = null
        this.Filiation= ""       
        this.Profession = null
        this.Adresse = null
        this.Telephone = null
        this.Email = null
        this.LieuExe = null
        this.Nationalite = null
        this.Genre = null
        this.Cni = null
    }
}
