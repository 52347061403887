<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Modification du devis <span style="color:#ffa409">Habitation</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="__resizeHanlder"/>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div style="margin-top:15px">
        <h3>Validation :</h3>
        <el-table :data="ForUpdate" style="width: 100%">
            <el-table-column
            :fixed="!isMobile"
            prop="typeBien"
            label="Type de Bien"
            width="150">
            </el-table-column>
            <el-table-column
            prop="nombrepiece"
            label="Nombre de pièce"
            width="150">
            </el-table-column>
            <el-table-column
            prop="nombreetage"
            label="Nombre d'étages"
            width="150">
            </el-table-column>
            <el-table-column
            prop="naturecouverture"
            label="Type de toiture"
            width="150">
            </el-table-column>
            <el-table-column
            prop="adresselogement"
            label="Adresse du logement"
            width="250">
            </el-table-column>
            <el-table-column
            prop="environnement_immediat"
            label="Environnement immédiat"
            width="250">
            </el-table-column>
            <el-table-column
            prop="valeurcontenu"
            label="Valeur du contenu"
            width="150">
             <template slot-scope ="scope">
                <span class="date">{{scope.row.valeurcontenu.toLocaleString()}}</span>
            </template>
            </el-table-column>
            <el-table-column
            prop="valeurbatiment"
            label="Valeur du bâtiment"
            width="150">
             <template slot-scope ="scope">
                <span class="date">{{scope.row.valeurbatiment.toLocaleString()}}</span>
            </template>
            </el-table-column>
        </el-table>

        <div>
            <h3>Liste des assureurs</h3>
            <el-row :gutter="20" type="flex" justify="center">
                <el-col :span="24">
                    <div class="grid-content bg-purple-dark">
                        <el-checkbox-group v-model="assureurs">
                            <el-checkbox v-for="(assur,index) in listeAssureurs" :key="index" :label="assur.societe">
                                <template>
                                    <img width="50" height="40" :src="assur.logo">
                                    <h3>{{assur.societe.substr(0, 5)}}</h3>
                                </template>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-col>
            </el-row>
        </div>


         <div>
            <h3>Période de couverture</h3>
            <el-row :gutter="20" v-if="!isMobile">
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <label>Durée de la garantie</label>
                        <el-input readOnly @keypress="onlyNumber" placeholder="durée de la garantie" v-model="duree" @change="dateDiff()">
                        <template slot="append">mois</template>
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <label>Date d'effet</label>
                        <el-date-picker
                            style="width: 100%!important;"
                            @change="dateDiff()"
                            v-model="dateEffet"
                            type="date"
                            placeholder="Date d'effet"
                            :picker-options="dateAfterToday">
                        </el-date-picker>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <label>Date d'échéance</label>
                        <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                        </el-input>
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="20" v-if="isMobile">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Durée de la garantie</label>
                        <el-input readOnly @keypress="onlyNumber" placeholder="durée de la garantie" v-model="duree" @change="dateDiff()">
                        <template slot="append">mois</template>
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <label>Date d'effet</label>
                        <el-date-picker
                            style="width: 100%!important;"
                            @change="dateDiff()"
                            v-model="dateEffet"
                            type="date"
                            placeholder="Date d'effet"
                            :picker-options="dateAfterToday">
                        </el-date-picker>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <label>Date d'échéance</label>
                        <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                        </el-input>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div style="margin-top:15px">
          <el-button style="float:left" @click="ListHabitationCreate()" type="primary" round>Valider</el-button>
        </div>
      </div>
    </div>

    <el-dialog width="70%!important" persistent title="Formulaire de modification" :visible.sync="showHabitation" v-if="showHabitation==true && !isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="8">
              <div>
                <label>Le bien à assurer</label>
                 <el-col>
                    <div class="grid-content bg-purple">
                        <el-radio v-model="ForUpdate[0].typeBien" size="medium" label="appartement">Appartement</el-radio>
                    </div>
                </el-col>
                <el-col>
                    <div class="grid-content bg-purple-light">
                        <el-radio v-model="ForUpdate[0].typeBien" label="maison">Maison</el-radio>
                    </div>
                </el-col>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Nombre de pièces</label>
                <el-input type="number" v-model="ForUpdate[0].nombrepiece" placeholder="Nombre de pièces" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div v-if="ForUpdate[0].typeBien === 'appartement'">
                <label>Superficie (en m2)</label>
                <el-input type="number" v-model="ForUpdate[0].nombreetage" placeholder="Superficie" class="input-with-select"></el-input>
              </div>

              <div v-if="ForUpdate[0].typeBien === 'maison'">
                <label>Superficie (en m2)</label>
                <el-input type="number" v-model="ForUpdate[0].nombreetage" placeholder="Superficie" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-top: 15px">
            <el-col :span="8">
              <div>
                <label>Type de toiture</label>
                 <el-select style="width: 100%!important;" v-model="ForUpdate[0].naturecouverture" placeholder="Selectionner">
                  <el-option v-for="(items, index) in optionsenvi" :key="index" :value="items.text">{{items.text}}</el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Environnement immédiat</label>
                <el-select style="width: 100%!important;" v-model="ForUpdate[0].environnement_immediat" placeholder="Selectionner">
                  <el-option v-for="(items, index) in optionsenvi" :key="index" :value="items.text">{{items.text}}</el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Adresse du logement</label>
                <el-input v-model="ForUpdate[0].adresselogement" placeholder="Adresse du logement" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

           <el-row :gutter="15" style="margin-top: 15px">
            <el-col :span="8">
              <div>
                <label>Qualité assurée / Proposant</label>
                 <el-col>
                    <div class="grid-content bg-purple">
                        <el-radio v-model="ForUpdate[0].qualiteassure" size="medium" label="locataire">Locataire</el-radio>
                    </div>
                </el-col>
                <el-col>
                    <div class="grid-content bg-purple-light">
                        <el-radio v-model="ForUpdate[0].qualiteassure" label="Proprietaire_et_occupant">Propriétaire et occupant</el-radio>
                    </div>
                </el-col>
                <el-col>
                    <div class="grid-content bg-purple-light">
                        <el-radio v-model="ForUpdate[0].qualiteassure" label="Proprietaire_non_occupant">Propriétaire non occupant</el-radio>
                    </div>
                </el-col>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Valeur du contenu</label>
                <el-input v-model="ForUpdate[0].valeurcontenu" placeholder="Valeur du contenu" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Valeur du bâtiment</label>
                <el-input v-model="ForUpdate[0].valeurbatiment" placeholder="Valeur du batiment" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-top: 15px">
            <el-col :span="24"  v-if="ForUpdate[0].qualiteassure === 'locataire'">
              <div>
                <label>Montant du loyer</label>
                <el-input @keypress="calculeValeurBatiment()" type="number" v-model="ForUpdate[0].montaLoyer" placeholder="Nombre de place" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>
          <hr>
          <el-row :gutter="15" style="margin-top:15px">
            <el-col :span="24">
              <div>
                <label>Garanties souscrites</label>
                <el-checkbox-group v-model="garanties">
                    <el-checkbox label="BRIS DE GLACES">Bris de glace</el-checkbox>
                    <el-checkbox label="RESPONSABILITE CIVILE">Responsabilité Civile</el-checkbox>
                    <el-checkbox label="INCENDIE" @change="AvRec = true">Incendie</el-checkbox>
                    <el-checkbox label="ASSISTANCE DOMICILE">Assistance à domicile</el-checkbox>
                    <el-checkbox label="DOMMAGES ELECTRIQUES">Dommages électriques</el-checkbox>
                    <el-checkbox label="DEGAT DES EAUX">Dégat des eaux</el-checkbox>
                    <el-checkbox label="VOL">Vol</el-checkbox>
                </el-checkbox-group>

                 <label style="padding-top:50px">Autres garanties</label>
                <el-checkbox-group v-model="garanties">
                    <el-checkbox label="REMPLACEMENT SERRURES">Remplacement serrures</el-checkbox>
                    <el-checkbox label="RESPONSABILITE CIVILE VOYAGE">RC voyage</el-checkbox>
                    <el-checkbox label="RESPONSABILITE CIVILE ANIMAUX">RC animaux</el-checkbox>
                    <el-checkbox label="RESPONSABILITE CIVILE SPORT">RC sport</el-checkbox>
                    <el-checkbox label="RESPONSABILITE CIVILE CHASSE">RC chasse</el-checkbox>
                    <el-checkbox @change="chech_scolarite()" label="ASSURANCE SCOLARITE">Assurance scolarite</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-col>
           </el-row>
        </el-card>

               <div class="mb-5" style="margin-top: 15px">
                  <el-button type="primary" round @click="fieldsValidation()">Modifier</el-button>
                </div>
      </div>

    </el-dialog>

     <el-dialog width="90%!important" persistent title="Formulaire de modification" :visible.sync="showHabitation" v-if="showHabitation==true && isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="24">
              <div>
                <label>Le bien à assurer</label>
                 <el-col>
                    <div class="grid-content bg-purple">
                        <el-radio v-model="ForUpdate[0].typeBien" size="medium" label="appartement">Appartement</el-radio>
                    </div>
                </el-col>
                <el-col>
                    <div class="grid-content bg-purple-light">
                        <el-radio v-model="ForUpdate[0].typeBien" label="maison">Maison</el-radio>
                    </div>
                </el-col>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Nombre de pièces</label>
                <el-input type="number" v-model="ForUpdate[0].nombrepiece" placeholder="Nombre de place" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div v-if="ForUpdate[0].typeBien === 'appartement'">
                <label>Superficie (en m2)</label>
                <el-input type="number" v-model="ForUpdate[0].nombreetage" placeholder="Nombre de place" class="input-with-select"></el-input>
              </div>

              <div v-if="ForUpdate[0].typeBien === 'maison'">
                <label>Superficie (en m2)</label>
                <el-input type="number" v-model="ForUpdate[0].nombreetage" placeholder="Nombre de place" class="input-with-select"></el-input>
              </div>
            </el-col>
             <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Type de toiture</label>
                 <el-select style="width: 100%!important;" v-model="ForUpdate[0].naturecouverture" placeholder="Selectionner">
                  <el-option v-for="(items, index) in optionsenvi" :key="index" :value="items.text">{{items.text}}</el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Environnement immédiat</label>
                <el-select style="width: 100%!important;" v-model="ForUpdate[0].environnement_immediat" placeholder="Selectionner">
                  <el-option v-for="(items, index) in optionsenvi" :key="index" :value="items.text">{{items.text}}</el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Adresse du logement</label>
                <el-input type="number" v-model="ForUpdate[0].adresselogement" placeholder="Nombre de place" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Qualité assurée / Proposant</label>
                 <el-col>
                    <div class="grid-content bg-purple">
                        <el-radio v-model="ForUpdate[0].qualiteassure" size="medium" label="locataire">Locataire</el-radio>
                    </div>
                </el-col>
                <el-col>
                    <div class="grid-content bg-purple-light">
                        <el-radio v-model="ForUpdate[0].qualiteassure" label="Proprietaire_et_occupant">Propriétaire et occupant</el-radio>
                    </div>
                </el-col>
                <el-col>
                    <div class="grid-content bg-purple-light">
                        <el-radio v-model="ForUpdate[0].qualiteassure" label="Proprietaire_non_occupant">Propriétaire non occupant</el-radio>
                    </div>
                </el-col>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Valeur du contenu</label>
                <el-input type="number" v-model="ForUpdate[0].valeurcontenu" placeholder="Nombre de place" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Valeur du bâtiment</label>
                <el-input type="number" v-model="ForUpdate[0].valeurbatiment" placeholder="Nombre de place" class="input-with-select"></el-input>
              </div>
            </el-col>
              <el-col :span="24"  v-if="ForUpdate[0].qualiteassure === 'locataire'">
              <div>
                <label>Montant du loyer</label>
                <el-input @keypress="calculeValeurBatiment()" type="number" v-model="ForUpdate[0].montaLoyer" placeholder="Nombre de place" class="input-with-select"></el-input>
              </div>
            </el-col>
              <el-col :span="24">
              <div>
                <label>Garanties souscrites</label>
                <el-checkbox-group v-model="garanties">
                    <el-checkbox label="BRIS DE GLACES">Bris de glace</el-checkbox>
                    <el-checkbox label="RESPONSABILITE CIVILE">Responsabilité Civile</el-checkbox>
                    <el-checkbox label="INCENDIE" @change="AvRec = true">Incendie</el-checkbox>
                    <el-checkbox label="ASSISTANCE DOMICILE">Assistance à domicile</el-checkbox>
                    <el-checkbox label="DOMMAGES ELECTRIQUES">Dommages électriques</el-checkbox>
                    <el-checkbox label="DEGAT DES EAUX">Dégat des eaux</el-checkbox>
                    <el-checkbox label="VOL">Vol</el-checkbox>
                </el-checkbox-group>

                 <label style="padding-top:50px">Autres garanties</label>
                <el-checkbox-group v-model="garanties">
                    <el-checkbox label="REMPLACEMENT SERRURES">Remplacement serrures</el-checkbox>
                    <el-checkbox label="RESPONSABILITE CIVILE VOYAGE">RC voyage</el-checkbox>
                    <el-checkbox label="RESPONSABILITE CIVILE ANIMAUX">RC animaux</el-checkbox>
                    <el-checkbox label="RESPONSABILITE CIVILE SPORT">RC sport</el-checkbox>
                    <el-checkbox label="RESPONSABILITE CIVILE CHASSE">RC chasse</el-checkbox>
                    <el-checkbox @change="chech_scolarite()" label="ASSURANCE SCOLARITE">Assurance scolarite</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="fieldsValidation()">Modifier</el-button>
        </div>
      </div>

    </el-dialog>

      <!-- ========= DIALOG NOMBRE D'ENFANT ============ -->
            <!-- ========= DIALOG NOMBRE D'ENFANT ============ -->
            <el-dialog show-close="false" title="Nombre d'enfant(s)" :visible.sync="modal_nombre_enfant">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
                <el-row justify="center">
                   <el-col :span="24">
                        <el-form-item label="Nombre d'enfant(s)">
                            <el-input type="number" placeholder="Saisir nombre d'enfant(s)" v-model="nombre_enfant"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>   
            </el-form>
            <div>
                 <el-button @click="nombre_enfant_validator()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import MarqueVehicule from '../../../common/marqueauto'
import Marque2roues from '../../../common/2roues'
import _ from 'underscore'
//fin importation assuraf

export default {
  name: "creer_assureur",
  data() {
    return {
      nombre_enfant : null,
          modal_nombre_enfant : false,
      isMobile : false,
        dateBeforeToday: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
        dateAfterToday: {
          disabledDate(time) {
            return time.getTime() <  (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
          }
        },
        showHabitation : true,
      ToastTabSucess: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'success',
                className: 'custom-success-class'
            }, // Customized options for success toast
            ToastTabError: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'error'
            }, // Customized options for error toast
            optionsdate: {
                minDate: new Date(),
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            showId: false,
            duree: 12,
            assureurs: [],
            dateEcheance: '',
            ForUpdate: [],
            dateEffet: null,
            notifMessage: '',
            listeAssureurs: [],
            liste_type_secu: [],
            garanties: [],
            items_toiture: ['Tuile (ardoise)', 'Tôle metal', 'paille', ],
            autredommage: [],
            items_environ_immediat: ['Chantiers a proximité', 'Proche Usine/Marche/Station Essence'],
            HeaderTableauHabitat: [{
                    key: 'typeBien',
                    label: 'Type Bien'
                },
                {
                    key: 'nombrepiece',
                    label: 'Nombre de piece'
                },
                {
                    key: 'nombreetage',
                    label: 'Nombre etage'
                },
                {
                    key: 'naturecouverture',
                    label: 'Type de toiture'
                },
                {
                    key: 'adresselogement',
                    label: 'Adresse logement'
                },
                {
                    key: 'environnement_immediat',
                    label: 'Environnement immediat'
                },
                {
                    key: 'valeurcontenu',
                    label: 'Valeur contenu'
                },
                {
                    key: 'valeurbatiment',
                    label: 'Valeur batiment'
                },

            ],
            optionstoiture: [{
                    text: 'Béton '
                },
                {
                    text: 'Tuile (ardoise)'
                },
                {
                    text: 'Tôle metal'
                },
                {
                    text: 'paille'
                },
            ],
            optionsenvi: [{
                    text: 'Quartier résidentiel '
                },
                {
                    text: 'Chantiers a proximité'
                },
                {
                    text: 'Proche Usine/Marche/Station Essence'
                },
            ],




      voidField: false,

      Deb: null,
      selectedcarbu: '',

      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: []
    }
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
        this.ForUpdate.push(store.state.DevisReopen)
        this.getListePartenaire()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    this.initChart()
    this.initPie()
    window.addEventListener("resize", this.__resizeHanlder)
  },
    watch: {
    marque(val) {
      this.onChangeSelectedMarque(val);
      this.onChangeSelectedMarque2roues(val);
    },
  },

  methods: {
    chech_scolarite()
        {
            let trouver = 0;
            for (let index = 0; index < this.garanties.length; index++) {
                if (this.garanties[index] == 'ASSURANCE SCOLARITE') {
                    trouver = 1
                }
            }
            if (trouver == 1) {
                this.modal_nombre_enfant = true
            } else {
                this.garanties.pop();
                this.modal_nombre_enfant = false
            }
        },
        nombre_enfant_validator()
        {
            let trouver = 0;
            for (let index = 0; index < this.garanties.length; index++)
            {
                if (this.garanties[index] == 'ASSURANCE SCOLARITE')
                {
                    trouver = 1
                }
            }

            if (trouver == 1 && !this.nombre_enfant || this.nombre_enfant <=0 )
            {
                this.garanties.pop();
                this.modal_nombre_enfant = false
            } else {
                this.modal_nombre_enfant = false
            }
        },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
     /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
        onlyNumber($event) {
            let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
            if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
                $event.preventDefault();
            }
        },

      fieldsValidation() {
    if (this.ForUpdate[0].typeBien && this.ForUpdate[0].nombrepiece && this.ForUpdate[0].nombreetage && this.ForUpdate[0].naturecouverture &&  this.ForUpdate[0].environnement_immediat && this.ForUpdate[0].adresselogement && this.ForUpdate[0].qualiteassure && this.ForUpdate[0].valeurcontenu && this.ForUpdate[0].valeurbatiment) {
          if(this.garanties.length >= 1){
            this.showHabitation = false;
          }else {
            this.$message.error('Choisissez au minimum une garantie !', this.AlertMessage)
          }
        } else {
            this.$message.error('Tout les champs sont obligatoires !', this.AlertMessage)
      } 
    },

     DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },
    
    //calcul de la valeur du batiment a partir du montant du loyer si c'est locataire
        calculeValeurBatiment() {
            if(this.ForUpdate[0].qualiteassure === 'locataire'){
                let VB = this.ForUpdate[0].montaLoyer
                let fac = 180
                let Total = VB * fac
                this.ForUpdate[0].valeurbatiment = Total
            }
        },

            dateDiff: function () {
            if (this.dateEffet === '' & this.duree === '') this.dateEcheance = ''
            let inter = dayjs(this.dateEffet).add(this.duree, 'month').format('YYYY-MM-DD')
            this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
        },

        RoundToMillion(num) {
            var len = (num + '').length;
            var fac = Math.pow(10, len - 1);
            return Math.ceil(num / fac) * fac;
        },

        ListHabitationCreate: function () {
            if (this.duree && this.dateEffet) {
                //envoie vers l ' API
                if (this.assureurs.length > 0) {
                    const Habitation = {
                        userid: this.ForUpdate[0].userid,
                        typeBien: this.ForUpdate[0].typeBien,
                        nombrepiece: this.ForUpdate[0].nombrepiece,
                        nombreetage: this.ForUpdate[0].nombreetage,
                        superficie: this.ForUpdate[0].nombreetage,
                        nombreniveau: this.ForUpdate[0].nombreniveau,
                        naturecouverture: this.ForUpdate[0].naturecouverture,
                        adresselogement: this.ForUpdate[0].adresselogement,
                        valeurcontenu: this.RoundToMillion(this.ForUpdate[0].valeurcontenu),
                        valeurbatiment: this.RoundToMillion(this.ForUpdate[0].valeurbatiment),
                        qualiteassure: this.ForUpdate[0].qualiteassure,
                        montaLoyer: this.ForUpdate[0].montaLoyer,
                        environnement_immediat: this.ForUpdate[0].environnement_immediat,
                        assureurs: this.assureurs,
                        garanties: this.garanties,
                        createur: this.$store.state.user._id,
                        proprietaire: this.ForUpdate[0].proprietaire,
                        branche: 'Habitation',
                        dateEffet: this.dateEffet,
                        dateEcheance: this.dateEcheance,
                        dateSouscription: dayjs(new Date()).format('YYYY-MM-DD')

                    }
                    if (this.$store.state.user.role == 'Agent' && !this.$store.state.user.assureur) {
                        Habitation.courtierid = this.$store.state.user.societe
                    }

                    Api().post('devis/add', Habitation)
                        .then(reslutat => {
                            this.$store.dispatch("setDevis", reslutat.data)
                            this.$router.go(this.$router.push('/paiement/liste'))
                        })
                } else {
                    this.$message.error('Choisissez au moins un assureur !', this.AlertMessage)
                }

            } else {
                this.$message.error("La durée du contrat et la date d'effet sont obligatoires !", this.AlertMessage)
            }

        },

        getListePartenaire: function () {

            if (this.$store.state.user.role == 'Agent' && !this.$store.state.user.assureur) {
              Api().post("/branchecourtier/get/partenaire", { userid: this.$store.state.user._id, branche: "Habitation" })
              .then((response) => { this.listeAssureurs = response.data.partenaires[0].assureurs })
              .catch((err) => { });
            } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin') {
                Api().get('/partenaire/partners/habitation')
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires //.assureurs_partenaires
                    })
            } else if (this.$store.state.user.role == 'assureur') {
                Api().get('/partenaire/' + this.$store.state.user.societe)
                    .then(response => {
                        this.listeAssureurs = response.data.partenaire
                    })
            }

        },


    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700),
    initChart() {
      this.chart = echarts.init(document.getElementById("chart"))
      this.chart.setOption({
        //backgroundColor: '#394056',
        title: {
          top: 20,
          text: "SALES STATISTICS",
          textStyle: { fontWeight: "normal", fontSize: 16, fontFamily: "Nunito Sans" /*color: '#F1F1F3'*/ },
          left: "1%"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              /*color: '#57617B'*/
            }
          }
        },
        legend: {
          top: 40,
          icon: "rect",
          itemWidth: 14,
          itemHeight: 5,
          itemGap: 13,
          data: ["Product-A", "Product-B", "Product-C"],
          right: "4%",
          textStyle: { fontSize: 12, fontFamily: "Nunito Sans" /*color: '#F1F1F3'*/ }
        },
        grid: {
          top: 100,
          left: "-5px",
          right: "30px",
          bottom: "2%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                /*color: '#57617B'*/
              }
            },
            data: [
              "13:00",
              "13:05",
              "13:10",
              "13:15",
              "13:20",
              "13:25",
              "13:30",
              "13:35",
              "13:40",
              "13:45",
              "13:50",
              "13:55"
            ]
          }
        ],
        yAxis: [
          {
            show: false,
            type: "value",
            name: "(%)",
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                /*color: '#57617B'*/
              }
            },
            axisLabel: {
              margin: 10,
              textStyle: { fontSize: 14 }
            },
            splitLine: { lineStyle: { color: "#eee" /*color: '#57617B'*/ } }
          }
        ],
        series: [
          {
            name: "Product-A",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: { normal: { width: 1 } },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(19, 206, 102, 0.3)"
                      },
                      {
                        offset: 0.8,
                        color: "rgba(19, 206, 102, 0)"
                      }
                    ],
                    false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: "rgb(19, 206, 102)",
                borderColor: "rgba(19, 206, 102, 0.27)",
                borderWidth: 12
              }
            },
            data: [220, 182, 191, 134, 150, 120, 110, 125, 145, 122, 165, 122]
          },
          {
            name: "Product-B",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: { normal: { width: 1 } },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(95, 143, 223, 0.3)"
                      },
                      {
                        offset: 0.8,
                        color: "rgba(95, 143, 223, 0)"
                      }
                    ],
                    false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: "rgb(95, 143, 223)",
                borderColor: "rgba(95, 143, 223, 0.2)",
                borderWidth: 12
              }
            },
            data: [120, 110, 125, 145, 122, 165, 122, 220, 182, 191, 134, 150]
          }
        ]
      })
    },
    initPie() {
      this.pie = echarts.init(document.getElementById("pie"))
      this.pie.setOption({
        title: {
          top: 20,
          text: "ORDER STATUS",
          textStyle: { fontWeight: "normal", fontSize: 16, fontFamily: "Nunito Sans" /*color: '#F1F1F3'*/ },
          left: "1%"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        series: [
          {
            name: "Status",
            type: "pie",
            selectedMode: "single",
            radius: [0, "35%"],

            label: {
              normal: {
                position: "inner"
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              {
                value: 335,
                name: "Orders",
                selected: true,
                itemStyle: { normal: { color: "rgb(95, 143, 223)" } }
              },
              { value: 679, name: "Pending", itemStyle: { normal: { color: "rgb(19, 206, 102)" } } },
              { value: 1548, name: "Delivered", itemStyle: { normal: { color: "rgb(247, 186, 43)" } } }
            ]
          },
          {
            name: "Products",
            type: "pie",
            radius: ["45%", "60%"],

            data: [
              { value: 335, name: "p1", itemStyle: { normal: { color: "#3f84f6" } } },
              { value: 310, name: "p2", itemStyle: { normal: { color: "#4c8bf7" } } },
              { value: 234, name: "p3", itemStyle: { normal: { color: "#5a95f7" } } },
              { value: 135, name: "p4", itemStyle: { normal: { color: "#70a3f8" } } },
              { value: 1048, name: "p5", itemStyle: { normal: { color: "#8ab4fa" } } },
              { value: 251, name: "p6", itemStyle: { normal: { color: "#a3c4fb" } } },
              { value: 147, name: "p7", itemStyle: { normal: { color: "#bfd6fc" } } },
              { value: 102, name: "p8", itemStyle: { normal: { color: "#d4e4fd" } } }
            ],

            itemStyle: {
              normal: {
                color: "rgb(19, 206, 102)"
              }
            }
          }
        ]
      })
    },
    initGridData() {
      const year = new Date().getFullYear()
      const status_list = ["Complete", "Pending", "Returned", "Paid"]

      _.times(10, number => {
        let price = chance.floating({ min: 1, max: 100, fixed: 2 })
        let qnt = chance.integer({ min: 1, max: 5 })
        let amount = price * qnt

        this.gridData.push({
          customer: chance.name(),
          photo: "/static/images/shop/" + chance.integer({ min: 0, max: 19 }) + ".jpg",
          city: chance.city(),
          address: chance.address(),
          email: chance.email(),
          product: chance.sentence({ words: 3 }),
          price: _.replace(price.toFixed(2).toString(), ".", ","),
          qnt,
          amount: _.replace(amount.toFixed(2).toString(), ".", ","),
          status: status_list[chance.integer({ min: 0, max: 3 })],
          date: chance.date({ string: true, year: year }),
          id: number
        })
      })
    }
  },
   created() {
        this.init()
    },
  computed : {
    sortOptions () {
      return this.listuser
          .filter(f => f.sortable)
          .map(f => { return { text: f.label, value: f.key } })
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
