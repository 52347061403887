<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
        <div class="page-header">
            <h1>Liste de tous les <span style="color:#ffa409">contrats partenaires</span>
            </h1>
        </div>
  
        <div class="toolbar-box flex align-center" v-if="!isMobile">
            <div class="box grow">
                <el-input
                        placeholder="Rechercher contrat partenaire..."
                        prefix-icon="el-icon-search"
                        v-model="search"
                        clearable
                >
                </el-input>
            </div>
            <div class="box grow">
                <!--        A ne pas supprimer-->
            </div>
        </div>
  
        <div v-if="isMobile" style="margin-bottom:15px">
            <el-row :gutter="15">
                <el-col :span="24" style="margin-top:15px">
                    <el-input
                            placeholder="Rechercher contrat partenaire..."
                            prefix-icon="el-icon-search"
                            v-model="search"
                            clearable>
                    </el-input>
                </el-col>
            </el-row>
        </div>
  
        <resize-observer @notify="handleResize" />
        <div class="mb-5">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>
  
        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
            <el-table
                    :data="listInPage"
                    style="width: 100%"
                    :height="height"
                    v-if="ready"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column fixed label="Date création" min-width="150" prop="dateSouscription">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Partenaire" min-width="200" prop="partner">
                    <template slot-scope ="scope">
                <span class="date">
                  <a href="#" @click="SetDetailPartner(scope.row.partner)">
                    {{scope.row.partner.societe}}
                  </a>
                </span>
                    </template>
                </el-table-column>
                <el-table-column label="Client" min-width="200" prop="client">
                    <template slot-scope ="scope">
                <span class="date">
                  <a href="#" @click="SetDetailUser(scope.row.userid)">
                    {{scope.row.userid.prenom }} - {{scope.row.userid.nom }}
                  </a>
                </span>
                    </template>
                </el-table-column>
                <el-table-column label="Branche" min-width="150" prop="brancheid.branche"></el-table-column>
                <el-table-column label="Numéro" min-width="250" prop="numero_police"></el-table-column>
                <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
                <el-table-column label="Plateforme" min-width="150" prop="devisid.plateforme">
                <template slot-scope ="scope">
                    <span class="date" style="color:#ffa409">{{ scope.row.devisid.plateforme }}</span>
                </template>
            </el-table-column>
                <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
                    <template slot-scope ="scope">
                        <span>{{scope.row.prime_ttc.toLocaleString()}} CFA</span>
                    </template>
                </el-table-column>
                <el-table-column label="Créateur" min-width="150" prop="creator">
                    <template slot-scope ="scope">
                        <span v-if="scope.row.createur" class="date">{{scope.row.createur.prenom}} - {{scope.row.createur.nom}}</span>
                        <span v-else>
              Admin
            </span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="detailcontrat(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
                        <el-button size="mini" v-loading.fullscreen.lock="modalgenerationloading" @click="downloadAttestationProvisoire(scope.row)" type="primary" icon="el-icon-download" circle title="Désarchiver"></el-button>
                    </template>
                </el-table-column>
            </el-table>
  
            <el-pagination
                    v-if="ready"
                    :small="pagination.small"
                    :current-page.sync="pagination.page"
                    :page-sizes="pagination.sizes"
                    :page-size.sync="pagination.size"
                    :layout="pagination.layout"
                    :total="total"
            ></el-pagination>
        </div>
  
        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
            <el-table
                    :data="listInPage"
                    style="width: 100%"
                    v-if="ready"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column label="Date création" min-width="150" prop="dateSouscription">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Partenaire" min-width="200" prop="partner">
                    <template slot-scope ="scope">
                <span class="date">
                  <a href="#" @click="SetDetailPartner(scope.row.partner)">
                    {{scope.row.partner.societe }}
                  </a>
                </span>
                    </template>
                </el-table-column>
                <el-table-column label="Client" min-width="200" prop="client">
                    <template slot-scope ="scope">
                <span class="date">
                  <a href="#" @click="SetDetailUser(scope.row.userid)">
                    {{scope.row.userid.prenom }} - {{scope.row.userid.nom }}
                  </a>
                </span>
                    </template>
                </el-table-column>
                <el-table-column label="Branche" min-width="180" prop="brancheid.branche"></el-table-column>
                <el-table-column label="Numéro" min-width="200" prop="numero_police"></el-table-column>
                <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
                <el-table-column label="Plateforme" min-width="150" prop="devisid.plateforme">
                <template slot-scope ="scope">
                    <span class="date" style="color:#ffa409">{{ scope.row.devis.plateforme }}</span>
                </template>
            </el-table-column>
                <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
                    <template slot-scope ="scope">
                        <span>{{scope.row.prime_ttc.toLocaleString()}} CFA</span>
                    </template>
                </el-table-column>
                <el-table-column label="Créateur" min-width="150" prop="creator">
                    <template slot-scope ="scope">
                        <span v-if="scope.row.createur" class="date">{{scope.row.createur.prenom}} - {{scope.row.createur.nom}}</span>
                        <span v-else>
              Admin
            </span>
                    </template>
                </el-table-column>
                <el-table-column label="Actions" prop="action" min-width="120">
                    <template slot-scope="scope">
                        <el-button @click="detailcontrat(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
                        <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="downloadAttestationProvisoire(scope.row)" type="primary" icon="el-icon-refresh-left" circle title="Désarchiver"></el-button>
                    </template>
                </el-table-column>
            </el-table>
  
            <el-pagination
                    v-if="ready"
                    :small="pagination.small"
                    :current-page.sync="pagination.page"
                    :page-sizes="pagination.sizes"
                    :page-size.sync="pagination.size"
                    :layout="pagination.layout"
                    :total="total"
            ></el-pagination>
        </div>
  
        <el-dialog width="70%!important" title="Détails contrat" :visible.sync="showcontrat" v-if="showcontrat==true && !isMobile">
            <div class="mb-5">
                <el-button type="primary" round @click="JsonFormaterView()">Détails en JSON</el-button>
            </div>
            <el-row :gutter="15">
                <el-col :span="12">
                <div class="grid-content bg-purple">
                    <el-card class="box-card">
                    <div  >
                        <ul style="list-style:none">
                        <li>Date de Souscription : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateSouscription)}}</span></li>
                        <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{detailsContrat.numero_police}}</span></li>
                        <li v-if="detailsContrat.brancheid.branche=='Voyage' && detailsContrat.increment_voyage">
                            Numéro Increment Voyage : <span class="to-right assuraf-orange">{{detailsContrat.increment_voyage}}</span></li>
                        <li>Numéro Police Assureur : <span class="to-right assuraf-orange">{{detailsContrat.police_assureur}}</span></li>
                        <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEffet)}}</span></li>
                        <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEcheance)}}</span></li>
                        <li>Prime Nette : <span class="to-right assuraf-orange">{{detailsContrat.prime_nette.toLocaleString()}}</span></li>
                        <li>Prime TTC : <span class="to-right assuraf-orange">{{detailsContrat.prime_ttc.toLocaleString()}}</span></li>
                        <li>Taxe : <span class="to-right assuraf-orange">{{detailsContrat.taxe.toLocaleString()}}</span></li>
                        <li v-if="detailsContrat.brancheid.branche=='Automobile' && CheckIfTypeDefined(detailsContrat.devisid) !=null">
                            FGA : <span class="to-right assuraf-orange" v-if="detailsContrat.devisid!=null && detailsContrat.devisid.fga!=null">
                                {{detailsContrat.devisid.fga.toLocaleString()}}
                                </span>
                        </li>
                        <li>Accessoires : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                        <li v-if="detailsContrat.partner_creator!=null">Partenaires : <span class="to-right assuraf-orange">{{detailsContrat.partner_creator}}</span></li>
                        </ul>
                    </div>
                    </el-card>
                </div>
                </el-col>
                <el-col :span="12">
                <div class="grid-content bg-purple">
                    <el-card class="box-card">
                    <div  >
                        <ul style="list-style:none">
                        <li>Prénom : <span class="to-right assuraf-orange">{{detailsContrat.userid.prenom.toUpperCase()}}</span></li>
                        <li>Nom : <span class="to-right assuraf-orange">{{detailsContrat.userid.nom.toUpperCase()}}</span></li>
                        <li>Téléphone : <span class="to-right assuraf-orange">{{detailsContrat.userid.telephone_port}}</span></li>
                        <li>Email : <span class="to-right assuraf-orange">{{detailsContrat.userid.email}}</span></li>
                        <li>Date inscription : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.userid.created)}}</span></li>
                        <li>
                            <img :src="detailsContrat.userid.photo" alt="" width="200">
                        </li>
                        </ul>
                    </div>
                    </el-card>
                </div>
                </el-col>
            </el-row>
            <el-row :gutter="15" v-if="JsonView" style="margin-top: 15px">
                <el-col :span="24">
                <div class="grid-content bg-purple">
                    <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>Détails en JSON </span>
                    </div>
                    <div>
                        <pre style="background:black;color:white">
                        {{detailsContrat}}
                        </pre>
                    </div>
                    </el-card>
                </div>
                </el-col>
            </el-row>
  
            <template v-if="detailsContrat.brancheid.branche == 'Automobile' && detailsContrat.details[0] != null">
                <el-table :data="detailsContrat.details[0].vehicules" class="padded-table" height="auto" style="width: 100%" row-key="id">
                <el-table-column prop="marque" label="Marque" min-width="150"> </el-table-column>
                <el-table-column prop="model" label="Modèle" min-width="100"> </el-table-column>
                <el-table-column prop="force" label="Puissance fiscale" min-width="200"> </el-table-column>
                <el-table-column prop="nbplace" label="Nombre de place" min-width="200"> </el-table-column>
                <el-table-column prop="valeur" label="Valeur à neuf" min-width="200"> </el-table-column>
                <el-table-column prop="valeur_venale" label="Valeur venale" min-width="200"> </el-table-column>
                <el-table-column prop="immatriculation" label="Immatriculation" min-width="200"> </el-table-column>
                <el-table-column prop="carburation" label="Carburation" min-width="200"> </el-table-column>
                </el-table>
                <div class="row mt-2" v-if="detailsContrat.user_on_gray_card">
                    <ul class="detailsSee">
                    <li>
                        Nom sur la carte grise :
                        <span class="font-weight-bold"
                        >{{ detailsContrat.user_on_gray_card.nom.toUpperCase() }}
                        </span>
                    </li>
                    <li>
                        Prénom sur la carte grise :
                        <span class="font-weight-bold"
                        >{{ detailsContrat.user_on_gray_card.prenom.toUpperCase() }}
                        </span>
                    </li>
                    </ul>
                </div>
            </template>
  
            <div class="mt-5">
                <el-button type="primary" v-if="detailsContrat.brancheid.branche=='Automobile'" round @click="detailAttestation()">Imprimer l'attestation</el-button>
                <el-button type="primary" v-if="!detailsContrat.police_assureur" round @click="modalNumPolice=true">Imprimer le contrat</el-button>
                <a v-else :href="detailsContrat.lien_contrat">Télécharger le contrat</a>
            </div>
        </el-dialog>
  
        <el-dialog width="90%!important" title="Détails contrat" :visible.sync="showcontrat" v-if="showcontrat==true && isMobile">
        <div class="mb-5">
            <el-button type="primary" round @click="JsonFormaterView()">Détails en JSON</el-button>
        </div>
  
        <el-row :gutter="15">
        <el-col :span="24" v-if="JsonView">
            <div class="grid-content bg-purple">
                <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>Détails en JSON </span>
                </div>
                <div>
                    <pre style="background:black;color:white">
                    {{detailsContrat}}
                    </pre>
                </div>
                </el-card>
            </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
                <el-card class="box-card">
                <div  >
                    <ul style="list-style:none">
                    <li>Date de Souscription : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateSouscription)}}</span></li>
                    <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{detailsContrat.numero_police}}</span></li>
                    <li v-if="detailsContrat.brancheid.branche=='Voyage' && detailsContrat.increment_voyage">
                        Numéro Increment Voyage : <span class="to-right assuraf-orange">{{detailsContrat.increment_voyage}}</span></li>
                    <li>Numéro Police Assureur : <span class="to-right assuraf-orange">{{detailsContrat.police_assureur}}</span></li>
                    <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEffet)}}</span></li>
                    <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEcheance)}}</span></li>
                    <li>Prime Nette : <span class="to-right assuraf-orange">{{detailsContrat.prime_nette.toLocaleString()}}</span></li>
                    <li>Prime TTC : <span class="to-right assuraf-orange">{{detailsContrat.prime_ttc.toLocaleString()}}</span></li>
                    <li>Taxe : <span class="to-right assuraf-orange">{{detailsContrat.taxe.toLocaleString()}}</span></li>
                    <li v-if="detailsContrat.brancheid.branche=='Automobile' && CheckIfTypeDefined(detailsContrat.devisid) !=null">
                        FGA : <span class="to-right assuraf-orange" v-if="detailsContrat.devisid!=null">{{detailsContrat.devisid.fga.toLocaleString()}}</span></li>
                    <li>Accessoires : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                    <li v-if="detailsContrat.partner_creator!=null">Partenaires : <span class="to-right assuraf-orange">{{detailsContrat.partner_creator}}</span></li>
                    </ul>
                </div>
                </el-card>
            </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
                <el-card class="box-card">
                <div  >
                    <ul style="list-style:none">
                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsContrat.userid.prenom.toUpperCase()}}</span></li>
                    <li>Nom : <span class="to-right assuraf-orange">{{detailsContrat.userid.nom.toUpperCase()}}</span></li>
                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsContrat.userid.telephone_port}}</span></li>
                    <li>Email : <span class="to-right assuraf-orange">{{detailsContrat.userid.email}}</span></li>
                    <li>Date inscription : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.userid.created)}}</span></li>
                    <li>
                        <img :src="detailsContrat.userid.photo" alt="" width="200">
                    </li>
                    </ul>
                </div>
                </el-card>
            </div>
            </el-col>
        </el-row>
  
        <template v-if="detailsContrat.brancheid.branche == 'Automobile' && detailsContrat.details[0] != null">
            <el-table :data="detailsContrat.details[0].vehicules" class="padded-table" height="auto" style="width: 100%" row-key="id">
            <el-table-column prop="marque" label="Marque" min-width="150"> </el-table-column>
            <el-table-column prop="model" label="Modèle" min-width="100"> </el-table-column>
            <el-table-column prop="force" label="Puissance fiscale" min-width="200"> </el-table-column>
            <el-table-column prop="nbplace" label="Nombre de place" min-width="200"> </el-table-column>
            <el-table-column prop="valeur" label="Valeur à neuf" min-width="200"> </el-table-column>
            <el-table-column prop="valeur_venale" label="Valeur venale" min-width="200"> </el-table-column>
            <el-table-column prop="immatriculation" label="Immatriculation" min-width="200"> </el-table-column>
            <el-table-column prop="carburation" label="Carburation" min-width="200"> </el-table-column>
            </el-table>
            <div class="row mt-2" v-if="detailsContrat.user_on_gray_card">
                <ul class="detailsSee">
                <li>
                    Nom sur la carte grise :
                    <span class="font-weight-bold"
                    >{{ detailsContrat.user_on_gray_card.nom.toUpperCase() }}
                    </span>
                </li>
                <li>
                    Prénom sur la carte grise :
                    <span class="font-weight-bold"
                    >{{ detailsContrat.user_on_gray_card.prenom.toUpperCase() }}
                    </span>
                </li>
                </ul>
            </div>
        </template>
  
        <div class="mt-5">
            <el-button type="primary" v-if="detailsContrat.brancheid.branche=='Automobile'" round @click="detailAttestation()">Imprimer l'attestation</el-button>
        </div>
        <div class="mt-5">
            <el-button type="primary" v-if="!detailsContrat.police_assureur" round @click="modalNumPolice=true">Imprimer le contrat</el-button>
            <a v-else :href="detailsContrat.lien_contrat">Télécharger le contrat</a>
        </div>
        </el-dialog>
  
        <el-dialog width="70%" title="Client" :visible.sync="showUser" v-if="showUser==true && !isMobile">
            <el-row :gutter="15">
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                                    <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                                    <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div style="text-align: center!important;">
                                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
  
        <el-dialog width="90%" title="Client" :visible.sync="showUser" v-if="showUser==true && isMobile">
            <el-row :gutter="15">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                                    <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                                    <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div style="text-align: center!important;">
                                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
  
        <el-dialog width="70%" title="Partenaire" :visible.sync="showPartner" v-if="showPartner==true && !isMobile">
            <el-row :gutter="15">
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Nom : <span class="to-right assuraf-orange">{{detailsPartner.societe}}</span></li>
                                    <li>Code partenaire : <span class="to-right assuraf-orange">{{detailsPartner.code_partenaire}}</span></li>
                                    <li>Type : <span class="to-right assuraf-orange">{{detailsPartner.type_partenaire}}</span></li>
                                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsPartner.telephone_port}}</span></li>
                                    <li>Email : <span class="to-right assuraf-orange">{{detailsPartner.email}}</span></li>
                                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsPartner.adresse}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div style="text-align: center!important;">
                                <img class="justify-center" :src="detailsPartner.logo" width="200" alt="logo du partenaire">
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
  
        <el-dialog width="90%" title="Partenaire" :visible.sync="showPartner" v-if="showPartner==true && isMobile">
            <el-row :gutter="15">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div  >
                                <ul style="list-style:none">
                                    <li>Nom : <span class="to-right assuraf-orange">{{detailsPartner.societe}}</span></li>
                                    <li>Code partenaire : <span class="to-right assuraf-orange">{{detailsPartner.code_partenaire}}</span></li>
                                    <li>Type : <span class="to-right assuraf-orange">{{detailsPartner.type_partenaire}}</span></li>
                                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsPartner.telephone_port}}</span></li>
                                    <li>Email : <span class="to-right assuraf-orange">{{detailsPartner.email}}</span></li>
                                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsPartner.adresse}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div style="text-align: center!important;">
                                <img class="justify-center" :src="detailsPartner.logo" width="200" alt="logo du partenaire">
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
  
        <el-dialog title="Génération du fichier" width="50%" :visible.sync="modalNumPolice" v-if="modalNumPolice==true && !isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Ajouter</span>
          </div>
          <div>
             <el-row :gutter="15">
              <el-col :span="12">
              <label>Entrer le numéro de police</label>
              <el-input aria-placeholder="Numéro police" v-model="NumPolice"></el-input>
              </el-col>
              <el-col :span="12">
               <label>Entrer le numéro du talon attestation</label>
                <el-input aria-placeholder="Numéro talon" v-model="NumTalon"></el-input>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="Validate_numePolice(detailsContrat)">Valider</el-button>
      </div>
    </el-dialog>
  
    <el-dialog title="Génération du fichier" width="90%" :visible.sync="modalNumPolice" v-if="modalNumPolice==true && isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Ajouter</span>
          </div>
          <div>
             <el-row :gutter="15">
              <el-col :span="24">
              <label>Entrer le numéro de police</label>
              <el-input aria-placeholder="Numéro police" v-model="NumPolice"></el-input>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
               <label>Entrer le numéro du talon attestation</label>
                <el-input aria-placeholder="Numéro talon" v-model="NumTalon"></el-input>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="Validate_numePolice(detailsContrat)">Valider</el-button>
      </div>
    </el-dialog>
  
    <el-dialog title="Génération du fichier" width="50%" :visible.sync="modalNumPoliceAttest" v-if="modalNumPoliceAttest==true && !isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Modifier</span>
          </div>
          <div>
             <el-row :gutter="15">
              <el-col :span="12">
              <label>Entrer le numéro de police de l'attestation</label>
              <el-input placeholder="Numéro police" v-model="NumPolice"></el-input>
              </el-col>
              <el-col :span="12">
               <label>Entrer la categorie de l'attestation</label>
                <el-input placeholder="Catégorie" v-model="categorie"></el-input>
              </el-col>
            </el-row>
  
            <el-row style="margin-top:15px">
              <el-col>
                <el-switch
                  style="display: block"
                  v-model="checked"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Changer le nom du client"
                  @change="checkedControl()">
                </el-switch>
              </el-col>
            </el-row>
  
             <el-row :gutter="15" style="margin-top:15px" v-show="client_name">
              <el-col :span="12">
              <label>Entrer le nom sur la carte grise</label>
              <el-input placeholder="Nom" v-model="NomSurCarte"></el-input>
              </el-col>
              <el-col :span="12">
               <label>Entrer le prénom sur la carte grise</label>
                <el-input placeholder="Prénom" v-model="PrenomSurCarte"></el-input>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="Validate_numePoliceAttest()">Valider</el-button>
      </div>
    </el-dialog>
  
     <el-dialog title="Génération du fichier" width="90%" :visible.sync="modalNumPoliceAttest" v-if="modalNumPoliceAttest==true && isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Modifier</span>
          </div>
          <div>
             <el-row :gutter="15">
              <el-col :span="24">
              <label>Entrer le numéro de police de l'attestation</label>
              <el-input placeholder="Numéro police" v-model="NumPolice"></el-input>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
               <label>Entrer la categorie de l'attestation</label>
                <el-input placeholder="Catégorie" v-model="categorie"></el-input>
              </el-col>
               <el-col :span="24" style="margin-top:15px">
                <el-switch
                  style="display: block"
                  v-model="checked"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Changer le nom du client"
                  @change="checkedControl()">
                </el-switch>
              </el-col>
               <el-col :span="24" style="margin-top:15px" v-show="client_name">
              <label>Entrer le nom sur la carte grise</label>
              <el-input placeholder="Nom" v-model="NomSurCarte"></el-input>
              </el-col>
              <el-col :span="24" style="margin-top:15px" v-show="client_name">
               <label>Entrer le prénom sur la carte grise</label>
                <el-input placeholder="Prénom" v-model="PrenomSurCarte"></el-input>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="Validate_numePoliceAttest()">Valider</el-button>
      </div>
    </el-dialog>
  
    <el-dialog title="Détails attestation" width="90%" :visible.sync="showAttestation" v-if="showAttestation==true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Attestation</span>
          </div>
          <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS AUTOMOTO -->
            <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS AUTOMOTO -->
            <div v-if="detailsContrat.brancheid.branche=='Automobile'">
                <!-- LA PARTIE ATTESTATION SUNU, PROVIDENCE, -->
                <!-- LA PARTIE ATTESTATION SUNU, PROVIDENCE, -->
                <!-- LA PARTIE ATTESTATION SUNU, PROVIDENCE, -->
                <!-- LA PARTIE ATTESTATION SUNU, PROVIDENCE, -->
                <div id="canvas" width="450" height="450" v-if="Sunu">
                    <div class="ml-1 row" style="display:inline-flex!important">
                        <div class="column" style="width:309px!important">
                            <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top: 43px!important;list-style:none!important;margin-left: 30px!important;">
                                <li><span class="font-weight-bold" v-show="user_name">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                                <li><span class="font-weight-bold">{{NomSurCarte}} {{PrenomSurCarte}}</span> </li>
                                <li><span class="font-weight-bold">{{detailsContrat.userid.profession}}</span> </li>
                                <li><span class="font-weight-bold">{{detailsContrat.userid.adresse}}</span> </li>
                            </ul>
  
                            <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:101px!important;list-style:none!important;margin-left: 30px!important;">
                                <li><span class="font-weight-bold">{{categorie}}</span> </li>
                                <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                                <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                                <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                                <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                                <li v-show="isToday"><span class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
                                <li v-show="isNotToday"><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}}</span> </li>
                                <li><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                            </ul>
                        </div>
  
                        <div class="column" style="width: 196px!important">
                            <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:96px!important;list-style:none!important;margin-left: 50px!important;">
                                <li><span class="font-weight-bold">{{categorie}}</span> </li>
                                <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                                <li><span class="font-weight-bold"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                                <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                                <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                                <li v-show="isToday"><span class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
                                <li v-show="isNotToday"><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}} </span> </li>
                                <li><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                            </ul>
                        </div>
  
                        <div class="column">
                            <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top: 43px!important;list-style:none!important;margin-left: 95px!important;">
                                <li><span class="font-weight-bold" v-show="user_name">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                                <li><span class="font-weight-bold"> {{NomSurCarte}} {{PrenomSurCarte}} </span> </li>
                                <li><span class="font-weight-bold">{{detailsContrat.userid.profession}}</span> </li>
                                <li><span class="font-weight-bold">{{detailsContrat.userid.adresse}}</span> </li>
                            </ul>
  
                            <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:101px!important;list-style:none!important;margin-left: 95px!important;">
                                <li><span class="font-weight-bold">{{categorie}}</span> </li>
                                <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                                <li><span class="font-weight-bold"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                                <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                                <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                                <li v-show="isToday"><span class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
                                <li v-show="isNotToday"><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}} </span> </li>
                                <li><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                            </ul>
                        </div>
                    </div>
                </div>
  
                <!-- LA PARTIE ATTESTATION ASKIA -->
                <!-- LA PARTIE ATTESTATION ASKIA -->
                <!-- LA PARTIE ATTESTATION ASKIA -->
                <!-- LA PARTIE ATTESTATION ASKIA -->
                <div id="canvas" width="450" v-if="Askia" style="height:250px!important">
                    <div class="ml-1 row" style="display:inline-flex!important">
                        <!-- 1 Colonne -->
                        <div class="column" style="width:309px!important;margin-left:75px">
                            <ul class="detailsSeeAttest" style="margin-top: 33px!important;list-style:none!important;margin-left: 35px!important;">
                                <li><span class="font-weight-bold" style="margin-left:-92px;font-size:11px!important">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                            </ul>
  
                            <ul class="detailsSeeAttest" style="margin-top: 18px!important;list-style:none!important;margin-left: 25px!important;line-height:12px!important">
                                <li><span class="font-weight-bold" style="margin-left:-50px;font-size:11px!important">{{NumPolice}}</span> </li>
                                <li v-show="isToday" style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:-35px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
  
                                <li v-show="isNotToday" style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:-35px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet) + ' 00H00'}}</span> </li>
                                <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:-35px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                                <!-- <li style="margin-top:-1px">-</li> -->
                                <li style="margin-top:-1px"><span class="font-weight-bold" style="font-size:11px!important;margin-left:-35px">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                                <!-- <li style="margin-top:-1px"><span class="font-weight-bold" style="font-size:9px!important">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li> -->
                            </ul>
  
                            <ul class="detailsSeeAttest" style="margin-top: 13px!important;list-style:none!important;margin-left: 25px!important;">
                                <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:-65px;font-size:11px!important">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                                <li><span class="font-weight-bold" style="margin-left:-40px;font-size:11px!important"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                                <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:60px;font-size:11px!important">{{categorie}}</span> </li>
                            </ul>
                        </div>
                        <!-- 2eme colonne-->
                        <div class="column" style="width:309px!important;margin-left:-20px">
                            <ul class="detailsSeeAttest" style="margin-top: 69px!important;list-style:none!important;margin-left: 1px!important;">
                                 <li><span class="font-weight-bold" style="margin-left:-65px;font-size:11px!important">{{NumPolice}}</span> </li>
  
                                 <li v-show="isToday" style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:-50px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
  
  
                                <li v-show="isNotToday" style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:-50px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet) + ' 00H00'}}</span> </li>
                                <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:-50px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                                <li style="margin-top:-1px"><span class="font-weight-bold" style="font-size:11px!important;margin-left:-43px">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
  
                            </ul>
  
                            <ul class="detailsSeeAttest" style="margin-top: 13px!important;list-style:none!important;margin-left: 1px!important;">
                                <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:-50px;font-size:11px!important">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                                <li><span class="font-weight-bold" style="margin-left:-50px;font-size:11px!important"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                                <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:50px;font-size:11px!important">{{categorie}}</span> </li>
                            </ul>
                        </div>
  
                        <!-- 3eme colonne-->
                        <div class="column" style="width:309px!important;margin-left:-120px;margin-top:-15px">
                            <ul class="detailsSeeAttest" style="margin-top: 20px!important;list-style:none!important;margin-left: 15px!important;">
                                <li><span class="font-weight-bold" style="margin-left:-10px;font-size:11px!important">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                            </ul>
  
                            <ul class="detailsSeeAttest" style="margin-top: 18px!important;list-style:none!important;margin-left: 5px!important;line-height:12px!important">
                                <li style="margin-top:-5px"><span class="font-weight-bold" style="margin-left:20px;font-size:11px!important">{{detailsContrat.userid.profession}}</span> </li>
                                <li style="margin-top:1px"><span class="font-weight-bold" style="margin-left:60px;font-size:11px!important">{{NumPolice}}</span> </li>
                                <li v-show="isToday" style="margin-top:10px;margin-left: 1px"><span class="font-weight-bold" style="font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}
                                        <span class="font-weight-bold" style="margin-left: 50px">{{DateFormatter(detailsContrat.dateEcheance) + ' 24H'}}</span></span> </li>
  
                                <li v-show="isNotToday" style="margin-top:10px;margin-left: 1px"><span class="font-weight-bold" style="font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet) + ' 00H00'}}
                                        <span class="font-weight-bold" style="margin-left: 50px">{{DateFormatter(detailsContrat.dateEcheance) + ' 24H'}}</span></span> </li>
                                <li style="margin-top:5px"><span class="font-weight-bold" style="font-size:11px!important;margin-left:130px">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                                <li style="margin-top:5px"><span class="font-weight-bold" style="font-size:11px!important;margin-left:125px">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                            </ul>
  
                            <ul class="detailsSeeAttest" style="margin-top: 15px!important;list-style:none!important;margin-left: 15px!important;">
                                <li><span class="font-weight-bold" style="font-size:11px!important"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                                <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:70px;font-size:11px!important">{{categorie}}</span> </li>
                            </ul>
                        </div>
                    </div>
                </div>
  
              <!-- LA PARTIE ATTESTATION AXA -->
              <!-- LA PARTIE ATTESTATION AXA -->
              <!-- LA PARTIE ATTESTATION AXA -->
              <!-- LA PARTIE ATTESTATION AXA -->
              <div id="canvas" width="450" v-if="Axa" style="height:250px!important">
                <div class="ml-1 row" style="display:inline-flex!important">
                  <!-- 1 Colonne -->
                  <div class="column" style="width:309px!important;margin-left:75px;margin-top:15px">
                    <ul class="detailsSeeAttest" style="margin-top: 33px!important;list-style:none!important;margin-left: 35px!important;">
                      <li><span class="font-weight-bold" style="margin-left:-92px;font-size:11px!important" v-show="user_name">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                      <li><span class="font-weight-bold" style="margin-left:-92px;font-size:11px!important">{{PrenomSurCarte}} {{NomSurCarte}}</span> </li>
  
                    </ul>
  
                    <ul class="detailsSeeAttest" style="margin-top: 18px!important;list-style:none!important;margin-left: 25px!important;line-height:12px!important">
                      <li><span class="font-weight-bold" style="margin-left:-35px;font-size:11px!important">{{NumPolice}}</span> </li>
                      <li v-show="isToday" style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:-35px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet)}}{{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
  
                      <li style="margin-top:-1px;margin-left: -40px">
                          <span v-show="isToday" class="font-weight-bold" style="margin-left:-35px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span>
                          <span v-show="isNotToday" class="font-weight-bold" style="margin-left:-35px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet) + ' 00H00'}}</span>
                          <span class="font-weight-bold" style="margin-left:10px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEcheance) + ' 24H'}}</span>
                      </li>
                      <li style="margin-top:-1px"><span class="font-weight-bold" style="font-size:11px!important;margin-left:-15px">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                    </ul>
  
                    <ul class="detailsSeeAttest" style="margin-top: 17px!important;list-style:none!important;margin-left: 25px!important;">
                      <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:25px;font-size:11px!important">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                      <li style="margin-top: 5px"><span class="font-weight-bold" style="margin-left:-30px;font-size:11px!important"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:50px;font-size:11px!important">{{categorie}}</span> </li>
                    </ul>
                  </div>
                  <!-- 2eme colonne-->
                  <div class="column" style="width:309px!important;margin-left:-20px;margin-top:10px">
                    <ul class="detailsSeeAttest" style="margin-top: 69px!important;list-style:none!important;margin-left: 1px!important;">
                      <li><span class="font-weight-bold" style="margin-left:-45px;font-size:11px!important">{{NumPolice}}</span> </li>
  
                      <li style="margin-top:-1px;margin-left: -40px">
                          <span v-show="isToday" class="font-weight-bold" style="margin-left:-35px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span>
                          <span v-show="isNotToday" class="font-weight-bold" style="margin-left:-35px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet) + ' 00H00'}}</span>
                          <span class="font-weight-bold" style="margin-left:10px;font-size:11px!important">{{DateFormatter(detailsContrat.dateEcheance) + ' 24H'}}</span>
                      </li>
                      <li style="margin-top:-1px"><span class="font-weight-bold" style="font-size:11px!important;margin-left:-15px">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
  
                    </ul>
  
                    <ul class="detailsSeeAttest" style="margin-top: 13px!important;list-style:none!important;margin-left: 1px!important;">
                      <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:25px;font-size:11px!important">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                      <li><span class="font-weight-bold" style="margin-left:-50px;font-size:11px!important"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:50px;font-size:11px!important">{{categorie}}</span> </li>
                    </ul>
                  </div>
                  <!-- 3eme colonne-->
                  <div class="column" style="width:309px!important;margin-left:-120px;margin-top:-5px">
                    <ul class="detailsSeeAttest" style="margin-top: 20px!important;list-style:none!important;margin-left: 15px!important;">
                      <li><span class="font-weight-bold" style="margin-left:-10px;font-size:11px!important" v-show="user_name">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                      <li><span class="font-weight-bold" style="margin-left:-10px;font-size:11px!important">{{PrenomSurCarte}} {{NomSurCarte}}</span> </li>
                    </ul>
  
                    <ul class="detailsSeeAttest" style="margin-top: 18px!important;list-style:none!important;margin-left: 5px!important;line-height:12px!important">
                      <li style="margin-top:-5px"><span class="font-weight-bold" style="margin-left:30px;font-size:11px!important">{{detailsContrat.userid.profession}}</span> </li>
                      <li style="margin-top:1px"><span class="font-weight-bold" style="margin-left:40px;font-size:11px!important">{{NumPolice}}</span> </li>
                      <li v-show="isToday" style="margin-top:10px;margin-left: 1px"><span class="font-weight-bold" style="font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}
                                        <span class="font-weight-bold" style="margin-left: 50px">{{DateFormatter(detailsContrat.dateEcheance) + ' 24H'}}</span></span> </li>
  
                      <li v-show="isNotToday" style="margin-top:10px;margin-left: 1px"><span class="font-weight-bold" style="font-size:11px!important">{{DateFormatter(detailsContrat.dateEffet) + ' 00H00'}}
                                        <span class="font-weight-bold" style="margin-left: 40px">{{DateFormatter(detailsContrat.dateEcheance) + ' 24H'}}</span></span> </li>
                      <li style="margin-top:5px"><span class="font-weight-bold" style="font-size:11px!important;margin-left:100px">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                      <li style="margin-top:5px"><span class="font-weight-bold" style="font-size:11px!important;margin-left:125px">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                    </ul>
  
                    <ul class="detailsSeeAttest" style="margin-top: 15px!important;list-style:none!important;margin-left: 15px!important;">
                      <li><span class="font-weight-bold" style="font-size:11px!important"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li style="margin-top:-1px"><span class="font-weight-bold" style="margin-left:140px;font-size:11px!important">{{categorie}}</span> </li>
                    </ul>
                  </div>
                </div>
              </div>
  
              <!-- LA PARTIE ATTESTATION AMSA -->
              <!-- LA PARTIE ATTESTATION AMSA -->
              <!-- LA PARTIE ATTESTATION AMSA -->
              <!-- LA PARTIE ATTESTATION AMSA -->
              <div id="canvas" width="450" v-if="Amsa" style="height:300px!important">
                <div class="ml-1 row" style="display:inline-flex!important">
                  <div class="column" style="width:309px!important;margin-top: -5px">
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top: 40px!important;list-style:none!important;margin-left: 60px!important;">
                      <li><span class="font-weight-bold" v-show="user_name">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                      <li><span class="font-weight-bold">{{NomSurCarte}} {{PrenomSurCarte}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.profession}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.adresse}}</span> </li>
                    </ul>
  
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:70px!important;list-style:none!important;margin-left: 60px!important;">
                      <li><span class="font-weight-bold">{{categorie}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li><span class="font-weight-bold" style="margin-left:80px">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                      <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                      <li>
                        <span v-show="isToday" class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span>
                        <span v-show="isNotToday" class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}}</span>
                        <span class="ml-1 font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span>
                      </li>
                    </ul>
                  </div>
  
                  <div class="column" style="width: 196px!important">
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:108px!important;list-style:none!important;margin-left: 40px!important;">
                      <li><span class="font-weight-bold">{{categorie}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                      <li><span class="font-weight-bold"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li><span class="font-weight-bold" style="margin-left: 30px">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                      <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                      <li v-show="isToday"><span class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
                      <li v-show="isNotToday"><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}} </span> </li>
                      <li><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                    </ul>
                  </div>
  
                  <div class="column">
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top: 37px!important;list-style:none!important;margin-left: 65px!important;">
                      <li><span class="font-weight-bold" v-show="user_name">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                      <li><span class="font-weight-bold"> {{NomSurCarte}} {{PrenomSurCarte}} </span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.profession}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.adresse}}</span> </li>
                    </ul>
  
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:68px!important;list-style:none!important;margin-left: 85px!important;">
                      <li><span class="font-weight-bold">{{categorie}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li><span class="font-weight-bold" style="margin-left:80px">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                      <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                      <li>
                        <span v-show="isToday" class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span>
                        <span v-show="isNotToday" class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}}</span>
                        <span class="ml-1 font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
  
              <!-- LA PARTIE ATTESTATION SALAMA -->
              <!-- LA PARTIE ATTESTATION SALAMA -->
              <!-- LA PARTIE ATTESTATION SALAMA -->
              <!-- LA PARTIE ATTESTATION SALAMA -->
              <div id="canvas" width="450" height="450" v-if="Salama">
                <div class="ml-1 row" style="display:inline-flex!important">
                  <div class="column" style="width:309px!important">
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top: 25px!important;list-style:none!important;margin-left: 30px!important;">
                      <li><span class="font-weight-bold" v-show="user_name">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                      <li><span class="font-weight-bold">{{NomSurCarte}} {{PrenomSurCarte}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.profession}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.adresse}}</span> </li>
                    </ul>
  
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:83px!important;list-style:none!important;margin-left: 30px!important;">
                      <li><span class="font-weight-bold">{{categorie}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                      <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                      <li v-show="isToday"><span class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
                      <li v-show="isNotToday"><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}}</span> </li>
                      <li><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                    </ul>
                  </div>
  
                  <div class="column" style="width: 170px!important">
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:86px!important;list-style:none!important;margin-left: 50px!important;">
                      <li><span class="font-weight-bold">{{categorie}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                      <li><span class="font-weight-bold"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                      <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                      <li v-show="isToday"><span class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
                      <li v-show="isNotToday"><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}} </span> </li>
                      <li><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                    </ul>
                  </div>
  
                  <div class="column">
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top: 33px!important;list-style:none!important;margin-left: 95px!important;">
                      <li><span class="font-weight-bold" v-show="user_name">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                      <li><span class="font-weight-bold"> {{NomSurCarte}} {{PrenomSurCarte}} </span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.profession}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.adresse}}</span> </li>
                    </ul>
  
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:91px!important;list-style:none!important;margin-left: 95px!important;">
                      <li><span class="font-weight-bold">{{categorie}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                      <li><span class="font-weight-bold"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                      <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                      <li v-show="isToday"><span class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
                      <li v-show="isNotToday"><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}} </span> </li>
                      <li><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                    </ul>
                  </div>
                </div>
              </div>
  
              <!-- LA PARTIE ATTESTATION ALLIANZ -->
              <!-- LA PARTIE ATTESTATION ALLIANZ -->
              <!-- LA PARTIE ATTESTATION ALLIANZ -->
              <!-- LA PARTIE ATTESTATION ALLIANZ -->
              <div id="canvas" width="450" height="450" v-if="Allianz">
                <div class="ml-1 row" style="display:inline-flex!important">
                  <div class="column" style="width:309px!important">
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top: 25px!important;list-style:none!important;margin-left: 30px!important;">
                      <li><span class="font-weight-bold" v-show="user_name">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                      <li><span class="font-weight-bold">{{NomSurCarte}} {{PrenomSurCarte}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.profession}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.adresse}}</span> </li>
                    </ul>
  
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:83px!important;list-style:none!important;margin-left: 30px!important;">
                      <li><span class="font-weight-bold">{{categorie}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                      <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                      <li v-show="isToday"><span class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
                      <li v-show="isNotToday"><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}}</span> </li>
                      <li><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                    </ul>
                  </div>
  
                  <div class="column" style="width: 158px!important">
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:86px!important;list-style:none!important;margin-left: 50px!important;">
                      <li><span class="font-weight-bold">{{categorie}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                      <li><span class="font-weight-bold"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                      <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                      <li v-show="isToday"><span class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
                      <li v-show="isNotToday"><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}} </span> </li>
                      <li><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                    </ul>
                  </div>
  
                  <div class="column">
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top: 33px!important;list-style:none!important;margin-left: 95px!important;">
                      <li><span class="font-weight-bold" v-show="user_name">{{detailsContrat.userid.prenom}} {{detailsContrat.userid.nom.toUpperCase()}}</span> </li>
                      <li><span class="font-weight-bold"> {{NomSurCarte}} {{PrenomSurCarte}} </span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.profession}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.userid.adresse}}</span> </li>
                    </ul>
  
                    <ul class="AttestPoliceSize detailsSeeAttest" style="margin-top:91px!important;list-style:none!important;margin-left: 95px!important;">
                      <li><span class="font-weight-bold">{{categorie}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].marque}}</span> </li>
                      <li><span class="font-weight-bold"> {{detailsContrat.details[0].vehicules[0].model}}</span> </li>
                      <li><span class="font-weight-bold">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li>
                      <li><span class="font-weight-bold">{{NumPolice}}</span> </li>
                      <li v-show="isToday"><span class="font-weight-bold">{{DateFormatter(detailsContrat.dateEffet)}} {{MinuteFormatter(detailsContrat.dateEffet)}}</span> </li>
                      <li v-show="isNotToday"><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEffet) + ' 00H00'}} </span> </li>
                      <li><span class="font-weight-bold">{{DateFormater(detailsContrat.dateEcheance) + ' 24H'}}</span> </li>
                    </ul>
                  </div>
                </div>
              </div>
  
            </div>
  
            <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS HABITATION -->
            <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS HABITATION -->
            <div v-if="detailsContrat.brancheid.branche=='Habitation'">
                <p>Comming soon</p>
            </div>
  
            <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS VOYAGE -->
            <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS VOYAGE -->
            <div v-if="detailsContrat.brancheid.branche=='Voyage'">
                <p>Comming soon</p>
            </div>
  
            <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS SANTÉ -->
            <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS SANTÉ -->
            <div v-if="detailsContrat.brancheid.branche=='Santé'">
                <p>Comming soon</p>
            </div>
  
            <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS VIE  -->
            <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS VIE -->
            <div v-if="detailsContrat.brancheid.branche=='Vie'">
                <p>Comming soon</p>
            </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="downloadAttestationPdf()">Imprimer l'attestation</el-button>
      </div>
    </el-dialog>
    </div>
  </template>
  
  <script>
    import _ from "lodash"
    import moment from "moment-timezone"
  
    //debut importation assuraf
    import dayjs from 'dayjs'
    import Api from '../../../services/Api'
    import filesCss from '../../../common/css_assur.json'
    import store from '../../../store'
    import jsPDF from "jspdf"
    import html2canvas from 'html2canvas'
    //fin importation assuraf
  
    export default {
        name: "StyledTablePage",
        data() {
            return {
                attestation: null,
                style: null,
                filesCss: filesCss,
                optionsdate: {
                    format: "YYYY/MM/DD",
                    useCurrent: false,
                },
                JsonView: false,
                Delete_reason: '',
                checked: null,
                user_name: true,
                client_name: false,
                isToday: false,
                isNotToday: false,
                Sunu: false,
                //code d'ivoire
                SANLAM: false,
                ALLIANZ: false,
                ATLANTIQUE: false,
                du: null,
                au: null,
                Askia: false,
                Axa: false,
                Amsa: false,
                devisclient: false,
                modalgeneration: false,
                modalNumPolice: false,
                modalDelete: false,
                modal_Delete_ok: false,
                modalNumPoliceAttest: false,
                NumPolice: null,
                NomPrenom: null,
                PrenomSurCarte: null,
                NomSurCarte: null,
                categorie: null,
                NumPoliceAttest: null,
                NumTalon: null,
                showUser: false,
                showPartner: false,
                username: null,
                paramclient: null,
                data: {},
                html: "",
                detailsContrat: [],
                detailsUser: [],
                detailsPartner: null,
                perPage: 10,
                totalRows: null,
                listuser: null,
                listcontrat: [],
                userid: null,
                userRole: null,
                showcontrat: false,
                showAttestation: false,
                showAttestation1: false,
                filter: null,
                notification_text: null,
                sonam: "SONAM GENERALE ASSURANCES",
                modalInfo: {
                    title: "",
                    content: "",
                },
                HeaderTableauVehicules: [
                    {
                    key: "marque",
                    label: "Marque",
                    },
                    {
                    key: "model",
                    label: "Model",
                    },
                    {
                    key: "force",
                    label: "Puissance Fiscale",
                    },
                    {
                    key: "nbplace",
                    label: "Nombre de place",
                    },
                    {
                    key: "valeur",
                    label: "Valeur neuf",
                    },
                    {
                    key: "valeur_venale",
                    label: "Valeur venale",
                    },
                    {
                    key: "immatriculation",
                    label: "Immatriculation",
                    },
                    {
                    key: "carburation",
                    label: "Carburation",
                    },
                ],
                fields: [
                    {
                    key: "partner",
                    label: "Partenaire",
                    },
                    {
                    key: "dateSouscription",
                    label: "Date Souscription",
                    },
                    {
                    key: "brancheid.branche",
                    label: "Branche",
                    },
                    {
                    key: "numero_police",
                    label: "Numero",
                    },
                    {
                    key: "partenaireid.societe",
                    label: "Assureur",
                    },
                    {
                    key: "dateEffet",
                    label: "Effet",
                    },
                    {
                    key: "dateEcheance",
                    label: "Echéance",
                    },
                    {
                    key: "prime_ttc",
                    label: "Prime TTC",
                    },
                    {
                    key: "client",
                    label: "Client",
                    },
                    {
                    key: "creator",
                    label: "Createur",
                    },
                    {
                    key: "action",
                    label: "Action",
                    },
                ],
                ArchivateDetails:null,
                /*=============*/
                up_primenette:null,
                up_accessoires:null,
                up_taxes:null,
                up_primettc:null,
                money: {
                    thousands: ' ',
                    precision: false,
                    masked: false
                },
                UpdatePrimeDevis:false,
                items: [],
  
                totalItems: null,
                isBusy: false,
                optionsdateModif: {
                    useCurrent: false,
                    format: 'YYYY/MM/DD',
                },
                dateEffet: null,
                dateEcheance: null,
                duree: null,
                CalculDuree:null,
                specialUpdatedevis: false,
                modalgenerationloading : false,
                optionsdate: {
                    format: 'YYYY/MM/DD',
                    useCurrent: false,
                },
                ldevis: [],
                listdevis: [],
                detailsDevis: [],
                showdevis: false,
                pageOptions: [5, 10, 15],
                MenuHeader:[
                    {key: "theme",label: "Libéllé"},
                    {key: "collapse",label: "Collapse"},
                    {key: "sousmenu",label: "Sous menu"},
                    {key: "action", label:"action"}
                ],
                userflette:[],
                fletteRows:null,
                editUser : false,
                Selectedbranche:null,
                selectedAssureur:[],
                configUserDevisAssureur:null,
                assureurs:[],
                branchelist:null,
                modalSociete: false,
                configHead:[
                    { key:"branche", label:"branche"},
                    { key:"assureurs", label:"assureur"},
                    { key:"action", label:"Action"}
                ],
                selectedUser:null,
                listeAssureurs: null,
                usermenu:[],
                collapse:null,
                menu_name:null,
                showConfigMenu:false,
                submenu:null,
                userSubmenu:[],
                UserFinalMenu:[],
  
                Mycounter: 0,
                dismissSecs: 3,
                UpdateOk: false,
                deleteConfirmation: false,
                modalDelete: false,
                dismissCountDown: 0,
                detailsUser: [],
                showuser: false,
                voidField: false,
                isMobile: false,
                ready: false,
                width: 0,
                height: "auto",
                loading: false,
                search: "",
                pagination: {
                    page: 1,
                    size: 100,
                    sizes: [10, 15, 20, 30, 50, 100],
                    layout: "total, ->, prev, pager, next, jumper, sizes",
                    small: false
                },
                list: this.listcontrat, //users,
                editMode: false,
                itemsChecked: [],
                dialogUserVisible: false,
                currentId: 0,
                moment
            }
        },
        computed: {
            listFiltered() {
                if(this.search.length >= 4){
                    return this.listcontrat.filter(obj => {
                        for (let k in obj)
                        {
                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                ctrl = true
                                /*on le push dans le tableau */
                                this.listcontrat.push(obj)
                            }else{
                                /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
                                if(Array.isArray(obj[k])){
                                    obj[k].filter(nobj=>{
                                        for(let l in nobj){
                                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                                            if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                                ctrl = true
                                                /* on le push dans le tableau */
                                                this.listcontrat.push(obj)
                                            }
                                        }
                                    })
                                    /* Si la valeur courante n'est ni String, ni Tableau mais Object */
                                }else if(!Array.isArray(obj[k]) && typeof obj[k]=="object" && obj[k]!=null){
                                    /*== On crée un nouveau tablequ vide ==*/
                                    let my_new_array = []
  
                                    /*== On push la valeur courante dans le nouveau tableau ==*/
                                    my_new_array.push(obj[k])
  
                                    /*On refiltre le nouveau tableau atrribut par attribut*/
                                    my_new_array.filter(nobj=>{
                                        for(let l in nobj){
                                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                                            if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                                ctrl = true
  
                                                /* on le push dans le tableau */
                                                this.listcontrat.push(obj)
                                            }
                                        }
                                    })
                                }
                            }
                        }
                        return ctrl
                    })
                }else{
                    return this.listcontrat.filter(obj => {
                        let ctrl = false
                        for (let k in obj)
                        {
                            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
                                ctrl = true
                        }
                        return ctrl
                    })
                }
            },
            listSortered() {
                let prop = this.sortingProp
                let order = this.sortingOrder
                return [].concat(
                    this.listFiltered.sort((item1, item2) => {
                        let val1 = ""
                        let val2 = ""
  
                        val1 = item1[prop]
                        val2 = item2[prop]
                        if (order === "descending") {
                            return val2 < val1 ? -1 : 1
                        }
                        return val1 < val2 ? -1 : 1
                    })
                )
            },
            listInPage() {
                let from = (this.currentPage - 1) * this.itemPerPage
                let to = from + this.itemPerPage * 1
                return this.listFiltered.slice(from, to)
            },
            total() {
                return this.listFiltered.length
            },
            currentPage: {
                get() {
                    return this.pagination.page
                },
                set(val) {
                    this.pagination.page = val
                }
            },
            itemPerPage() {
                return this.pagination.size
            },
            selectedItems() {
                return this.itemsChecked.length || 0
            }
        },
        watch: {
            isBusy(newVal, oldVal) {
                if (newVal !== oldVal) {
                    const tableScrollBody = this.$refs["my-table"].$el;
                    if (newVal === true) {
                        tableScrollBody.classList.add("overflow-hidden");
                    } else {
                        tableScrollBody.classList.remove("overflow-hidden");
                    }
                }
            },
  
            itemPerPage(val) {
                this.ready = false
                this.currentPage = 1
  
                setTimeout(() => {
                    this.ready = true
                }, 500)
            },
            search(val) {
                this.currentPage = 1
            }
        },
        methods: {
            // Fonction de download du devis en fichier pdf
            downloadAttestationProvisoire: function (contrat) {
                if (contrat.brancheid.branche == "Voyage" && contrat.partenaireid.societe == this.sonam) {
                    this.modalgenerationloading = true;
                    let url = contrat.lien_contrat;
                    setTimeout(
                    () => ((this.modalgenerationloading = false), window.open(url)),
                    7000
                    );
                } else {
                    Api().get('contrat/attestation/download/' + contrat._id)
                    .then(response => {
                        //this.modalgeneration = true
                        this.modalgenerationloading = true;
                        let url = response.data.lien
                        setTimeout(() => (
                            this.modalgenerationloading = false,
                                window.open(url)), 7000);
                    })
                }
           
            },
    Validate_numePolice(contrat) {
      if (this.NumPolice) {
        contrat.NumPolice = this.NumPolice
        this.Contract_generator(contrat)
      } else {
        this.$message.error('Numéro de police obligatoire !', this.AlertMessage)
      }
  
    },
  
    Validate_numePoliceAttest() {
      if (this.checked) {
        if (this.PrenomSurCarte && this.NomSurCarte && this.NumPolice && this.categorie) {
          let data = {
            idContart: this.detailsContrat.id,
            nom: this.NomSurCarte,
            prenom: this.PrenomSurCarte
          }
          Api().post('/contrat/update/user_on_gray_card', data)
              .then((result) => {
                if (result.data.response.nModified || result.data.response.ok) {
                  this.modalNumPoliceAttest = false
                }
              }).catch((err) => {});
  
        } else {
          this.$message.error('Champs obligatoires !', this.AlertMessage)
        }
  
      } else {
        if (this.NumPolice && this.categorie) {
          this.modalNumPoliceAttest = false
        } else {
          this.$message.error('Champs obligatoires !', this.AlertMessage)
        }
      }
  
    },
  
    Contract_generator: function (contrat) {
      this.modalNumPolice = false
      this.modalNumPoliceAttest = false
  
      switch (contrat.brancheid.branche) {
        case 'Automobile':
          let data = {
            idContrat: contrat.id,
            numpolice: this.NumPolice,
            numTalon: this.NumTalon
          }
          Api().post('contrat/definitif/auto', data)
              .then(response => {
                this.NumPolice = null
                this.modalgeneration = true
                let url = response.data.lien
                setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
              })
          break;
        case 'Habitation':
          Api().get('contrat/definitif/mrh/' + contrat.id)
              .then(response => {
                this.modalgeneration = true
                let url = response.data.lien
                setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
              })
          break;
        case 'Voyage':
          let Traveldata = {
            idContrat: contrat.id,
            numpolice: this.NumPolice,
            numTalon: this.NumTalon
          }
          Api().post('contrat/definitif/voyage', Traveldata)
              .then(response => {
                this.modalgeneration = true
                let url = response.data.lien
                setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
              })
          break;
  
      }
  
    },
            DateFormatter: function (TheDate) {
                if (TheDate) {
                    return dayjs(TheDate).format('DD-MM-YYYY')
                }
            },
            MinuteFormatter(TheDate){
                if (TheDate) {
                    return dayjs(new Date()).format('HH:mm')
                }
            },
            Validate_numePoliceAttest() {
      if (this.checked) {
        if (this.PrenomSurCarte && this.NomSurCarte && this.NumPolice && this.categorie) {
          let data = {
            idContart: this.detailsContrat.id,
            nom: this.NomSurCarte,
            prenom: this.PrenomSurCarte
          }
          Api().post('/contrat/update/user_on_gray_card', data)
              .then((result) => {
                if (result.data.response.nModified || result.data.response.ok) {
                  this.modalNumPoliceAttest = false
                }
              }).catch((err) => {});
  
        } else {
          this.$message.error('Champs obligatoires !', this.AlertMessage)
        }
  
      } else {
        if (this.NumPolice && this.categorie) {
          this.modalNumPoliceAttest = false
        } else {
          this.$message.error('Champs obligatoires !', this.AlertMessage)
        }
      }
  
    },
  
    Contract_generator: function (contrat) {
      this.modalNumPolice = false
      this.modalNumPoliceAttest = false
  
      switch (contrat.brancheid.branche) {
        case 'Automobile':
          let data = {
            idContrat: contrat.id,
            numpolice: this.NumPolice,
            numTalon: this.NumTalon
          }
          Api().post('contrat/definitif/auto', data)
              .then(response => {
                this.NumPolice = null
                this.modalgeneration = true
                let url = response.data.lien
                setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
              })
          break;
        case 'Habitation':
          Api().get('contrat/definitif/mrh/' + contrat.id)
              .then(response => {
                this.modalgeneration = true
                let url = response.data.lien
                setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
              })
          break;
        case 'Voyage':
          let Traveldata = {
            idContrat: contrat.id,
            numpolice: this.NumPolice,
            numTalon: this.NumTalon
          }
          Api().post('contrat/definitif/voyage', Traveldata)
              .then(response => {
                this.modalgeneration = true
                let url = response.data.lien
                setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
              })
          break;
  
      }
  
    },
    detailAttestation: function () {
      let today = dayjs(new Date()).format('DD-MM-YYYY')
      if(this.DateFormatter(this.detailsContrat.dateEffet)== today){
        this.isToday = true
        this.isNotToday = false
      } else {
        this.isNotToday = true
        this.isToday = false
      }
  
      this.showAttestation = true
      if (this.detailsContrat.partenaireid.societe == 'SUNU') {
        this.Sunu = true
        this.Askia = false
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'PROVIDENCE') {
        this.Sunu = true
        this.Askia = false
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'NSIA') {
        this.Sunu = true
        this.Askia = false
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'ASKIA') {
        this.Askia = true
        this.Sunu = false
        this.Axa = false
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'AXA') {
        this.Axa = true
        this.Askia = false
        this.Sunu = false
        this.modalNumPoliceAttest = true
      } else if(this.detailsContrat.partenaireid.societe == 'AMSA'){
        this.Axa = false
        this.Askia = false
        this.Sunu = false
        this.Amsa = true
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'SALAMA') {
        this.Sunu = false
        this.Askia = false
        this.Amsa = false
        this.Axa = false
        this.Salama = true
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'ALLIANZ') {
        this.Sunu = false
        this.Askia = false
        this.Amsa = false
        this.Axa = false
        this.Salama = false
        this.Allianz = true
        this.modalNumPoliceAttest = true
      }
      if (this.showAttestation == true) {
        this.showcontrat = false
      }
      if (this.detailsContrat.userid.profession == null) {
        this.detailsContrat.userid.profession = '-'
      }
    },
    downloadAttestationPdf() {
      let pdfName = this.detailsContrat.userid.prenom
      var canvas = document.getElementById('canvas')
      html2canvas(canvas).then(function (canvas) {
        var imgData = canvas.toDataURL('image/png')
        var doc = new jsPDF('landscape')
        doc.addImage(imgData, 'PNG', 10, 65)
        doc.save('Attestation-' + pdfName + '.pdf')
      })
      this.showAttestation = false
  
    },
    getContrat() {
        if (this.$store.state.user.role == "SuperAdmin"||this.$store.state.user.role=='Admin') {
            Api()
            .get("/superadmin/contrat/all")
            .then((resultat) => {
                this.listcontrat = resultat.data;
                this.totalRows = resultat.data.length;
            });
        }
    },
    checkedControl() {
      if (this.checked === true) {
        this.client_name = true
        this.user_name = null
      } else if(this.checked ===  false){
        this.client_name = false
        this.user_name = true
        this.NomPrenom = null
        this.PrenomSurCarte = null
        this.NomSurCarte = null
      }
    },
  
  
  
  
            SetDetailUser(details) {
                this.detailsUser = details
                this.showUser = true
            },
            SetDetailPartner(details) {
                this.detailsPartner = details
                this.showPartner = true
            },
            detailcontrat(item) {
                //this.getDevisArchivateDetails(item._id)
                this.detailsContrat = item;
                this.showcontrat = true;
  
                // this.detailsDevis = item
                // this.showdevis = true
            },
  
            DesarchivateDevis(devis) {
                let id = devis._id
                Api().post('devis/desarchivate/' + id)
                    .then(resp => {
                        this.$message.success('Devis desarchivé avec succés !', this.AlertMessage)
                        this.getDevis()
                    })
            },
  
            getDevisArchivateDetails(id)
            {
                //ArchivateDetails
                Api().get('devis/archivate/detail/'+id)
                    .then(resultat => {
                        this.ArchivateDetails = resultat.data.detail
                    })
                    .catch(err => {})
  
            },
  
  
            AlertMessage() {
                this.$message({
                    showClose: false,
                    duration : 5000
                });
            },
  
            SpecialUpdatePrime()
            {
                if(this.up_primenette!=null && this.up_primenette!=0 && this.up_accessoires!=null && this.up_accessoires!=0 && this.up_taxes!=null && this.up_taxes!=0)
                {
                    let pNette          = Number(this.up_primenette.replace(/[^0-9.-]+/g, ""))
                    let access          = Number(this.up_accessoires.replace(/[^0-9.-]+/g, ""))
                    let taxe            = Number(this.up_taxes.replace(/[^0-9.-]+/g, ""))
                    this.up_primettc    = pNette + access + taxe
  
                    const data = {
                        prime_nette:pNette,
                        accessoires:access,
                        taxe:taxe,
                        prime_ttc:this.up_primettc,
                        devisid:this.detailsDevis._id
                    }
                    Api().post("/devis/update/primes", data).then((result) => {
                        if(result.data.devis.nModified==1){
                            this.$message.success('Opération effectuée !', this.AlertMessage)
                            this.UpdatePrimeDevis = false
                            this.showdevis        = false
                            this.getDevis()
                        }else{
                            this.$message.error('Opération échouée !', this.AlertMessage)
                        }
                    }).catch((err) => {
  
                    });
  
                }else{
                    this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
                }
            },
  
            primeNetteCalculation()
            {
                if(this.up_primenette!=null && this.up_primenette!=0 && this.up_accessoires!=null && this.up_accessoires!=0 && this.up_taxes!=null && this.up_taxes!=0)
                {
                    let pNette      = Number(this.up_primenette.replace(/[^0-9.-]+/g, ""))
                    let access      = Number(this.up_accessoires.replace(/[^0-9.-]+/g, ""))
                    let taxe        = Number(this.up_taxes.replace(/[^0-9.-]+/g, ""))
                    this.up_primettc   = pNette + access + taxe
                }
  
            },
  
            JsonFormaterView()
            {
                if(this.JsonView==false)
                {
                    this.JsonView = true
                }else{
                    this.JsonView = false
                }
            },
  
            CheckIfTypeDefined(item){
                return typeof item
            },
  
            exportToExcel(){
                Api().get('/export/contrat/systeme')
                    .then(resultat => {
                        this.modalgeneration = true
                        let url = resultat.data.lien
                        setTimeout(() => (
                            this.modalgeneration = false,
                                window.open(url)), 7000
                        );
                    });
            },
  
            dateDiff: function () {
                if (this.dateEffet && this.duree && this.CalculDuree)
                {
                    let inter = dayjs(this.dateEffet).add(this.duree, this.CalculDuree).format('YYYY-MM-DD');
                    this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
                }
            },
  
            /*================*/
            SpecialUpdate() {
                if (this.duree && this.dateEffet && this.dateEcheance && this.numeroDevis) {
  
                    let data = {
                        devisid: this.detailsDevis._id,
                        duree: this.duree,
                        dateEffet: dayjs(this.dateEffet).format('YYYY-MM-DD'),
                        dateEcheance: dayjs(this.dateEcheance).format('YYYY-MM-DD'),
                        numeroDevis: this.numeroDevis
                    }
  
                    Api().post('/devis/update/special', data)
                        .then((result) => {
                            if (result.data.msg = "Devis modifié !") {
                                this.specialUpdatedevis = false
                            }
                        }).catch((err) => {
                        console.log('Nooooo !')
                    });
  
                } else {
                    this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
                }
  
            },
  
            // Fonction de download du devis en fichier pdf
            Downloa_devis: function (devis) {
                Api().get('devis/download/' + devis._id)
                    .then(response => {
                        this.modalgenerationloading = true;
                        let url = response.data.lien
                        let WinOpener = null
                        setTimeout(() => (
                            this.modalgenerationloading = false,
                                WinOpener = window.open(),
                                WinOpener.location = url
                        ), 7000);
                    })
            },
  
            Edit_devis: function (devis) {
  
                switch (devis.brancheid.branche) {
                    case 'Automobile':
                        Api().get('devis/' + devis._id)
                            .then(response => {
                                this.$store.dispatch('setDevisReopen', response.data.devis)
                                this.$router.go(this.$router.push({
                                    name: 'update_devis_auto'
                                }))
                            })
  
                        break;
  
                    case 'Habitation':
                        this.$router.go(this.$router.push({
                            name: 'update_devis_habitation'
                        }))
                        break;
  
                    case 'Voyage':
                        this.$router.go(this.$router.push({
                            name: 'update_devis_voyage'
                        }))
                        break;
  
                }
            },
  
  
            Remover() {
                Api().post('sinistre/remove')
                    .then(rep => {})
            },
  
  
            devisDeleteConfirmation(item) {
                this.modalDelete = true
                this.detailsDevis = item
            },
  
            ArchivateDevis(devis) {
                let id = devis._id
                Api().post('devis/archivate/' + id)
                    .then(resp => {
                        this.getDevis()
                    })
            },
  
            AddEtatUser() {
                Api().post('devis/addetatuser')
                    .then(resultat => {})
                    .catch(err => {})
            },
  
            DeletingDevis(devis)
            {
                if(this.Delete_reason)
                {
                    const Adevis = {
                        userid: store.state.user._id,
                        brancheid: devis.brancheid._id,
                        devisid: devis._id,
                        archive_reason: this.Delete_reason
                    }
                    Api().post('devis/delete', Adevis)
                        .then(resultat => {
                            if (resultat.data.rep == 1)
                            {
                                this.Delete_reason = ""
                                this.modalDelete = false
                                this.getDevis()
                                this.$message.success('Devis archivé avec succés !', this.AlertMessage)
  
                            }
                        })
  
                }else{
                    this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
                }
  
            },
  
            toggleDetails(item){
                this.detailsUser = item
                this.editUser = true
            },
  
  
  
  
  
  
  
            DeleteBrancheCourtier(item) {
                Api().post("/branchecourtier/delete", {id:item._id})
                    .then((result) => {
                        Api().post("/branchecourtier/get/all/", {userid:item.userid})
                            .then((response) => {
                                this.configUserDevisAssureur = response.data.partenaires
                            })
                            .catch((err) => {});
                    }).catch((err) => {
  
                });
            },
  
            AddbrancheConfig() {
                Api().post("/branchecourtier/create", {configuration:this.configUserDevisAssureur})
                    .then((result) => {
                        this.configUserDevisAssureur = []
                    }).catch((err) => {
                    console.log(err)
                });
            },
  
            pushConfig() {
                if(this.Selectedbranche && this.selectedAssureur)
                {
                    this.configUserDevisAssureur.push({
                        branche:this.Selectedbranche,
                        assureurs:this.selectedAssureur,
                        userid: this.selectedUser.id
                    })
                    this.Selectedbranche  = null
                    this.selectedAssureur = []
                }
            },
  
            loadConfigBranchePopUp(item) {
                this.modalSociete = true
                this.selectedUser = item
                Api().post("/branchecourtier/get/all/", {userid:item.id})
                    .then((response) => {
                        this.configUserDevisAssureur = response.data.partenaires
                    })
                    .catch((err) => {});
            },
  
            setCollapse(){
                this.collapse = "id_"+Date.now()+"_collapse"
            },
  
            MenuDelete(item)
            {
                Api().delete("/menu/"+item._id)
                    .then((result) => {
                        this.getUserMenu(this.detailsUser._id)
                    }).catch((err) => {
  
                });
            },
  
            pushMenu()
            {
                if(this.menu_name && this.userSubmenu.length >= 1)
                {
                    this.UserFinalMenu.push({
                        user : this.detailsUser.id,
                        theme:this.menu_name,
                        collapse:this.collapse,
                        config:this.userSubmenu
                    })
                    this.menu_name   = null
                    this.userSubmenu = []
                    this.collapse    = null
                }else{
                    this.$message.error('Remplissez les champs vides !', this.AlertMessage)
                }
            },
  
            create_user_menu()
            {
                if(this.UserFinalMenu.length >=1 )
                {
                    Api().post("/menu/add", this.UserFinalMenu)
                        .then((result) => {
                            this.$message.error('Opération éffectuée !', this.AlertMessage)
                            this.UserFinalMenu = []
                        }).catch((err) => {
                        console.log(err)
                    });
                }else{
                    this.$message.error('Le menu ne doit pas être vide !', this.AlertMessage)
                }
            },
  
            merge(){
                Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
                    .then((result) => {
                        this.showuser = false
                        if(result.data.result){
                            alert('Opération réussie')
                        }
                    }).catch((err) => {
                    console.log('=======err=========')
                });
            },
  
            detailuser(item) {
                this.getUserMenu(item._id)
                this.detailsUser = item
                this.showuser = true
            },
  
            ready: function () {
                var self = this;
  
                setInterval(function () {
                    self.$data.ticker = Date.now();
                }, 1000);
            },
  
            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },
  
            showAlert() {
                this.dismissCountDown = this.dismissSecs
            },
  
            getUserList:function(){
                switch (this.$store.state.user.role) {
                    case 'Admin':
                        Api().get('/user/frontoffice/')
                            .then( resultat => {
                                this.listuser = resultat.data
                                this.totalRows = resultat.data.length
                            });
                        break;
  
  
                    case 'assureur':
                        Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
                            .then( resultat => {
                                this.listuser = resultat.data
                                this.totalRows = resultat.data.length
                            });
                        break;
  
                    default:
                        break;
                }
            },
  
            UpdateUser(item) {
  
                let idU = item.id
                Api().post('user/' + idU, item)
                    .then(response => {
                        if (response.data) {
                            this.showAlert()
                        }
                        this.showAlert()
                    })
                    .catch(err => {
  
                    })
  
            },
  
            DateFormater: function (TheDate) {
                if (TheDate) {
                    return dayjs(TheDate).format('DD-MM-YYYY')
                }
            },
  
            userDeleteConfirmation(item) {
  
                this.modalDelete = true
                this.detailsUser = item
  
            },
  
            DeletingUser(id) {
                Api().delete('user/delete/' + id)
                    .then(resultat => {
                        if (resultat.status == 200) {
                            this.modalDelete = false
                            this.getUserList()
                        }
                    })
                    .catch(err => {})
            },
  
            getUserMenu(id){
                Api().post('/menu/user/menu', {userid:id})
                    .then((result) => {
                        this.usermenu = result.data.menus
                    }).catch((err) => {
                    console.log(err)
                });
            },
  
  
  
  
  
  
  
  
            calcDims() {
                const tableWrapper = document.getElementById("table-wrapper")
                if (tableWrapper) this.width = tableWrapper.clientWidth
  
                if (!this.isMobile && tableWrapper) {
                    this.height = tableWrapper.clientHeight - 44
                }
  
                if (this.width < 480) {
                    this.pagination.small = true
                    this.pagination.layout = "prev, pager, next"
                } else if (this.width >= 480 && this.width < 700) {
                    this.pagination.small = false
                    this.pagination.layout = "prev, pager, next, ->, sizes"
                } else {
                    this.pagination.small = false
                    this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
                }
  
                this.ready = true
            },
            handleResize: _.throttle(function(e) {
                this.ready = false
                this.width = 0
                setTimeout(this.calcDims, 1000)
            }, 500),
            handleSelectionChange(val) {
                this.itemsChecked = val
            },
            init() {
                if (window.innerWidth <= 768) this.isMobile = true
            }
        },
        filters: {
            selected: function(value, sel) {
                if (!value) return ""
                if (!sel) return value
  
                value = value.toString()
                sel = sel.toString()
  
                const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
                if (startIndex !== -1) {
                    const endLength = sel.length
                    const matchingString = value.substr(startIndex, endLength)
                    return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
                }
  
                return value
            }
        },
        created() {
            this.init()
            this.getContrat()
        },
        mounted() {
            if (store.state.isUserLoggedIn && store.state.user.role !== "User") {
                Api()
                    .get("/user/clients")
                    .then((resultat) => {
                    this.listuser = resultat.data;
                    });
                this.getContrat();
                } else {
                this.$router.go(this.$router.push("/login"));
            }
  
            //ie fix
            if (!window.Number.parseInt) window.Number.parseInt = parseInt
  
            this.calcDims()
        }
    }
  </script>
  
  <style lang="scss" scoped>
    @import "../../../assets/scss/_variables";
  
    .page-table {
        .toolbar-box {
            margin-bottom: 10px;
  
            .card-shadow--medium {
                &.el-input,
                &.el-select {
                    border-radius: 4px;
  
                    input {
                        border-color: transparent;
                        background-color: lighten($background-color, 2%);
                        color: $text-color;
  
                        &:hover {
                            border-color: #c0c4cc;
                        }
                        &:focus {
                            border-color: $text-color-accent;
                        }
                    }
                }
  
                &.el-button {
                    border-color: transparent;
                }
            }
        }
  
        .clickable {
            cursor: pointer;
            text-decoration: underline;
            font-weight: bold;
        }
  
        .sel-string {
            .sel {
                background: transparentize($text-color, 0.8);
                border-radius: 5px;
                //text-transform: uppercase;
            }
        }
    }
  
    @media (max-width: 768px) {
        .page-table {
            .toolbar-box {
                display: block;
                overflow: hidden;
                font-size: 80%;
                padding-bottom: 10px;
  
                & > * {
                    display: inline-block;
                    min-width: 120px;
                    height: 22px;
                    //background: rgba(0, 0, 0, 0.04);
                    margin-bottom: 16px;
                }
            }
        }
    }
    .button-filtre{
        padding: 12px!important;
        margin : 0 15px!important;
        background: #C0C7CF!important;
        border:none!important;
        color:#fff!important;
    }
  </style>
  