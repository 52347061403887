<template>
  <vue-scroll class="page-devis-voyage">
    <div class="page-header">
      <h1>Nouveau<span class="text-color"> coupon</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">

      <el-col>
        <el-row :gutter="20" style="margin-top:15px">
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Période du coupon *</label>
              <el-date-picker style="width: 100%!important;" v-model="periode" type="daterange" range-separator="au"
                start-placeholder="Date de début" end-placeholder="Date de fin" format="dd-MM-yyyy">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <label>Type de reduction *</label>
              <el-select style="width: 100%;" v-model="coupon.type" placeholder="Selectionner">
                <el-option v-for="(item, i) in types" :key="i" :label="item" :value="item" />
              </el-select>

            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <label>Reduction *</label>
              <el-input placeholder="Nom de la coupon" type="number" v-model="coupon.reduction">
              </el-input>
            </div>
          </el-col>
        </el-row>
      
        <el-row :gutter="20" style="margin-top:20px">
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Client</label>
              <el-select style="width: 100%;" v-model="coupon.client" placeholder="Selectionner" multiple filterable
                allow-create>
                <el-option v-for="client in liste_clients" :key="client._id" :label="client.telephone_port"
                  :value="client._id" />
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Branche</label>
              <el-select style="width: 100%;" v-model="coupon.branche" placeholder="Selectionner" multiple filterable
                allow-create>
                <el-option v-for="branche in liste_branches" :key="branche._id" :label="branche.branche"
                  :value="branche._id" />
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Assureurs</label>
              <el-select style="width: 100%;" v-model="coupon.assureur" placeholder="Selectionner" multiple filterable
                allow-create>
                <el-option v-for="assur in liste_assureurs" :key="assur.id" :label="assur.societe" :value="assur.id" />
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:15px">
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Période du contrat </label>
              <el-date-picker style="width: 100%!important;" v-model="periode_contrat" type="daterange"
                range-separator="au" start-placeholder="Date de début" end-placeholder="Date de fin" format="dd-MM-yyyy">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Plateforme</label>
              <el-select style="width: 100%;" v-model="coupon.plateforme" placeholder="Selectionner" filterable
                allow-create>
                <el-option v-for="p in plateformes" :key="p" :label="p" :value="p" />
              </el-select>
            </div>
          </el-col>
          <el-col :span="6">


          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:15px">
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Partenaire</label>
              <el-select style="width: 100%;" v-model="coupon.codep" placeholder="Selectionner" multiple filterable
                allow-create>
                <el-option v-for="part in liste_partenaires" :key="part._id" :label="part.societe"
                  :value="part.code_partenaire" />
              </el-select>
            </div>
          </el-col>

          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Agent</label>
              <el-select style="width: 100%;" v-model="coupon.courtier" placeholder="Selectionner" multiple filterable
                allow-create>
                <el-option v-for="court in liste_courtiers" :key="court.id" :label="court.societe" :value="court.id" />
              </el-select>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Generer un <span class="text-color "> code coupon</span> <el-checkbox
                  v-model="coupon.withcode"></el-checkbox></label>
            </div>
          </el-col>
        </el-row>
      </el-col>


      <el-col style="margin-top:20px">
        <el-button @click="createCoupon" type="primary" round>Créer</el-button>
      </el-col>
    </div>


  </vue-scroll>
</template>

<script>

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

import Coupon from '../../../models/Coupon.class'
export default {
  name: "coupon_create",

  data() {
    return {
      plateformes: ["App-Mobile", "Front", "Dash"],
      periode: [],
      periode_contrat: [],
      isMobile: false,
      coupon: new Coupon(),
      types: ['pourcentage', 'valeur'],
      liste_branches: [],
      liste_assureurs: [],
      liste_courtiers: [],
      liste_partenaires: [],
      liste_clients: [],
      Deb: null,
      selectedcarbu: '',
      show: true,
      //fin initialisation des models d'assuraf
    }
  },
  mounted() {
    this.getListeBranches()
    this.getListeAssureurs()
    this.getListeCourtiers()
    this.getListePartenaires()
  },

  methods: {
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
    // Creation de branche
    createCoupon() {
      console.log(this.coupon)
      if (this.periode.length != 0 && this.coupon.reduction && this.coupon.type) {
        this.coupon.du = this.periode[0]
        this.coupon.au = this.periode[1]
        if(this.periode_contrat.length != 0){
          this.coupon.debut_contrat = this.periode_contrat[0]
          this.coupon.fin_contrat = this.periode_contrat[1]
        }
        if(this.coupon.plateforme){
          this.coupon.plateforme = [this.coupon.plateforme]
        }
        this.coupon.createur = store.state.user._id,
          Api().post('/coupons/add', this.coupon)
            .then(rep => {
              if (rep.status == 201) {
                this.$message.success('Coupon crée avec succés !', this.AlertMessage)
                this.coupon = new Coupon()
                this.peroode = []
              }
            }).catch(err => { console.log(err) })
      } else {
        this.$message.error('tous les champs avec (*) sont obligatoires !', this.AlertMessage)
      }
    },

    getListeBranches() {
      Api().get('/branche')
        .then(resultat => {
          this.liste_branches = resultat.data
          console.log(this.liste_branches)
        });
    },
    getListeClients() {
      Api().get('/user/all')
        .then(resultat => {
          this.liste_clients = resultat.data.users
          console.log(this.liste_clients)
        });
    },
    getListeAssureurs() {
      Api().get('/partenaire/assureur')
        .then(response => {
          this.liste_assureurs = response.data.partenaires
          console.log(this.liste_assureurs)
        })
    },
    getListeCourtiers() {
      Api().get('/partenaire/courtier')
        .then(response => {
          this.liste_courtiers = response.data.partenaires //.assureurs_partenaires
          console.log(this.liste_courtiers)
        })
    },
    getListePartenaires() {
      Api().get('/partenaires/all')
        .then(resultat => {
          this.liste_partenaires = resultat.data.partenaires
          console.log(this.liste_partenaires)
        });
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000
      });
    },

  },
  created() {
    this.init()
    this.getListeClients()
  },
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.text-color {
  color: #ffa409
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }

          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }

          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }

          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
