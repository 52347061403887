import Vue from 'vue'
import Vuex from 'vuex'
import persistanteState from 'vuex-persistedstate'
export { default as types } from './store/types'

/*import * as Cookies from 'js-cookie'*/
import bordereaux from './store/bordereaux/bordereaux.module'

Vue.use(Vuex)
export default new Vuex.Store({
	strict: true,
    plugins: [persistanteState()],
	state: {
		layout: {
			navPos: 'left', //top, bottom, left, right, false
			toolbar: 'top', //top, bottom, false
			footer: true, //above, below, false
			boxed: false, //true, false
			roundedCorners: false, //true, false
			viewAnimation: 'fade-top' // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
		},
        code_partenaire: null,
        token: null,
        refresh: null,
		logged: false, //true,
        user: {},
        devis: {},
        devispayement: {},
        Newcontrat: {},
        Newcourtier: {},
        DevisReopen: {},
        Client: {},
        orderid: {},
        modePaiement: {},
        listeBeneficiaire: {},
        isUserLoggedIn: false,
        roles: null,
        apikey : null,
        paraphrase : null
	},
	
	getters: {
		/*==================== Partie Assuraf =======================*/ 
		username: state => {
            if(state.token && state.user){
                return state.user.nom +' '+ state.user.prenom
            }else{
                return
            }
        },
		/*##########################################################*/ 


		layout(state, getters) {
			return state.layout
		},
		navPos(state, getters) {
			return state.layout.navPos
		},
		toolbar(state, getters) {
			return state.layout.toolbar
		},
		footer(state, getters) {
			return state.layout.footer
		},
		boxed(state, getters) {
			return state.layout.boxed
		},
		roundedCorners(state, getters) {
			return state.layout.roundedCorners
		},
		viewAnimation(state, getters) {
			return state.layout.viewAnimation
		},
		isLogged(state, getters) {
			return state.logged
		},
	},

	mutations: {
		setLayout(state, payload) {
			if(payload && payload.navPos !== undefined)
				state.layout.navPos = payload.navPos

			if(payload && payload.toolbar !== undefined)
				state.layout.toolbar = payload.toolbar
				
			if(payload && payload.footer !== undefined)
				state.layout.footer = payload.footer
		
			if(payload && payload.boxed !== undefined)
				state.layout.boxed = payload.boxed

			if(payload && payload.roundedCorners !== undefined)
				state.layout.roundedCorners = payload.roundedCorners

			if(payload && payload.viewAnimation !== undefined)
				state.layout.viewAnimation = payload.viewAnimation
		},
		setLogin(state, payload) {
			state.logged = true
		},
        
        setSwitchData(state, data){
            if (data !== null) {
                state.apikey = data.api_key_salt,
                state.paraphrase = data.api_key_paraphrase
            }
        },

		setLogout(state, payload) {
			state.layout.navPos = null
			state.layout.toolbar = null
			state.logged = false
            state.apikey ="",
            state.paraphrase = ""
		},
		
        setToken(state, user) {
            if (user !== null) {
                if (user.token) {
                    state.isUserLoggedIn = true
                    state.token = user.token
                    state.refresh = user.refresh
                    state.user = user
                    state.code_partenaire = user.code_partenaire
                    state.apikey = user.apikey,
                    state.paraphrase = user.paraphrase
                }
            } else {
                state.isUserLoggedIn = false
                state.token = null
                state.refresh = null
                state.user = {}
                state.code_partenaire = null
                state.apikey ="",
                state.paraphrase = ""
            }

        },
        refreshToken(state, user){
            state.token = user.token
            state.refresh = user.refresh
        },
        setUser(state, user){
            state.user = user
        },
        setRoles(state, roles) {
            state.roles = roles.AllRoles
            const superAdmin = state.roles.find((role) => role.name === "SuperAdmin");
                if (superAdmin) {
                    state.roles.splice(state.roles.indexOf(superAdmin), 1);

                }
        },
        setDevis(state, data){
            state.devis = data
        },
        setDevisPayement(state, devis) {
            state.devispayement = devis
        },
        setNewcontrat(state, contrat) {
            state.Newcontrat = contrat
        },
        setNewcourtier(state, courtier) {
            state.Newcourtier = courtier
        },
        setDevisReopen(state, devis) {
            state.DevisReopen = devis
        },
        setClient(state, client) {
            state.Client = client
        },
        
        setOrderId(state, orderid){
            state.orderid = orderid
        },
        setmodePaiement(state, mode){
            state.modePaiement = mode
        },
        setListeBeneficiaire(state, voyageurs){
            state.listeBeneficiaire = voyageurs
        }
		/*############################################################*/
	},

	actions: {
		/*==================== Partie Assuraf ========================*/
        setSwitchData({commit}, data){
            commit('setSwitchData', data)
        },

		setToken({commit}, token){
            commit('setToken', token)
        },
        refreshToken({commit}, object){
            commit('refreshToken', object)
        },
        setUser({commit}, user){
            commit('setUser', user)
        },
        setRoles({ commit }, roles) {
            commit('setRoles', roles)
        },
        setDevis({commit}, devis){
            commit('setDevis', devis)
        },
        setDevisPayement({commit}, devispayement) {
            commit('setDevisPayement', devispayement)
        },
        setNewcontrat({commit}, Newcontrat) {
            commit('setNewcontrat', Newcontrat)
        },
        setNewcourtier({commit}, Newcourtier) {
            commit('setNewcourtier', Newcourtier)
        },
        setDevisReopen({commit}, DevisReopen) {
            commit('setDevisReopen', DevisReopen)
        },
        setClient({commit}, Client) {
            commit('setClient', Client)
        },
        setOrderId({commit}, orderid){
            commit('setOrderId', orderid)
        },
        setmodePaiement({commit}, mode){
            commit('setmodePaiement', mode)
        },
        setListeBeneficiaire({commit}, voyageurs){
            commit('setListeBeneficiaire', voyageurs)
        }
		/*############################################################*/ 
	},
    modules: {
        bordereaux
    }

})
