import Api from './Api'
import customPassword from "./password_generator"
import dayjs from 'dayjs'
import rolesService from "./roles.service";
export default {
    // signup (credentials){
    //     return Api().post('user/signup', credentials)
    // },

    login(credentials){
      return Api().post('user/connect', credentials)
    },
    checkclient(number){
      return Api().get('/user/phone/' + number)
    },
    register(client){
      console.log(client)
      client.password = customPassword.customPassword(client.nom, client.prenom)
      client.confirmPass =  client.password
      client.created = dayjs(new Date()).format('YYYY-MM-DD')
      
      return Api().post('user/register', client)
    }
}