<template>
  <div class="login-page flex">
    <div class="box grow scrollable align-vertical side-box box-left">
      <div class="align-vertical-middle wrapper text-center">
        <img
          class="image-logo"
          src="@/assets/images/logo.png"
          alt="logo-left"
        />
      </div>
    </div>
    <div class="box grow scrollable align-vertical side-box box-right">
      <div class="align-vertical-middle wrapper">
        <form class="form-box" @submit.prevent="userLogin()">
          <h2>Connectez-vous !</h2>

          <float-label class="styled">
            <el-input
            v-model="myuser.email"
              placeholder="Email / Tel"
            ></el-input>
          </float-label>

          <float-label class="styled">
            <el-input
            v-model="myuser.password"
              placeholder="Mot de passe"
              type="password"
              auto-complete="off"
            ></el-input>
          </float-label>
          
          <float-label class="styled">
            <el-input
            v-model="myuser.code_partenaire"
              placeholder="Code partenaire"
              type="text"
              auto-complete="off"
            ></el-input>
          </float-label>


          <div class="flex text-center center pt-10 pb-10">
            <el-button
              native-type="submit"
              plain
              size="small"
              class="login-btn pulse animated themed"
            >
              Se connecter
            </el-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../../store'
import Authservice from "../../../services/AuthenticationService";
import { types } from '../../../store'
import { mapState } from "vuex";
import RoleService from "../../../services/roles.service";
import { color } from "echarts";

export default {
  name: "Login2",
  data() {
    return {
      show_code_partenaire: true,
      pill: true,
      notifMessage: "",
      myuser: {
        email: "",
        password: "",
        code_partenaire: "",
      },

      model: {
        username: "",
        password: "",
        code_partenaire: "",
      },
      errors :[]
    };
  },
  computed: {
    ...mapState(["auth", "site"]),
  },
  methods: {
    checkCodePartner: function (event) 
    {
      if (this.myuser.email.length >= 7) {
        if (this.myuser.email.length == 7 && event.key == "-") {
          this.show_code_partenaire = false;
        }else{
          this.show_code_partenaire = true;
        }
      }
    },
    onSuccess(data) {
      this.$store.commit(types.SET_AUTH, data);
      this.$store.dispatch(types.FETCH_SITE);
      this.$router.push({
        path: data.redirect || "/",
      });
    },

    async userLogin() 
    {
      try 
      {
        if (this.myuser.email == "" || this.myuser.password == "" || this.myuser.code_partenaire == "")
        {
          this.$message.error("Email et mot de passe sont obligatoires !",this.AlertMessage);
        } else 
        {        
          const loginResp = await Authservice.login({
            login: this.myuser.email,
            password: this.myuser.password,
            code_partenaire: this.myuser.code_partenaire,
          });

          if (loginResp) 
          {
                if (loginResp.data.error) 
                {
                  this.$message.error("Email et mot de passe sont incorrect !", this.AlertMessage);
                } else if (loginResp.data.token) 
                {
                  this.$store.dispatch("setToken", loginResp.data);
                  this.userLogin = {};
                  const roles = await RoleService.liste();
                  this.$store.dispatch("setRoles", roles.data);
                  this.$message.success("User connected",this.AlertMessage);
                  this.$router.push("/home");

                }
            }
        }
      } catch (err) {
        console.log(err)
        this.$message.error("Email et mot de passe sont incorrect !",this.AlertMessage);
      }
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000,
      });
    },
    errorNotif() {
      this.$notify({
        title: "Error",
        message: "Email et mot de passe obligatoire !",
        position: "bottom-right",
        type: "error",
      });
    },
    WrongCredentialsNotif() {
      this.$notify({
        title: "Error",
        message: "Email ou mot de passe erroné !",
        position: "bottom-right",
        type: "error",
      });
    },
    successNotif() {
      this.$notify({
        title: "Success",
        message: "This is a success message",
        type: "success",
      });
    }
  },
  mounted() {
    if (store.state.isUserLoggedIn) {
      this.$router.go(this.$router.push("/home"));
    }
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";

.login-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 !important;

  .side-box {
    .wrapper {
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
    }
  }

  .box-left {
    background-image: url("../../../assets/images/login2.jpg");
    background-size: cover;
    background-position: 50% 50%;

    .wrapper {
      .image-logo {
        width: 90%;
        max-width: 300px;
        margin-top: 20px;
      }
    }
  }
  .box-right {
    background: white;
    color: var(--background-color);
  }

  .form-box {
    width: 100%;
    max-width: 340px;
    padding: 30px;
    box-sizing: border-box;
    margin: 20px auto;
    //background: transparent;

    a {
      font-size: 14px;
      color: var(--color-accent);
      text-decoration: none;
      font-weight: 500;
    }

    .image-logo {
      width: 180px;
      margin: 0px auto;
      margin-bottom: 70px;
      display: block;
    }

    .login-btn {
      background: rgba(var(--color-accent-rgb), 0.2);
      color: var(--text-color);
      border-color: var(--text-color);
      border-width: 2px;
      font-weight: bold;
      border-radius: 0;

      &:hover {
        background: rgba(var(--color-accent-rgb), 1);
        border-color: var(--text-color);
        color: var(--text-color);
      }
    }
  }
}

@media (max-width: 1200px) {
  .login-page {
    .box-left {
      .wrapper {
        .h-big {
          font-size: 50px;
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .login-page {
    .box-left {
      .wrapper {
        .h-big {
          font-size: 30px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .login-page {
    display: block;
    overflow: auto;

    .side-box {
      display: block;
    }
  }
}
</style>
