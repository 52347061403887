import { render, staticRenderFns } from "./back_office_user.vue?vue&type=template&id=6723f094&scoped=true"
import script from "./back_office_user.vue?vue&type=script&lang=js"
export * from "./back_office_user.vue?vue&type=script&lang=js"
import style0 from "./back_office_user.vue?vue&type=style&index=0&id=6723f094&prod&lang=scss&scoped=true"
import style1 from "./back_office_user.vue?vue&type=style&index=1&id=6723f094&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6723f094",
  null
  
)

export default component.exports