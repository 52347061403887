<template>
  <div
    class="page-table column scrollable only-y"
    :class="{ flex: !isMobile, overflow: isMobile }"
  >
    <div class="page-header">
      <h1>
        Paramétrage des taux de
        <span style="color: #ffa409"> commissions</span>
        <!-- <theme-picker style="float: right"></theme-picker> -->
      </h1>
    </div>

    <div class="mb-3">
      <el-button type="primary" @click="createParams = true" round
        >Ajouter un taux</el-button
      >
    </div>

    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
          placeholder="Rechercher parametre de commission..."
          prefix-icon="el-icon-search"
          v-model="search"
          clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom: 15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top: 15px">
          <el-input
            placeholder="Rechercher parametre de commission..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />

    <div
      class="table-box card-base card-shadow--medium box grow"
      id="table-wrapper"
      v-loading="!ready"
      v-if="!isMobile"
    >
      <el-table
        :data="listInPage"
        style="width: 100%"
        :height="height"
        v-if="ready"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          label="Branche"
          min-width="100"
          prop="branche"
        ></el-table-column>
        <el-table-column
          label="Assureur"
          min-width="120"
          fixed
          prop="assureur"
        ></el-table-column>
        <el-table-column
          label="Taux de commission(%)"
          min-width="200"
          prop="tauxCommission"
        ></el-table-column>
        <el-table-column
          label="Qp Access(%)"
          min-width="150"
          prop="qpaccess"
        ></el-table-column>

        <el-table-column label="Date création" prop="created" min-width="200">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.created) }}
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="Actions"
          prop="action"
          min-width="140"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="detailparametre(scope.row)"
              type="primary"
              icon="el-icon-view"
              circle
            ></el-button>
            <el-button
              size="mini"
              type="primary"
              @click="toggleDetails(scope.row)"
              icon="el-icon-edit"
              circle
            ></el-button>
            <el-button
              size="mini"
              @click="parametreDeleteConfirmation(scope.row)"
              type="danger"
              icon="el-icon-delete"
              circle
              title="Supprimer"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="ready"
        :small="pagination.small"
        :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes"
        :page-size.sync="pagination.size"
        :layout="pagination.layout"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog width="70%" title="Détails" :visible.sync="showparams">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div >
                <ul style="list-style: none">
                  <li>
                    Assureur :
                    <span class="to-right assuraf-orange">{{
                      detailsParams.assureur
                    }}</span>
                  </li>
                  <li>
                    Branche :
                    <span class="to-right assuraf-orange">{{
                      detailsParams.branche
                    }}</span>
                  </li>
                  <li>
                    Taux de commission :
                    <span class="to-right assuraf-orange">{{
                      detailsParams.tauxCommission
                    }}</span>
                  </li>
                  <li>
                    QP access :
                    <span class="to-right assuraf-orange">{{
                      detailsParams.qpaccess
                    }}</span>
                  </li>
                  <li>
                    Date de creation :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailsParams.created)
                    }}</span>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top: 15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center !important">
                <!-- <img class="justify-center" :src="detailsAssu.logo" width="200" alt="logo assureur"> -->
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
      title="Suppression parametre"
      width="50%"
      :visible.sync="modalDelete"
      v-if="modalDelete == true"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous supprimer ce parametre ?</span>
          </div>
          <div >
            <ul style="list-style: none">
              <li>
                <h3>
                  Assureur :
                  <span class="to-right assuraf-orange">{{
                    detailsParams.assureur
                  }}</span>
                </h3>
              </li>
              <li>
                <h3>
                  Branche :
                  <span class="to-right assuraf-orange">{{
                    detailsParams.branche
                  }}</span>
                </h3>
              </li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top: 15px">
        <el-button
          type="danger"
          round
          @click="DeletingParametre(detailsParams._id)"
          >Oui</el-button
        >
        <el-button type="primary" round @click="modalDelete = false"
          >Non</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      width="70%!important"
      title="Formulaire de modification"
      :visible.sync="editParams"
      v-if="editParams == true && !isMobile"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="12">
              <div>
                <label>Assureur</label>
                <el-select
                  style="width: 100% !important"
                  v-model="detailsParams.assureur"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="params in listassureur"
                    :key="params.id"
                    :label="params.societe"
                    :value="params.id"
                    >{{ params.societe }}</el-option
                  >
                </el-select>
                {{ detailsParams.assureur }}
                <!-- <el-input
                  v-model="detailsParams.assureur"
                  placeholder="Assureur..."
                  class="input-with-select"
                ></el-input> -->
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <label>Branche</label>
                <el-select
                  style="width: 100% !important"
                  v-model="detailsParams.branche"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="params in listbranche"
                    :key="params._id"
                    :label="params.branche"
                    :value="params._id"
                    >{{ params.branche }}</el-option
                  >
                </el-select>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <label>Taux de commission</label>
                <el-input
                  v-model="detailsParams.tauxCommission"
                  @keyup.enter="numberValide"
                  placeholder="Assureur..."
                  class="input-with-select"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <label>QP access</label>
                <el-input
                  v-model="detailsParams.qpaccess"
                  @keyup.enter="numberValide"
                  placeholder="Assureur..."
                  class="input-with-select"
                ></el-input>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="updateParametre()"
            >Modifier</el-button
          >
          <el-button type="danger" round @click="editParams = false"
            >Annuler</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="70%!important"
      title="Formulaire de création"
      :visible.sync="createParams"
      v-if="createParams == true && !isMobile"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="12">
              <div>
                <label>Assureur</label>
                <el-select
                  style="width: 100% !important"
                  v-model="assureur"
                  placeholder="Selectionner"
                >
                  <el-option
                    v-for="params in listassureur"
                    :key="params.id"
                    :label="params.societe"
                    :value="params.id"
                    >{{ params.societe }}</el-option
                  >
                </el-select>
                <!-- <el-input
                  v-model="params.assureur"
                  placeholder="Assureur..."
                  class="input-with-select"
                ></el-input> -->
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <label>Branche</label>
                <el-select
                  style="width: 100% !important"
                  v-model="branche"
                  placeholder="Selectionner ---"
                >
                  <el-option
                    v-for="params in listbranche"
                    :key="params._id"
                    :label="params.branche"
                    :value="params._id"
                    >{{ params.branche }}</el-option
                  >
                </el-select>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <label>Taux de commission</label>
                <el-input
                  v-model="tauxCommission"
                  @keyup.enter="numberValide"
                  placeholder="Assureur..."
                  class="input-with-select"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <label>QP access</label>
                <el-input
                  v-model="qpaccess"
                  @keyup.enter="numberValide"
                  placeholder="Assureur..."
                  class="input-with-select"
                ></el-input>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="createParametre()"
            >Valider</el-button
          >
          <el-button type="danger" round @click="Annuler()">Annuler</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import lib from "../../../libs/index";
import Api from "../../../services/Api";
import store from "../../../store";
export default {
  name: "ParametreCommission",
  data() {
    return {
      dismissCountDown: 0,
      dismissSecs: 3,
      listbranche: [],
      assureur: null,
      branche: null,
      qpaccess: null,
      tauxCommission: null,
      listassureur: [],
      params: {
        assureur: null,
        branche: null,
        tauxCommission: 0,
        qpaccess: 0,
      },
      createParams: false,
      editParams: false,
      menu_name: "",
      detailsParams: {},
      showparams: false,
      assureurs: [],
      collapse: null,
      totalRows: null,
      filter: null,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      ready: false,
      itemsChecked: [],
      listparams: [],

      totalRows: null,
      modalDelete: false,
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false,
      },
      MenuHeader: [
        { key: "theme", label: "Libellé" },
        { key: "collapse", label: "Collapse" },
        { key: "sousmenu", label: "Sous menu" },
        { key: "action", label: "action" },
      ],
      fields: [
        {
          key: "assureur",
          label: "Assureur",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "branche",
          label: "Branche",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "tauxCommission",
          label: "Taux commission",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "qpaccess",
          label: "QP access",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "created",
          label: "Date Creation",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
    };
  },
  computed: {
    listFiltered() {
      return this.listparams.filter((obj) => {
        let ctrl = false;
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true;
        }
        return ctrl;
      });
    },
    listSortered() {
      let prop = this.sortingProp;
      let order = this.sortingOrder;
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = "";
          let val2 = "";

          val1 = item1[prop];
          val2 = item2[prop];
          if (order === "descending") {
            return val2 < val1 ? -1 : 1;
          }
          return val1 < val2 ? -1 : 1;
        })
      );
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage;
      let to = from + this.itemPerPage * 1;
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to);
    },
    total() {
      return this.listFiltered.length;
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.pagination.page = val;
      },
    },
    itemPerPage() {
      return this.pagination.size;
    },
    selectedItems() {
      return this.itemsChecked.length || 0;
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    itemPerPage(val) {
      this.ready = false;
      this.currentPage = 1;

      setTimeout(() => {
        this.ready = true;
      }, 500);
    },
    search(val) {
      this.currentPage = 1;
    },
  },
  methods: {
    numberValide(object) {
      console.log("============= e ==================");
      console.log(e);
      console.log("CHECKING ENTER");
      console.log(object);

      if (object.match(/[0-9]/g)) {
        console.log(true);
      } else {
        console.log(false);
      }

      //value.match(/[0-9]/g) !== null
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000,
      });
    },
    Annuler() {
      this.createParams = false;
      this.assureur = this.branche = this.tauxCommission = this.qpaccess = null;
    },
    updateParametre() {
      if (isNaN(this.detailsParams.tauxCommission)) {
        this.$message.error(
          "Taux de commission doit être numeric !",
          this.AlertMessage
        );
        return;
      }
      if (isNaN(this.detailsParams.qpaccess)) {
        this.$message.error(
          "Qpaccess doit être numeric !",
          this.AlertMessage
        );
        return;
      }
      let item = this.detailsParams;
      if (
        this.detailsParams.assureur != null &&
        this.detailsParams.branche != null
      ) {
        Api()
          .patch(lib.sdk.parametre.update, item)
          .then((resultat) => {
            this.$message.success(
              "Modification effectuée avec succés !",
              this.AlertMessage
            );
            this.getParametreList();
            this.editParams = false;
          });
      } else {
        this.$message.error(
          "Tous les champs sont obligatoires !",
          this.AlertMessage
        );
      }
    },
    createParametre() {
      if (isNaN(this.tauxCommission)) {
        this.$message.error(
          "Taux de commission doit être numeric !",
          this.AlertMessage
        );
        return;
      }
      if (isNaN(this.qpaccess)) {
        this.$message.error(
          "Qpaccess doit eêtre numeric !",
          this.AlertMessage
        );
        return;
      }
      if (this.assureur != null && this.branche != null) {
        Api()
          .post(lib.sdk.parametre.add, {
            assureur: this.assureur,
            branche: this.branche,
            tauxCommission: this.tauxCommission ,
            qpaccess: this.qpaccess,
          })
          .then((resultat) => {
            this.$message.success(
              "Nouveau parametre de commission ajouté !",
              this.AlertMessage
            );
            this.getParametreList();
            this.createParams = false;
            this.assureur = this.branche = "";
            this.tauxCommission = this.qpaccess = null;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error(
          "Tous les champs sont obligatoires !",
          this.AlertMessage
        );
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },

    getBranche() {
      Api()
        .get("/branche")
        .then((resultat) => {
          this.listbranche = resultat.data;
        });
    },
    getAssureur() {
      Api()
        .get("/partenaire/assureur")
        .then((resultat) => {
          this.listassureur = resultat.data.partenaires;
        });
    },
    DeletingParametre(id) {
      Api()
        .delete(lib.sdk.parametre.delete + id)
        .then((resultat) => {
          if (resultat.status == 200) {
            this.modalDelete = false;
            this.getParametreList();
          }
        })
        .catch((err) => {});
    },
    parametreDeleteConfirmation(item) {
      this.modalDelete = true;
      this.detailsParams = item;
    },
    setCollapse() {
      this.collapse = "id_" + Date.now() + "_collapse";
    },
    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format("DD-MM-YYYY H:m:s");
      }
    },
    detailparametre(item) {
      this.detailsParams = item;
      this.showparams = true;
    },
    handleSelectionChange(val) {
      this.itemsChecked = val;
    },

    toggleDetails(item) {
      this.detailsParams = item;
      this.editParams = true;
    },
    getParametreList() {
      Api()
        .get(lib.sdk.parametre.all)
        .then((result) => {
          this.listparams = result.data;
          this.totalRows = result.data.length;

          // this.assureur = this.listparams.filter(x => x)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    isTablette() {
      if (screen.width > 425 && screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
    isDesktop: function () {
      if (screen.width > 768) {
        return true;
      } else {
        return false;
      }
    },
    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper");
      if (tableWrapper) this.width = tableWrapper.clientWidth;

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44;
      }

      if (this.width < 480) {
        this.pagination.small = true;
        this.pagination.layout = "prev, pager, next";
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false;
        this.pagination.layout = "prev, pager, next, ->, sizes";
      } else {
        this.pagination.small = false;
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes";
      }

      this.ready = true;
    },
    handleResize: _.throttle(function (e) {
      this.ready = false;
      this.width = 0;
      setTimeout(this.calcDims, 1000);
    }, 500),
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== "User") {
      this.getParametreList();
    } else {
      this.$router.go(this.$router.push("/login"));
    }
    if (!window.Number.parseInt) window.Number.parseInt = parseInt;

    this.getBranche();
    this.getAssureur();
    this.calcDims();
  },
};
</script>

<style>
</style>