<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>
        Liste des
        <span style="color: #ffa409">bordereaux</span>
        <!-- <theme-picker style="float: right"></theme-picker> -->
      </h1>
    </div>


    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input placeholder="Recherche de bordereaux..." prefix-icon="el-icon-search" v-model="search" clearable>
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input placeholder="Recherche de bordereaux..." prefix-icon="el-icon-search" v-model="search" clearable>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />
    <!-- <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
      <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading"
        @click="exportToExcel()">Exporter en Excel</el-button>
    </div> -->

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready"
      v-if="!isMobile">
      <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready">
        <el-table-column label="Date création" prop="created" min-width="120" fixed>
          <template slot-scope="scope">
            {{ DateFormater(scope.row.created) }}
          </template>
        </el-table-column>
        <el-table-column label="Branche" prop="brancheid" min-width="180">
          <template slot-scope="scope">
            <span v-html="branchesFilter(scope.row.brancheid)"></span>
          </template>
        </el-table-column>
        <el-table-column label="Assureur" prop="assureur" min-width="180">
          <template slot-scope="scope">
            <span v-html="assureursFilter(scope.row.partenaireid)"></span>
          </template>
        </el-table-column>

        <el-table-column label="Type bordereaux" prop="user" min-width="180">
          <template slot-scope="scope">
            {{ scope.row.type_borderaux }}
          </template>
        </el-table-column>
        <el-table-column label="Description" prop="user" min-width="180">

          <template slot-scope="scope">
            {{ scope.row.description }}
          </template>
        </el-table-column>
        <el-table-column label="Debut période" prop="debut" min-width="120">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.debut_periode) }}
          </template>
        </el-table-column>
        <el-table-column label="Fin période" prop="fin" min-width="120">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.fin_periode) }}
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="downloadBordered(scope.row)" type="primary" icon="el-icon-download"
              v-loading.fullscreen.lock="loading" circle></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout" :total="total">
      </el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready">
        <el-table-column label="Date création" prop="created" min-width="150" fixed>
          <template slot-scope="scope">
            {{ DateFormater(scope.row.created) }}
          </template>
        </el-table-column>
        <el-table-column label="Branche" prop="brancheid" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.brancheid.branche }}

          </template>
        </el-table-column>
        <el-table-column label="Assureur" prop="assureur" min-width="100">
          <template slot-scope="scope">
            {{ scope.row.partenaireid.societe }}
          </template>
        </el-table-column>

        <el-table-column label="Client" prop="user" min-width="180">
          <template slot-scope="scope">
            {{ formatUser(scope.row.userid) }}
          </template>
        </el-table-column>
        <el-table-column label="Debut période" prop="debut" min-width="120">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.debut_periode) }}
          </template>
        </el-table-column>
        <el-table-column label="Fin période" prop="fin" min-width="120">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.fin_periode) }}
          </template>
        </el-table-column>


        <el-table-column fixed="right" label="Actions" prop="action" min-width="150">
          <template slot-scope="scope">
            <el-button size="mini" @click="downloadBordered(scope.row)" type="primary" icon="el-icon-view"
              v-loading.fullscreen.lock="loading" circle></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout" :total="total">
      </el-pagination>
    </div>


    <el-dialog title="Suppression de branche" width="50%" :visible.sync="modalDelete" v-if="modalDelete == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous supprimer cette branche?</span>
          </div>
          <div>
            <ul style="list-style:none">
              <li>Branche : <span class="to-right assuraf-orange" style="font-size: 15px">{{
                detailbranche.branche
                  }}</span></li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="danger" round @click="Deletingbranche(detailbranche._id)">Oui</el-button>
        <el-button type="primary" round @click="modalDelete = false">Non</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "Liste de bordereaux",
  data() {
    return {
      listbranche: [],
      bordereaux: [],
      detailbranche: [],
      showpack: false,
      nombranche: null,
      ready: false,
      modalgenerationloading: false,
      optionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      ldevis: [],
      du: null,
      au: null,
      listdevis: [],
      modalgeneration: false,
      detailsDevis: [],
      showdevis: false,
      pageOptions: [5, 10, 15],
      modalInfo: {
        title: '',
        content: ''
      },
      userflette: [],
      fletteRows: null,
      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      voidField: false,
      isMobile: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listbranche,//users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      form: {
        debut_periode: null,
        fin_periode: null,
        branches: [],
        assureurs: [],
        description: "",
      },
    }
  },
  computed: {
    listFiltered() {
      return this.bordereaux.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    /*listFiltered() {
      return this.list.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },*/
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = ""
          let val2 = ""

          val1 = item1[prop]
          val2 = item2[prop]
          if (order === "descending") {
            return val2 < val1 ? -1 : 1
          }
          return val1 < val2 ? -1 : 1
        })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs["my-table"].$el;
        if (newVal === true) {
          tableScrollBody.classList.add("overflow-hidden");
        } else {
          tableScrollBody.classList.remove("overflow-hidden");
        }
      }
    },

    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    exportToExcel() {
      //API de genereation donne en excel
    },
    downloadBordered: async function (bordereau) {


      this.loading = true;
      const assureurs = []
      const branches = []
      bordereau.partenaireid.forEach((p) => { assureurs.push(p._id); });
      bordereau.brancheid.forEach((b) => { branches.push(b._id); });

      const uri = bordereau.type_borderaux == 'encaissement' ? "borderaux/exportBorderauxExpress" :
        bordereau.type_borderaux == 'commission' ? "borderaux/exportBorderauxCommission" : "borderaux/filtreExportReversement"

      alert(JSON.stringify(uri))
      const response = await Api().post(uri, {
        _id: bordereau._id,
        debut_periode: bordereau.debut_periode,
        description: bordereau.description,
        fin_periode: bordereau.fin_periode,
        partenaireid: assureurs,
        brancheid: branches,
      });
      if (response.data.saveBorderaux) {
        let url = response.data.lien;
        setTimeout(() => ((
          this.loading = false,
          this.dialogBordereaux = false,
          this.$message.success("les données sont exportées !", this.AlertMessage)
        ), window.open(url)), 5000);
      } else {
        this.loading = false,
          this.$message.error(
            "Le filtre a échoué !",
            this.AlertMessage
          );
      }
    },
    reset() {
      this.form = {
        debut_periode: null,
        fin_periode: null,
        branches: [],
        assureurs: [],
        description: "",
      };
    },
    formatUser(user) {
      return user.nom + " - " + user.prenom;
    },
    logoAssureur(assureur) {
      return assureur.logo;
    },
    toCreateBranche() {
      this.$router.go(this.$router.push('/branche/create'))
    },
    detailbrancheVue(item) {

      this.detailbranche = item
      this.showpack = true
    },
    getBranche() {
      Api().get('/branche')
        .then(resultat => {
          this.listbranche = resultat.data
          this.totalRows = this.listbranche.length
        });
    },

    // Creation de branche
    CreateBranche() {
      if (this.nombranche) {
        let br = {
          branche: this.nombranche,
          user: store.state.user._id
        }

        Api().post('/branche/add', br)
          .then(rep => {
            if (rep.data.message) {
              this.$message.error(rep.data.message, this.AlertMessage)
              this.nombranche = ""
              this.getBranche()

            }
          })
      } else {
        this.$message.error('Le nom de la branche obligatoire !', this.AlertMessage)
      }
    },


    BrancheDeleteConfirmation(item) {
      this.modalDelete = true
      this.detailbranche = item
    },

    Deletingbranche(id) {
      Api().delete('branche/' + id)
        .then(resultat => {
          if (resultat.data.rep == 1) {
            this.modalDelete = false
            this.$message.success('Branche supprimée avec succés !', this.AlertMessage)
            this.getBranche()
          }
        })
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000
      });
    },

    toggleDetails(item) {
      this.detailsUser = item
      this.editUser = true
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList: function () {
      switch (this.$store.state.user.role) {
        case 'Admin':
          Api().get('/user/frontoffice/')
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;


        case 'assureur':
          Api().get('/user/myuser/assureur/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
        .then(response => {
          if (response.data) {
            this.showAlert()
          }
          this.showAlert()
        })
        .catch(err => {

        })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },

    DeletingUser(id) {
      Api().delete('user/delete/' + id)
        .then(resultat => {
          if (resultat.status == 200) {
            this.modalDelete = false
            this.getUserList()
          }
        })
        .catch(err => { })
    },

    getUserMenu(id) {
      Api().post('/menu/user/menu', { userid: id })
        .then((result) => {
          this.usermenu = result.data.menus
        }).catch((err) => {
          console.log(err)
        });
    },








    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function (e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
    getBranches() {
      Api().get("/branche").then(assureurs => {
        if (assureurs) {
          this.listbranche = assureurs.data;
        } else {
          this.$message.error(
            "Erreur lors de la récuperation des branches !",
            this.AlertMessage
          );
        }
      })
    },

    getAssureurs() {
      Api().get("/partenaire").then(assureurs => {
        if (assureurs) {
          this.listassureur = assureurs.data.partenaires;
        } else {
          this.$message.error(
            "Erreur lors de la récuperation des assueurs !", this.AlertMessage
          );
        }
      })
    },
    getBordereaux() {
      Api().get("/borderaux/listBorderaux").then(bordereau => {
        if (bordereau) {
          this.bordereaux = bordereau.data.borderaux;
          this.totalRows = this.bordereaux.length;
        } else {
          this.$message.error(
            "Erreur lors de la récuperation des bordereaux!",
            this.AlertMessage
          );
        }

      })
    },

    branchesFilter(branche) {
      if (branche.length !== 0) {
        return `<div> ${this.lib(branche)}</div> `;
      }
      return "";
    },

    assureursFilter(assureurs) {
      if (assureurs.length !== 0) {
        return `<div> ${this.li(assureurs)}</div> `;
      }
      return "";
    },

    li(list) {
      let view = "";
      list.forEach((element) => {
        view = view + `<button class="ml-2" type="info">${element.societe}</button>`;
      });
      return view;
    },

    lib(list) {
      let view = "";
      list.forEach((element) => {
        view = view + `<button class="ml-2" type="info">${element.branche}</button>`;
      });
      return view;
    },

  },
  filters: {
    selected: function (value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
  },
  mounted() {

    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getBordereaux();
      this.getBranches();
      this.getAssureurs();
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {

      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }

          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      &>* {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
