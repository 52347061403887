<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Nouveau RS<span style="color:#ffa409"> Monitor</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="__resizeHanlder"/>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
        <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nom RS</label>
              <el-input type="primary" placeholder="Nom RS" v-model="nom_rs">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nombre de follower ASSURAF</label>
              <el-input type="number" placeholder="Nombre des followers" v-model="followers_count">
              </el-input>
            </div>
          </el-col>
         <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>État</label>
              <el-select style="width: 100%" v-model="etat_rs" placeholder="Selectionner">
                <el-option label="Activer" value="activate"></el-option>
                <el-option label="Desactiver" value="deactivate"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top:15px">
          <el-button @click="CreatTestimonial()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
        <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nom RS</label>
              <el-input type="primary" placeholder="Nom RS" v-model="nom_rs">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Nombre de follower ASSURAF</label>
              <el-input type="number" placeholder="Nombre des followers" v-model="followers_count">
              </el-input>
            </div>
          </el-col>
         <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>État</label>
              <el-select style="width: 100%" v-model="etat_rs" placeholder="Selectionner">
                <el-option label="Activer" value="activate"></el-option>
                <el-option label="Desactiver" value="deactivate"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top:15px">
          <el-button @click="CreatTestimonial()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "creer_assureur",
  data() {
    return {
        isMobile: false,
        nom_rs: null,
            followers_count: null,
            etat_rs: null,
            showpack: false,
            listTestomo: [],
            detailRS: null,
            etat: null,
            filter: null,
            totalRows: null,
            currentPage: 1,
            ToastTabSucess: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 2000,
                fitToScreen: true,
                type: 'success',
                className: 'custom-success-class'
            }, // Customized options for success toast
            ToastTabError: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 2000,
                fitToScreen: true,
                type: 'error'
            }, // Customized options for error toast
            perPage: 3,
            pageOptions: [5, 10, 15],
            fields: [{
                    key: 'created',
                    label: 'Date création'
                },
                {
                    key: 'nom_rs',
                    label: 'Nom RS'
                },
                {
                    key: 'followers_count',
                    label: 'Nombre de Follower'
                },
                {
                    key: 'etat_rs',
                    label: 'Etat du RS'
                },
                {
                    key: 'action',
                    label: 'Action'
                },

            ],



      voidField: false,

      Deb: null,
      selectedcarbu: '',

      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: []
    }
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
        this.getListPartenaire()
        this.getListBranche()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    this.initChart()
    this.initPie()
    window.addEventListener("resize", this.__resizeHanlder)
  },

  methods: {
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },

    CreatTestimonial() {
            if (this.nom_rs) {
                if (this.followers_count) {
                    if (this.etat_rs) {

                        const testimonial = {
                            nom_rs: this.nom_rs,
                            followers_count: this.followers_count,
                            etat_rs: this.etat_rs,
                            userid: store.state.user._id
                        }

                        Api().post('/RS/add', testimonial)
                            .then(res => {
                                this.nom_rs = ''
                                this.followers_count = ''
                                this.etat_rs = ''
                                this.Listing()
                                this.$message.success("RS Monitor crée avec succés !", this.AlertMessage)
                            })

                    } else {
                        this.$message.error("L'état du RS est obligatoire !", this.AlertMessage)
                    }
                } else {
                    this.$message.error("Le nombre de follower du RS est obligatoire !", this.AlertMessage)
                }
            } else {
                this.$message.error("Le nom du RS est obligatoire !", this.AlertMessage)
            }
        },

        UpdateRS(item) {

            if (item.nom_rs) {
                if (item.followers_count) {
                    if (item.etat_rs) {
                        Api().post('/RS/update', item)
                            .then(res => {
                                this.Listing()
                            })
                    } else {
                        this.$toasted.show("L'état du RS est obligatoire !", this.ToastTabError)
                    }
                } else {
                    this.$toasted.show("Le nombre de follower du RS est obligatoire !", this.ToastTabError)
                }
            } else {
                this.$toasted.show('Le nom du RS est obligatoire !', this.ToastTabError)
            }

        },

        Listing() {
            Api().get('/RS/listing')
                .then(res => {
                    this.listTestomo = res.data.rs
                    this.totalRows = res.data.rs.length
                })
        },


      
    DateFormater: function(TheDate) {
        if (TheDate) { return dayjs(TheDate).format('DD-MM-YYYY') }
    }, 

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700),
    initChart() {
      this.chart = echarts.init(document.getElementById("chart"))
      this.chart.setOption({
        //backgroundColor: '#394056',
        title: {
          top: 20,
          text: "SALES STATISTICS",
          textStyle: { fontWeight: "normal", fontSize: 16, fontFamily: "Nunito Sans" /*color: '#F1F1F3'*/ },
          left: "1%"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              /*color: '#57617B'*/
            }
          }
        },
        legend: {
          top: 40,
          icon: "rect",
          itemWidth: 14,
          itemHeight: 5,
          itemGap: 13,
          data: ["Product-A", "Product-B", "Product-C"],
          right: "4%",
          textStyle: { fontSize: 12, fontFamily: "Nunito Sans" /*color: '#F1F1F3'*/ }
        },
        grid: {
          top: 100,
          left: "-5px",
          right: "30px",
          bottom: "2%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                /*color: '#57617B'*/
              }
            },
            data: [
              "13:00",
              "13:05",
              "13:10",
              "13:15",
              "13:20",
              "13:25",
              "13:30",
              "13:35",
              "13:40",
              "13:45",
              "13:50",
              "13:55"
            ]
          }
        ],
        yAxis: [
          {
            show: false,
            type: "value",
            name: "(%)",
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                /*color: '#57617B'*/
              }
            },
            axisLabel: {
              margin: 10,
              textStyle: { fontSize: 14 }
            },
            splitLine: { lineStyle: { color: "#eee" /*color: '#57617B'*/ } }
          }
        ],
        series: [
          {
            name: "Product-A",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: { normal: { width: 1 } },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(19, 206, 102, 0.3)"
                      },
                      {
                        offset: 0.8,
                        color: "rgba(19, 206, 102, 0)"
                      }
                    ],
                    false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: "rgb(19, 206, 102)",
                borderColor: "rgba(19, 206, 102, 0.27)",
                borderWidth: 12
              }
            },
            data: [220, 182, 191, 134, 150, 120, 110, 125, 145, 122, 165, 122]
          },
          {
            name: "Product-B",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: { normal: { width: 1 } },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(95, 143, 223, 0.3)"
                      },
                      {
                        offset: 0.8,
                        color: "rgba(95, 143, 223, 0)"
                      }
                    ],
                    false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: "rgb(95, 143, 223)",
                borderColor: "rgba(95, 143, 223, 0.2)",
                borderWidth: 12
              }
            },
            data: [120, 110, 125, 145, 122, 165, 122, 220, 182, 191, 134, 150]
          }
        ]
      })
    },
    initPie() {
      this.pie = echarts.init(document.getElementById("pie"))
      this.pie.setOption({
        title: {
          top: 20,
          text: "ORDER STATUS",
          textStyle: { fontWeight: "normal", fontSize: 16, fontFamily: "Nunito Sans" /*color: '#F1F1F3'*/ },
          left: "1%"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        series: [
          {
            name: "Status",
            type: "pie",
            selectedMode: "single",
            radius: [0, "35%"],

            label: {
              normal: {
                position: "inner"
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              {
                value: 335,
                name: "Orders",
                selected: true,
                itemStyle: { normal: { color: "rgb(95, 143, 223)" } }
              },
              { value: 679, name: "Pending", itemStyle: { normal: { color: "rgb(19, 206, 102)" } } },
              { value: 1548, name: "Delivered", itemStyle: { normal: { color: "rgb(247, 186, 43)" } } }
            ]
          },
          {
            name: "Products",
            type: "pie",
            radius: ["45%", "60%"],

            data: [
              { value: 335, name: "p1", itemStyle: { normal: { color: "#3f84f6" } } },
              { value: 310, name: "p2", itemStyle: { normal: { color: "#4c8bf7" } } },
              { value: 234, name: "p3", itemStyle: { normal: { color: "#5a95f7" } } },
              { value: 135, name: "p4", itemStyle: { normal: { color: "#70a3f8" } } },
              { value: 1048, name: "p5", itemStyle: { normal: { color: "#8ab4fa" } } },
              { value: 251, name: "p6", itemStyle: { normal: { color: "#a3c4fb" } } },
              { value: 147, name: "p7", itemStyle: { normal: { color: "#bfd6fc" } } },
              { value: 102, name: "p8", itemStyle: { normal: { color: "#d4e4fd" } } }
            ],

            itemStyle: {
              normal: {
                color: "rgb(19, 206, 102)"
              }
            }
          }
        ]
      })
    },
    initGridData() {
      const year = new Date().getFullYear()
      const status_list = ["Complete", "Pending", "Returned", "Paid"]

      _.times(10, number => {
        let price = chance.floating({ min: 1, max: 100, fixed: 2 })
        let qnt = chance.integer({ min: 1, max: 5 })
        let amount = price * qnt

        this.gridData.push({
          customer: chance.name(),
          photo: "/static/images/shop/" + chance.integer({ min: 0, max: 19 }) + ".jpg",
          city: chance.city(),
          address: chance.address(),
          email: chance.email(),
          product: chance.sentence({ words: 3 }),
          price: _.replace(price.toFixed(2).toString(), ".", ","),
          qnt,
          amount: _.replace(amount.toFixed(2).toString(), ".", ","),
          status: status_list[chance.integer({ min: 0, max: 3 })],
          date: chance.date({ string: true, year: year }),
          id: number
        })
      })
    }
  },
   created() {
    this.init()
  },
  computed : {
    sortOptions () {
      return this.listuser
          .filter(f => f.sortable)
          .map(f => { return { text: f.label, value: f.key } })
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
