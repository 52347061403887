<template>
	<router-view/>
</template>

<script>


export default {
	name: 'Layout'
}
</script>

<style lang="scss" scoped>
</style>

