<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Recherche contrat par <span style="color:#ffa409">numéro de contrat</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

     <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
            placeholder="Rechercher contrat..."
            prefix-icon="el-icon-search"
            v-model="numero"
            clearable
        >
        <el-button @click="SearchContrat" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input
            placeholder="Rechercher contrat..."
            prefix-icon="el-icon-search"
            v-model="numero"
            clearable>
            <el-button @click="SearchContrat" slot="append" icon="el-icon-search"></el-button>
        </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="listcontrat.length > 0 && !isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
      <el-table-column label="Date Souscription" fixed min-width="200" prop="dateSouscription">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
          </template>
        </el-table-column>
      <el-table-column label="Client" min-width="150" prop="userid">
          <template slot-scope ="scope">
            <span class="date">{{scope.row.userid.prenom}} - {{scope.row.userid.nom}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
        <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" fixed="right" min-width="150" prop="prime_ttc">
          <template slot-scope ="scope">
            <span>{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

       <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="listcontrat.length > 0 && isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
      <el-table-column label="Date Souscription" min-width="200" prop="dateSouscription">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
          </template>
        </el-table-column>
      <el-table-column label="Client" min-width="150" prop="userid">
          <template slot-scope ="scope">
            <span class="date">{{scope.row.userid.prenom}} - {{scope.row.userid.nom}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
        <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope ="scope">
            <span>{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        
      </el-table>
    </div>
  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "search_contrat",
  data() {
    return {
      listcontrat:[],
      numero:'',
      NotFound:false,
      detailsContrat:null,
      showcontrat:false,
      used:false,
      fields: [
        {key: 'dateSouscription', label: 'Date Souscription'},
        {key: 'brancheid.branche',label:'Branche'},
        {key: 'numero_police', label: 'Numero'},
        {key: 'partenaireid.societe', label: 'Assureur'},
        {key: 'dateEffet', label: 'Effet'},
        {key: 'dateEcheance', label: 'Echéance'},
        {key: 'prime_ttc', label: 'Prime TTC'},
        {key: 'userid', label: 'Client'},
        {key: 'action', label: 'Action'},
      ],




      Delete_reason: '',
      /*=============*/
      up_primenette:null,
      up_accessoires:null,
      up_taxes:null,
      up_primettc:null,
      money: {
        thousands: ' ',
        precision: false,
        masked: false
      },
      UpdatePrimeDevis:false,
      useremail: null,
      items: [],

      totalItems: null,
      isBusy: false,
      optionsdateModif: {
        useCurrent: false,
        format: 'YYYY/MM/DD',
      },
      dateEffet: null,
      dateEcheance: null,
      duree: null,
      CalculDuree:null,
      JsonView:false,


      specialUpdatedevis: false,

      modalgenerationloading : false,
      optionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      ldevis: [],
      du: null,
      au: null,
      listdevis: [],
      modalgeneration: false,
      detailsDevis: [],
      showdevis: false,
      pageOptions: [5, 10, 15],

      fieldsFiltres: [{
        key: 'dateSouscription',
        label: 'Date creation'
      },
        {
          key: 'brancheid.branche',
          label: 'Branche'
        },
        {
          key: 'numeroDevis',
          label: 'Num devis'
        },
        {
          key: 'partenaireid.societe',
          label: 'Assureur'
        },
        {
          key: 'courtier.societe',
          label: 'Courtier'
        },
        {
          key: 'dateEffet',
          label: 'Effet'
        },
        {
          key: 'dateEcheance',
          label: 'Echeance'
        },
        {
          key: 'prime_ttc',
          label: ' Prime TTC'
        },
        {
          key: 'creator',
          label: 'Createur'
        },
        {
          key: 'action',
          label: 'Action'
        },

      ],



      modalInfo: {
        title: '',
        content: ''
      },
      MenuHeader:[
        {key: "theme",label: "Libéllé"},
        {key: "collapse",label: "Collapse"},
        {key: "sousmenu",label: "Sous menu"},
        {key: "action", label:"action"}
      ],

      userflette:[],
      fletteRows:null,
      carFields:[
        {key:'marque',label:'Marque', sortable: true, sortDirection: 'desc'},
        {key:'model',label:'Model', sortable: true, sortDirection: 'desc'},
        {key:'immatriculation',label:'Immatriculation', sortable: true, sortDirection: 'desc'},
        {key:'carburation',label:'Carburation', sortable: true, sortDirection: 'desc'},
        {key:'added_date',label:"Date d'ajout"},
        {key:'action',label:'Action'},
      ],



      editUser : false,
      Selectedbranche:null,
      selectedAssureur:[],
      configUserDevisAssureur:null,
      assureurs:[],
      branchelist:null,
      modalSociete: false,
      configHead:[
        { key:"branche", label:"branche"},
        { key:"assureurs", label:"assureur"},
        { key:"action", label:"Action"}
      ],
      selectedUser:null,
      listeAssureurs: null,
      usermenu:[],
      collapse:null,
      menu_name:null,
      showConfigMenu:false,
      submenu:null,
      userSubmenu:[],
      UserFinalMenu:[],

      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listcontrat, //users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      return this.listcontrat.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    /*listFiltered() {
      return this.list.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },*/
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFiltered.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs["my-table"].$el;
        if (newVal === true) {
          tableScrollBody.classList.add("overflow-hidden");
        } else {
          tableScrollBody.classList.remove("overflow-hidden");
        }
      }
    },

    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    SearchContrat(){
      if(this.numero)
      {
        const contrat ={numero:this.numero }
        Api().post('/contrat/attestation/retrait',contrat)
            .then(resp=>{
              if (resp.data.contrats.length>0)
              {
                this.NotFound = false
                this.listcontrat = resp.data.contrats
              } else {
                this.listcontrat =[]
                this.NotFound = true
                this.$message.error('Contrat introuvable !', this.AlertMessage)

                setTimeout(() => (
                    this.NotFound=false,
                        window.open(url)), 3000);

              }
            })
      }else{
        this.$message.error('Le numéro  de contrat est obligatoire !', this.AlertMessage)
      }
    },

    SetdetailsContrat(item){
      this.detailsContrat = item
      this.showcontrat = true
    },



    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },
    SpecialUpdatePrime()
    {
      if(this.up_primenette!=null && this.up_primenette!=0 && this.up_accessoires!=null && this.up_accessoires!=0 && this.up_taxes!=null && this.up_taxes!=0)
      {
        let pNette          = Number(this.up_primenette.replace(/[^0-9.-]+/g, ""))
        let access          = Number(this.up_accessoires.replace(/[^0-9.-]+/g, ""))
        let taxe            = Number(this.up_taxes.replace(/[^0-9.-]+/g, ""))
        this.up_primettc    = pNette + access + taxe

        const data = {
          prime_nette:pNette,
          accessoires:access,
          taxe:taxe,
          prime_ttc:this.up_primettc,
          devisid:this.detailsDevis._id
        }
        Api().post("/devis/update/primes", data).then((result) => {
          if(result.data.devis.nModified==1){
            this.$message.error('Opération effectuée !', this.AlertMessage)
            this.UpdatePrimeDevis = false
            this.showdevis        = false
            this.getDevis()
          }else{
            this.$message.error('Opération échouée !', this.AlertMessage)
          }
        }).catch((err) => {

        });

      }else{
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }
    },

    primeNetteCalculation()
    {
      if(this.up_primenette!=null && this.up_primenette!=0 && this.up_accessoires!=null && this.up_accessoires!=0 && this.up_taxes!=null && this.up_taxes!=0)
      {
        let pNette      = Number(this.up_primenette.replace(/[^0-9.-]+/g, ""))
        let access      = Number(this.up_accessoires.replace(/[^0-9.-]+/g, ""))
        let taxe        = Number(this.up_taxes.replace(/[^0-9.-]+/g, ""))
        this.up_primettc   = pNette + access + taxe
      }

    },

    detaildevis(item) {
      this.detailsDevis = item
      this.showdevis = true
    },

    JsonFormaterView()
    {
      if(this.JsonView==false)
      {
        this.JsonView = true
      }else{
        this.JsonView = false
      }
    },

    CheckIfTypeDefined(item){
      return typeof item
    },

    exportToExcel(){
      Api().get('/export/devis')
          .then(resultat => {
            this.modalgeneration = true
            let url = resultat.data.lien
            setTimeout(() => (
                this.modalgeneration = false,
                    window.open(url)), 7000
            );
          });
    },

    dateDiff: function () {
      if (this.dateEffet && this.duree && this.CalculDuree)
      {
        let inter = dayjs(this.dateEffet).add(this.duree, this.CalculDuree).format('YYYY-MM-DD');
        this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
      }
    },

    /*================*/
    SpecialUpdate() {
      if (this.duree && this.dateEffet && this.dateEcheance && this.numeroDevis) {

        let data = {
          devisid: this.detailsDevis._id,
          duree: this.duree,
          dateEffet: dayjs(this.dateEffet).format('YYYY-MM-DD'),
          dateEcheance: dayjs(this.dateEcheance).format('YYYY-MM-DD'),
          numeroDevis: this.numeroDevis
        }

        Api().post('/devis/update/special', data)
            .then((result) => {
              if (result.data.msg = "Devis modifié !") {
                this.specialUpdatedevis = false
              }
            }).catch((err) => {
          console.log('Nooooo !')
        });

      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }

    },

    getDevis() {

      if (this.$store.state.user.role == 'agent général') {
        Api().get('/devis/assureur/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listdevis = resultat.data.devis
              this.totalRows = this.listdevis.length
              this.totalItems = resultat.data.devis.length;
            });
      } else if (this.$store.state.user.role == 'Agent') {
        Api().get('/devis/courtier/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listdevis = resultat.data.devis
              this.totalRows = resultat.data.devis.length
              this.totalItems = resultat.data.devis.length;
            });

      } else if (this.$store.state.user.role == 'assureur') {
        Api().get('/devis/assureur/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listdevis = resultat.data.devis
              this.totalRows = resultat.data.devis.length
              this.totalItems = resultat.data.devis.length;
            });
      } else if (this.$store.state.user.role == 'Admin') {
        Api().get('/devis/')
            .then(resultat => {
              this.listdevis = resultat.data.devis
              this.totalRows = resultat.data.devis.length
              this.totalItems = resultat.data.devis.length;
            });
      }

    },

    // Fonction de download du devis en fichier pdf
    Downloa_devis: function (devis) {
      Api().get('devis/download/' + devis._id)
          .then(response => {
            this.modalgenerationloading = true;
            let url = response.data.lien
            let WinOpener = null
            setTimeout(() => (
                WinOpener = window.open(),
                this.modalgenerationloading = false,
                WinOpener.location = url
            ), 7000);
          })
    },

    Edit_devis: function (devis) {

      switch (devis.brancheid.branche) {
        case 'Automobile':
          Api().get('devis/' + devis._id)
              .then(response => {
                this.$store.dispatch('setDevisReopen', response.data.devis)
                this.$router.go(this.$router.push({
                  name: 'update_devis_auto'
                }))
              })

          break;

        case 'Habitation':
          this.$router.go(this.$router.push({
            name: 'update_devis_habitation'
          }))
          break;

        case 'Voyage':
          this.$router.go(this.$router.push({
            name: 'update_devis_voyage'
          }))
          break;

      }
    },


    Remover() {
      Api().post('sinistre/remove')
          .then(rep => {})
    },


    devisDeleteConfirmation(item) {
      this.modalDelete = true
      this.detailsDevis = item
    },

    ArchivateDevis(devis) {
      let id = devis._id
      Api().post('devis/archivate/' + id)
          .then(resp => {
            this.getDevis()
          })
    },

    AddEtatUser() {
      Api().post('devis/addetatuser')
          .then(resultat => {})
          .catch(err => {})
    },

    DeletingDevis(devis)
    {
      if(this.Delete_reason)
      {
        const Adevis = {
          userid: store.state.user._id,
          brancheid: devis.brancheid._id,
          devisid: devis._id,
          archive_reason: this.Delete_reason
        }
        Api().post('devis/delete', Adevis)
            .then(resultat => {
              if (resultat.data.rep == 1)
              {
                this.Delete_reason = ""
                this.modalDelete = false
                this.getDevis()
                this.$message.success('Devis archivé avec succés !', this.AlertMessage)

              }
            })

      }else{
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }

    },

    FiltreDate() {
      if (this.du && this.au) {
        switch (store.state.user.role) {
          case 'Admin':
            Api().post('devis/filtre/periode', {
              du: this.du,
              au: this.au
            })
                .then(resultat => {
                  this.listdevis = resultat.data.devis
                });
            break;

          case 'Agent':
            Api().post('devis/filtre/courtier/periode/' + store.state.user.societe, {
              du: this.du,
              au: this.au
            })
                .then(resultat => {
                  this.listdevis = resultat.data.devis
                });
            break;

          case 'assureur':
            Api().post('devis/filtre/assureur/periode/' + store.state.user.societe, {
              du: this.du,
              au: this.au
            })
                .then(resultat => {
                  this.listdevis = resultat.data.devis
                });
            break;
          case 'agent général':
            Api().post('devis/filtre/assureur/periode/' + store.state.user.assureur, {
              du: this.du,
              au: this.au
            })
                .then(resultat => {
                  this.listdevis = resultat.data.devis
                });
            break;

          default:
            break;
        }
      }

    },







    toggleDetails(item){
      this.detailsUser = item
      this.editUser = true
    },







    DeleteBrancheCourtier(item) {
      Api().post("/branchecourtier/delete", {id:item._id})
          .then((result) => {
            Api().post("/branchecourtier/get/all/", {userid:item.userid})
                .then((response) => {
                  this.configUserDevisAssureur = response.data.partenaires
                })
                .catch((err) => {});
          }).catch((err) => {

      });
    },

    AddbrancheConfig() {
      Api().post("/branchecourtier/create", {configuration:this.configUserDevisAssureur})
          .then((result) => {
            this.configUserDevisAssureur = []
          }).catch((err) => {
        console.log(err)
      });
    },

    pushConfig() {
      if(this.Selectedbranche && this.selectedAssureur)
      {
        this.configUserDevisAssureur.push({
          branche:this.Selectedbranche,
          assureurs:this.selectedAssureur,
          userid: this.selectedUser.id
        })
        this.Selectedbranche  = null
        this.selectedAssureur = []
      }
    },

    loadConfigBranchePopUp(item) {
      this.modalSociete = true
      this.selectedUser = item
      Api().post("/branchecourtier/get/all/", {userid:item.id})
          .then((response) => {
            this.configUserDevisAssureur = response.data.partenaires
          })
          .catch((err) => {});
    },

    setCollapse(){
      this.collapse = "id_"+Date.now()+"_collapse"
    },

    MenuDelete(item)
    {
      Api().delete("/menu/"+item._id)
          .then((result) => {
            this.getUserMenu(this.detailsUser._id)
          }).catch((err) => {

      });
    },

    pushMenu()
    {
      if(this.menu_name && this.userSubmenu.length >= 1)
      {
        this.UserFinalMenu.push({
          user : this.detailsUser.id,
          theme:this.menu_name,
          collapse:this.collapse,
          config:this.userSubmenu
        })
        this.menu_name   = null
        this.userSubmenu = []
        this.collapse    = null
      }else{
        this.$message.error('Remplissez les champs vides !', this.AlertMessage)
      }
    },

    create_user_menu()
    {
      if(this.UserFinalMenu.length >=1 )
      {
        Api().post("/menu/add", this.UserFinalMenu)
            .then((result) => {
              this.$message.error('Opération éffectuée !', this.AlertMessage)
              console.log(result.data)
              this.UserFinalMenu = []
            }).catch((err) => {
          console.log(err)
        });
      }else{
        this.$message.error('Le menu ne doit pas être vide !', this.AlertMessage)
      }
    },

    merge(){
      Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
          .then((result) => {
            this.showuser = false
            if(result.data.result){
              alert('Opération réussie')
            }
          }).catch((err) => {
        console.log('=======err=========')
      });
    },

    detailuser(item) {
      this.getUserMenu(item._id)
      this.detailsUser = item
      this.showuser = true
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList:function(){
      switch (this.$store.state.user.role) {
        case 'Admin':
          Api().get('/user/frontoffice/')
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;


        case 'assureur':
          Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
          .then(response => {
            if (response.data) {
              this.showAlert()
            }
            this.showAlert()
          })
          .catch(err => {

          })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },

    DeletingUser(id) {
      Api().delete('user/delete/' + id)
          .then(resultat => {
            if (resultat.status == 200) {
              this.modalDelete = false
              this.getUserList()
            }
          })
          .catch(err => {})
    },

    getUserMenu(id){
      Api().post('/menu/user/menu', {userid:id})
          .then((result) => {
            this.usermenu = result.data.menus
            console.log(result.data.menus)
          }).catch((err) => {
        console.log(err)
      });
    },








    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function(e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function(value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.used = true
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
.button-filtre{
  padding: 12px!important;
  margin : 0 15px!important;
  background: #C0C7CF!important;
  border:none!important;
  color:#fff!important;
}
</style>
